export function getOrdersHeaderFields($t, showClosedOrdered = false) {
  return [
    {
      name: 'headerCheckbox',
      type: 'portal',
      is_visible: showClosedOrdered,
      titlePortalName: 'checkboxAll'
    },
    {
      name: 'status',
      type: 'portal',
      style: 'position:absolute; top:26px; left:3px',
      is_visible: true
    },
    { name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_supplier')
      },
      get title() {
        return $t('Orders.order_header_supplier')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_store')
      },
      get title() {
        return $t('Orders.order_header_store')
      }
    },
    {
      name: 'orderDate',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_date')
      },
      get title() {
        return $t('Orders.order_header_date')
      }
    },
    {
      name: 'createdOn',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_created_on_date')
      },
      get title() {
        return $t('Orders.order_created_on_date')
      }
    },
    {
      name: 'netTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_totalAmount')
      },
      get title() {
        return $t('Orders.order_header_totalAmount')
      }
    },
    {
      name: 'taxTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_taxAmount')
      },
      get title() {
        return $t('Orders.order_header_taxAmount')
      }
    },
    {
      name: 'grossTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_grossAmount')
      },
      get title() {
        return $t('Orders.order_header_grossAmount')
      }
    },
    {
      name: 'orderNumber',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_orderNo')
      },
      get title() {
        return $t('Orders.order_header_orderNo')
      }
    },
    {
      name: 'headerClosedCheckbox',
      type: 'portal',
      style: 'text-align: center',
      is_visible: true,
      get short_title() {
        return showClosedOrdered ? $t('Orders.order_header_orderedOpened') : $t('Orders.order_header_orderedClosed')
      },
      get title() {
        return showClosedOrdered ? $t('Orders.order_header_orderedOpened') : $t('Orders.order_header_orderedClosed')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}

export function getOrdersDetailFields($t, showClosedOrdered = false) {
  return [
    {
      name: 'detailCheckbox',
      type: 'portal',
      is_visible: showClosedOrdered
    },
    {
      type: 'fixed',
      name: 'stockItemName',
      style: 'min-width: 200px;',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_stock')
      },
      get title() {
        return $t('Orders.order_detail_stock')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_unitName')
      },
      get title() {
        return $t('Orders.order_detail_unitName')
      }
    },
    {
      name: 'quantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_quantity')
      },
      get title() {
        return $t('Orders.order_detail_quantity')
      }
    },
    {
      name: 'stockItemNumber',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_stockItemNumber')
      },
      get title() {
        return $t('Orders.order_detail_stockItemNumber')
      }
    },
    {
      name: 'supplierStockItemCode',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_supplierStockItemCode')
      },
      get title() {
        return $t('Orders.order_detail_supplierStockItemCode')
      }
    },
    {
      name: 'price',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Orders.order_detail_price')
      },
      get title() {
        return $t('Orders.order_detail_price')
      }
    },
    {
      name: 'discount1',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_discount1')
      },
      get title() {
        return $t('Orders.order_detail_discount1')
      }
    },
    {
      name: 'discount2',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_discount2')
      },
      get title() {
        return $t('Orders.order_detail_discount2')
      }
    },
    {
      name: 'taxAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_taxAmount')
      },
      get title() {
        return $t('Orders.order_detail_taxAmount')
      }
    },
    {
      name: 'netAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_netAmount')
      },
      get title() {
        return $t('Orders.order_detail_netAmount')
      }
    },
    {
      name: 'grossAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_grossAmount')
      },
      get title() {
        return $t('Orders.order_detail_grossAmount')
      }
    },
    {
      name: 'detailDescription',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}

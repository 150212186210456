import BaseClass from "@/utils/baseclass";

export default class Despatches extends BaseClass {
  addItem(item) {
    let newItem = new Despatch(item);
    this.list.push(newItem);
  }

  updateItem(item) {
    let findItem = this.findItem(item.id);
    if (findItem) {
      findItem.id = item.id;
      findItem.hasAnyDetail = item.hasAnyDetail;
      findItem.supplier = item.supplier;
      findItem.decomposedInvoiceNumber = item.decomposedInvoiceNumber;
      findItem.despatchDate = item.despatchDate;
      findItem.despatchNumber = item.despatchNumber;
      findItem.status = item.status;
      findItem.netTotalAmount = item.netTotalAmount;
      findItem.grossTotalAmount = item.grossTotalAmount;
      findItem.taxTotalAmount = item.taxTotalAmount;
      findItem.showDetail = item.showDetail;
      findItem.invoiceDate = item.invoiceDate;
      findItem.invoiceId = item.invoiceId;
      findItem.invoiceNumber = item.invoiceNumber;
      findItem.accountingStatus = item.accountingStatus;
      findItem.accountingMessage = item.accountingMessage;
      findItem.unitPriceType = item.unitPriceType;
      findItem.description = item.description;
      findItem.currencyDefinitionSymbol = item.currencyDefinitionSymbol;
      findItem.exchangeRate = item.exchangeRate;
      findItem.supplierId = item.supplier.id;
      findItem.supplierName = item.supplier.name;
      (findItem.usageWithListIsNotRequired = item.usageWithListIsNotRequired),
        (findItem.parentStoreId = item.parentStoreId);
      findItem.parentStoreName = item.parentStoreName;
    }
  }
}

export class Invoices extends BaseClass {
  addItem(item) {
    let newItem = new Invoice(item);
    this.list.push(newItem);
  }

  updateItem(item) {
    let findItem = this.findItem(item.id);
    if (findItem) {
      findItem.id = item.id;
      findItem.supplier = item.supplier;
      findItem.netTotalAmount = item.netTotalAmount;
      findItem.grossTotalAmount = item.grossTotalAmount;
      findItem.taxTotalAmount = item.taxTotalAmount;
      findItem.invoiceDetailList = [];
      findItem.showDetail = false;
      findItem.invoiceDate = item.invoiceDate;
      findItem.invoiceNumber = item.invoiceNumber;
      findItem.roundingTolerance = item.roundingTolerance;
      findItem.description = item.description;
    }
  }
}

export class Despatch {
  constructor(item) {
    this.id = item.id;
    this.hasAnyDetail = item.hasAnyDetail;
    this.supplier = item.supplier;
    this.decomposedInvoiceNumber = item.decomposedInvoiceNumber;
    this.despatchDate = item.despatchDate;
    this.despatchNumber = item.despatchNumber;
    this.status = item.status;
    this.netTotalAmount = item.netTotalAmount;
    this.grossTotalAmount = item.grossTotalAmount;
    this.taxTotalAmount = item.taxTotalAmount;
    this.despatchDetailList = [];
    this.showDetail = false;
    this.invoiceDate = item.invoiceDate;
    this.invoiceId = item.invoiceId;
    this.invoiceNumber = item.invoiceNumber;
    this.accountingStatus = item.accountingStatus;
    this.accountingMessage = item.accountingMessage;
    this.unitPriceType = item.unitPriceType;
    this.storeGroups = item.storeGroups;
    this.description = item.description;
    this.currencyDefinitionSymbol = item.currencyDefinitionSymbol;
    this.exchangeRate = item.exchangeRate;
    this.supplierId = item.supplier.id;
    this.supplierName = item.supplier.name;
    (this.usageWithListIsNotRequired = item.usageWithListIsNotRequired),
      (this.parentStoreId = item.parentStoreId),
      (this.parentStoreName = item.parentStoreName);
  }

  addDetails(detailList) {
    this.despatchDetailList = [];
    detailList.forEach((detail) => {
      this.despatchDetailList.push(
        new DespatchDetail(detail, this.currencyDefinitionSymbol)
      );
    });
  }

  get isLive() {
    return this.status === 2;
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id);
    if (findIndex > -1) {
      this.despatchDetailList.splice(findIndex, 1);
    }
  }

  findIndex(Id) {
    return this.despatchDetailList.findIndex((elementId) => elementId === Id);
  }

  findItem(Id) {
    return this.despatchDetailList.find((elementId) => elementId.id === Id);
  }

  get detailIsEmpty() {
    return this.despatchDetailList.length === 0;
  }
}

export class DespatchDetail {
  constructor(detail, currencyDefinitionSymbol) {
    this.id = detail.id;
    this.despatchHeaderId = detail.despatchHeaderId;
    this.stockItem = detail.stockItem;
    this.quantity = detail.quantity;
    this.discount1 = detail.discount1;
    this.discount2 = detail.discount2;
    this.discountedUnitPrice = detail.discountedUnitPrice;
    this.discountedBaseUnitPrice = detail.discountedBaseUnitPrice;
    this.taxRatio = detail.taxRatio;
    this.taxId = detail.taxId;
    this.currencyDefinitionSymbol = currencyDefinitionSymbol;
    this.unitId = detail.unitId;
    this.grossAmount = detail.grossAmount;
    this.unitPrice = detail.unitPrice;
    this.netAmount = detail.quantity * detail.unitPrice;
    this.taxAmount = detail.taxAmount;
    this.baseQuantity = detail.baseQuantity;
    this.netBaseUnitPrice = detail.netBaseUnitPrice;
    this.storeId = detail.storeId;
    this.storeName = detail.storeName;
    this.discountedNetUnitPrice = detail.discountedNetUnitPrice;
    this.isOrderCompleted = detail.isOrderCompleted;
    this.originalOrderQuantity = detail.originalOrderQuantity;
    this.remainingOrderQuantity = detail.remainingOrderQuantity;
    this.showOrderPopup = false;
    this.canIgnoreQuantity = false;
    this.canIgnorePrice = false;
    this.showPriceValidation = false;
    this.baseUnitQuantity = detail.baseUnitQuantity;
    this.isFocused = false;
    this.maxQuantityDeviationResult = detail.maxQuantityDeviationResult;
    this.minQuantityDeviationResult = detail.minQuantityDeviationResult;
    this.minPriceDeviationResult = detail.minPriceDeviationResult;
    this.maxPriceDeviationResult = detail.maxPriceDeviationResult;
    this.orderDetailId = detail.orderDetailId;
    this.orderNumber = detail.orderNumber;
    this.finalQuantity = detail.quantity;
    this.finalPrice = detail.unitPrice;
    this.priceType = detail.priceType;
    this.stockOnHandQuantity = detail.stockOnHandQuantity;
  }

  updateDetail(item) {
    this.id = item.id;
    this.despatchHeaderId = item.despatchHeaderId;
    this.stockItem = item.stockItem;
    this.quantity = item.quantity;
    this.discount1 = item.discount1;
    this.discount2 = item.discount2;
    this.discountedUnitPrice = item.discountedUnitPrice || 0;
    this.discountedBaseUnitPrice = item.discountedBaseUnitPrice || 0;
    this.taxRatio = item.taxRatio;
    this.taxId = item.taxId;
    this.unitId = item.unitId;
    this.grossAmount = item.grossAmount || 0;
    this.unitPrice = item.unitPrice || 0;
    this.netAmount = item.netAmount || 0;
    this.taxAmount = item.taxAmount || 0;
    this.baseQuantity = item.baseQuantity;
    this.netBaseUnitPrice = item.netBaseUnitPrice || 0;
    this.storeId = item.storeId;
    this.storeName = item.storeName;
    this.discountedNetUnitPrice = item.discountedNetUnitPrice || 0;
    this.isOrderCompleted = item.isOrderCompleted;
    this.originalOrderQuantity = item.originalOrderQuantity;
    this.remainingOrderQuantity = item.remainingOrderQuantity;
    this.showOrderPopup = item.showOrderPopup;
    this.canIgnoreQuantity = item.canIgnoreQuantity;
    this.canIgnorePrice = item.canIgnorePrice;
    this.showPriceValidation = item.showPriceValidation;
    this.baseUnitQuantity = item.baseUnitQuantity;
    this.orderDetailId = item.orderDetailId;
    this.orderNumber = item.orderNumber;
    this.minPriceDeviationResult = item.minPriceDeviationResult;
    this.maxPriceDeviationResult = item.maxPriceDeviationResult;
    this.minQuantityDeviationResult = item.minQuantityDeviationResult;
    this.maxQuantityDeviationResult = item.maxQuantityDeviationResult;
    this.finalQuantity = item.finalQuantity;
    this.finalPrice = item.finalPrice;
    this.priceType = item.priceType;
    this.stockOnHandQuantity = item.stockOnHandQuantity;
  }
}

export class Invoice {
  constructor(item) {
    this.id = item.id;
    this.supplier = item.supplier;
    this.netTotalAmount = item.netTotalAmount;
    this.grossTotalAmount = item.grossTotalAmount;
    this.taxTotalAmount = item.taxTotalAmount;
    this.invoiceDetailList = [];
    this.showDetail = false;
    this.invoiceDate = item.invoiceDate;
    this.invoiceNumber = item.invoiceNumber;
    this.accountingStatus = item.accountingStatus;
    this.accountingMessage = item.accountingMessage;
    this.storeGroups = item.storeGroups;
    this.isEInvoice = item.isEInvoice;
    this.roundingTolerance = item.roundingTolerance;
    this.description = item.description;
  }

  findItem(Id) {
    return this.invoiceDetailList.find((elementId) => elementId.id === Id);
  }

  addDetails(detailList) {
    this.invoiceDetailList = [];
    detailList.forEach((detail) => {
      this.invoiceDetailList.push(new Despatch(detail));
    });
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id);
    if (findIndex > -1) {
      this.invoiceDetailList.splice(findIndex, 1);
    }
  }

  findIndex(Id) {
    return this.invoiceDetailList.findIndex((elementId) => elementId === Id);
  }

  get detailIsEmpty() {
    return this.invoiceDetailList.length === 0;
  }
}

/*
baseQuantity
grossAmount
id
despatchHeaderId
netAmount
netUnitPrice
quantity
stockItem
- baseQuantity
- baseUnitCode
- baseUnitId
- baseUnitName
 - id
 - name
 - unitCode
 - unitId
 - unitName
taxAmount
taxId
taxRatio
unitId
*/

<template lang="pug">
  Modal(:isOpen="true" @closeModal="closeModal")
    template(slot="title")
      span {{ despatchHeader?.supplier?.name }}
    template(slot="content")
      NestedTable(
        v-if="data.length"
        :fields="fields"
        :detailFields="detailFields",
        :data="data"
        :newRow="newRow"
        :isDetailDataLoading="isShelfListLoading"
        :actions="['export']"
        :detailActions="['export']"
        :activateNewRow="activateNewRow"
        :showDetailWithEmptyData="true"
        @addNewData="addNewRow"
        @showExportPopup="showExportPopup"
        @showDetailExportPopup="showDetailExportPopup"
        @showDetailAction="showDetail"
      )

      EmptyState(
        v-else
        :title="$t('Despatches.emptyState_Title_Batch', { field: $t('Dictionary.Batch') })"
        :description="$t('Global.emptyState_Description_Batch', { field: $t('Dictionary.Batch') })"
      )

      portal(to="discard")
        div(slot-scope="detail")
          Button(
            :justIcon="true" 
            :clearVariant="true"
            iconName="circle-remove"
            iconClass="circle-remove"
            @click="removeBatch(detail.row, item)"
          )
      
      portal(to="saveBatch")
        div(slot-scope="detail")
          Button(
            :justIcon="true" 
            :clearVariant="true"
            iconName="icon-btn-success"
            iconClass="icon-btn-success"
            @click="saveBatch(detail.row, item)"
          )

      portal(to="quantity")
        div(slot-scope="detail")
          .control
            customNumberInput(
              :name="`quantity-${detail.rowIndex}-${detail.row?.id}`"
              :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
              id="input-batch-quantity"
              v-validate="{required: true, greater_than: 0}"
              :error="veeErrors.has(`quantity-${detail.rowIndex}-${detail.row?.id}`)"
              v-model="detail.row.id ? detail.row.quantity : item.quantity"
              @input="changeValue($event, detail.row, 'quantity')"
            )

            showValidateError(
              v-show="veeErrors.has(`quantity-${detail.rowIndex}-${detail.row?.id}`)"
              :errorName="veeErrors.first(`quantity-${detail.rowIndex}-${detail.row?.id}`)"
            )
      
      portal(to="shelfLife")
        div(slot-scope="detail")
          customNumberInput(
            :name="`shelf-life-${detail.rowIndex}-${detail.row?.id}`"
            :data-vv-as="$t('Despatches.shelfLife')"
            id="input-batch-shelf-life"
            v-model="detail.row.id ? detail.row.shelfLife : item.shelfLife"
            @input="changeValue($event, detail.row, 'shelfLife')"
          )
      
      portal(to="productionDate")
        div(slot-scope="detail")
          CustomDatepicker(
            id="batch-production-date"
            name="productionDate",
            v-model="selectedProductionDate"
            :class="{ 'is-danger': veeErrors.has('productionDate') }"
            :data-vv-as="$t('Despatches.productionDate')",
            :useFilterReset="true"
            pickerType="manuel",
            inputIconRight="icon-datepicker"
            inputIcon=""
            @resetDateFilter="resetDate(detail.row, 'productionDate')"
          )
      
      portal(to="expirationDate")
        div(slot-scope="detail")
          CustomDatepicker(
            id="batch-expiration-date"
            name="expirationDate",
            v-model="detail.row.id ? detail.row.expirationDate : item.expirationDate"
            :class="{ 'is-danger': veeErrors.has('expirationDate') }"
            :data-vv-as="$t('Despatches.expirationDate')",
            :useFilterReset="true"
            pickerType="manuel",
            inputIconRight="icon-datepicker"
            inputIcon=""
            @input="selectDate($event, detail.row)"
            @resetDateFilter="resetDate(detail.row, 'expirationDate')"
          )
  
      portal(to="degree")
        div(slot-scope="detail")
          div.input-wrapper
            input.input-wrapper-txt(type="number", v-model="detail.row.id ? detail.row.degree : item.degree")

          showValidateError(
            v-show="veeErrors.has(`degree-${detail.rowIndex}-${detail.row?.id}`)"
            :errorName="veeErrors.first(`degree-${detail.rowIndex}-${detail.row?.id}`)"
          )
      
      portal(to="lotNumber")
        div(slot-scope="detail")
          span {{ detail.row.id ? detail.row.lotNumber : '-'}}
      
      portal(to="warehouseTime")
        div(slot-scope="detail")    
          input(type="time", v-model="detail.row.id ? warehouseTimeData[detail.rowIndex] : item.warehouseTime" @input="changeTime(warehouseTimeData[detail.rowIndex], detail.row, 'warehouseTime')")

      portal(to="shipmentTime")
        div(slot-scope="detail")
          input(type="time", v-model="detail.row.id ? shipmentTimeData[detail.rowIndex] : item.shipmentTime" @input="changeTime(shipmentTimeData[detail.rowIndex], detail.row, 'shipmentTime')")

      portal(to="shelfStorageLocationList")
        div(slot-scope="detail")
          customSelect(
            v-model="detail.row.id ? detail.row.shelfStorageLocationListHeaderId : item.shelfStorageLocationListHeaderId"
            selectedEmitValue="id"
            :defaultTitle="$t('Despatches.selectShelfStorageLocation')"
            :optionData="ShelfListFromStore"
            optionTitle="name"
            componentName="shelf-storage-location-select"
            @inputSelected="changeValue($event, detail.row, 'shelfStorageLocationListHeaderId')"
          )
      exportPopup(
        v-if="isExportPopupVisible"
        :templateId="templateId"
        :templateType="templateType"
        ref="exportPopupTemplate"
      )
      


        
    template(slot="footer")



</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import CustomSelect from '@/view/global/custom-select'
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import moment from 'moment'
import { getExpireDateFields, getExpireDateDetailFields } from '../fields/expireDate'


export default {
  name: 'UBLUpload',

  data () {
    return {
      fields: getExpireDateFields(this.$t),
      detailFields: getExpireDateDetailFields(this.$t),
      item: {
        degree: null,
        expirationDate: null,
        productionDate: null,
        lotNumber: '',
        quantity: null,
        shipmentTime: null,
        warehouseTime: null,
        shelfLife: null,
        despatchDetailId: null,
        shelfStorageLocationListHeaderId: null
      },
      tempRow: null,
      despatchDetailId: null,
      newRow: 0,
      activateNewRow: true,
      suggestedShelfStorageLocation: null,
      selectedProductionDate: null,
      shipmentTimeData: null,
      warehouseTimeData: null,
      despatchDetail: null
    }
  },

  components: {
    CustomSelect,
    exportPopup
  },

  mixins: [settingsExportPopup],

  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    despatchHeader: {
      type: Object,
      default: () => {}
    },
    storeId: {
      type: String,
      default: null
    }
  },

  computed: {
     ...mapGetters('ListsShelfStorageLocation', ['ShelfListFromStore']),

    isShelfListLoading () {
      return this.$wait.is(['setShelfLocation'])
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Despatch', [
      'checkLotNumber',
      'createBatch',
      'updateBatch',
      'getBatchDetails',
      'deleteBatch',
      'getExpireDateData',
      'validateBatchQuantity'
    ]),

    ...mapActions('OnHand', ['getShelfLocationList']),

    ...mapActions('ListsShelfStorageLocation', ['getAllShelvesFromStore']),

    ...mapMutations('Despatch', [
      'SHOW_BATCH_DETAIL',
      'HIDE_BATCH_DETAIL'
    ]),

    resetDate (detail, field) {
      detail.id ? detail[field] = null : this.item[field] = null
    },

    selectDate (date, row) {
      let expirationDate
      date ? expirationDate = moment(date).format('YYYY-MM-DD') : expirationDate = null
      row.expirationDate ? row.expirationDate = expirationDate : this.item.expirationDate = expirationDate
    },

    changeTime (value, row, field) {
      if (row.id) row[field] = value
    },


    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 17, isFinalizeAction)
    },

    showDetailExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 18, isFinalizeAction)
    },

    changeValue (event, detail, field ) {
      if (!this.tempRow && detail.id) {
        this.tempRow = detail
      }
      field === 'shelfStorageLocationListHeaderId' ? this.tempRow[field] = event.id : this.tempRow[field] = event
    },

    addNewRow () {
      this.resetItem()
      this.newRow = 1
      this.item.despatchDetailId = this.despatchDetailId
      this.activateNewRow = false
      this.item.shelfStorageLocationListHeaderId = this.suggestedShelfStorageLocation.id
    },
  
    saveBatch (detail, item) {
      if (detail.id) {
        this.tempRow ? this.tempRow = null : this.tempRow = detail
        detail.expirationDate ? this.tempRow.expirationDate = moment(this.tempRow.expirationDate).format('YYYY-MM-DD') : this.tempRow.expirationDate = null
        detail.degree ? this.tempRow.degree = Number(this.tempRow.degree) : this.tempRow.degree = null
        const payload = {
          ...this.tempRow,
          productionDate: this.selectedProductionDate ? moment(this.selectedProductionDate).format('YYYY-MM-DD') : null
        }
        this.updateBatch(payload).then(res => {
          if (res) {
            const message = this.$t('Despatches.success_batchUpdated')
            this.notifyShow({ message })
            this.refreshData()
            this.getBatchDetails({despatchDetailId: this.despatchDetailId}).then(res => {
              if (res) {
                this.HIDE_BATCH_DETAIL(this.despatchDetail)
                this.showDetail(this.despatchDetail)
                this.selectedProductionDate = null
              }
            })
          }
        })
      }
      else {
        item.despatchDetailId = this.despatchDetailId
        this.selectedProductionDate ? this.item.productionDate = moment(this.selectedProductionDate).format('YYYY-MM-DD') : this.item.productionDate = null
        this.item.expirationDate ? this.item.expirationDate = moment(this.item.expirationDate).format('YYYY-MM-DD') : this.item.expirationDate = null
        this.item.degree ? this.item.degree = Number(this.item.degree) : this.item.degree = null
        this.createBatch(item).then(res => {
          if (res) {
            const message = this.$t('Despatches.success_batchCreated')
            this.notifyShow({ message })
            this.refreshData()
            this.getBatchDetails({despatchDetailId: this.despatchDetailId}).then(res => {
              if (res) {
                this.HIDE_BATCH_DETAIL(this.despatchDetail)
                this.showDetail(this.despatchDetail)
                this.selectedProductionDate = null
              }
            })
          }
        })
      }
      
    },

    refreshData () {
      this.resetItem()
      this.newRow = 0
      this.activateNewRow = true
      this.$emit('getUpdatedBatch')
    },
   
    removeBatch (detail) {
      if (detail.id) {
        this.deleteBatch(detail.id).then(res => {
          if (res) {
            const message = this.$t('Despatches.success_batchDeleted')
            this.notifyShow({ message })
            this.refreshData()
          }
        })
      } 
      else {
        this.resetItem()
        this.newRow = 0
        this.activateNewRow = true
      }
    },

    resetItem () {
      this.item = {
        degree: null,
        expirationDate: null,
        productionDate: null,
        quantity: null,
        shipmentTime: null,
        warehouseTime: null,
        shelfLife: this.despatchDetail.shelfLife || null,
        despatchDetailId: null,
        shelfStorageLocationListHeaderId: null
      }
    },
  
    async showDetail (batch) {
      this.despatchDetail = batch
      this.despatchDetailId = batch.id
      if (batch.showDetail) {
        this.HIDE_BATCH_DETAIL(batch)
        return
      }
      this.getShelfList(batch)
      await this.getBatchDetails({despatchDetailId: batch.id}).then(res => {
        if (res) {
          this.shipmentTimeData = res.data.batchList.map(i => i.shipmentTime)
          this.warehouseTimeData = res.data.batchList.map(i => i.warehouseTime)
          this.item.shelfLife = batch.shelfLife
          this.SHOW_BATCH_DETAIL({batch: res.data, id: batch.id})
        }
      })
    },

    getShelfList: vueWaitLoader(async function(batch) {
      const payload = {
        storeId: batch.storeId,
        stockItemId: batch.stockItemId,
        unitId: batch.unitId
      }
      await this.getAllShelvesFromStore(payload)
      await this.getShelfLocationList(payload).then(res => {
        if (res) {
          this.suggestedShelfStorageLocation = res.data.shelfStorageLocationListHeaderListByStore[0]
        }
      })
    }, 'setShelfLocation'),

    closeModal () {
      this.$emit('closeModal')
    },

    getFileName (file) {
      return file.length > 30 ? file.substr(0, 30).concat('...') : file
    },

    closeAlert () {
      this.$emit('closeUploadScreen')
    }
  }
}
</script>

<style lang="scss" scoped>

  .accordion {
    
    &-title {
      display: flex;
      height: 45px;
      width: 100%;
      border: 1px solid $color-gray;
      border-width: 1px 1px 0 1px;
      background: $color-lighter-blue;
    }
    &-header {
      display: flex;
    }
  }

  :deep(.Modal) {
    &-Body {
      z-index: $z-index-xxxl !important;
      &-content {
        width: 80vw !important;
      }
    }
    &-Overlay[data-v-b9dcd536] {
      
      z-index: $z-index-xxl !important;

    }
    
  }
  :deep(.circle-remove) {
    color: $color-warning;
    height: 16px !important;
    width: 18px !important;
    &:hover {
      color: $color-red;
      transition: all $transition;
    }
  }


  .discard {
    width: max-content !important;
  }

  :deep(.NestedDetail) {

    .detailTable {
      overflow-x: scroll !important;
      min-height: 300px !important;
    }
    &-row {
      &-col {
        min-width: 130px !important;
        &.saveBatch {
          left: 80px !important;
        }
      }
      
    }
  }
  :deep(.icon-btn-success) {
    color: $color-success !important;
    &:hover {
      color: $color-success-dark !important;
      transition: all $transition;
    }
  }

  :deep(.NestedDetail-row) {
    grid-template-columns: 35px repeat(10, 1fr) 60px !important
  }
  .Form-item-help {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 14px;
    color: $color-rejection;
  }

  .input-wrapper {
    display: flex;
    align-items: center;

    &-button {
      padding: 4px 8px;
      font-size: 14px;
    }
    &-txt {
      padding: 4px 8px;
      font-size: 14px;
      width: 50px;
    }
  }

  :deep(.select-dropdown-custom) {
    &-options .normal {
      width: 210px !important;
    }
    &-selected {
      height: 30px !important;
    }
  }
  :deep(.txt) {
    min-height: unset !important;
    height: 30px !important;
  }

  :deep(.Header) {
    z-index: $z-index-md !important;
  }
  :deep(.select-dropdown-custom-options) {
    width: 210px !important;
  }
</style>

<template lang="pug">
Popup(@close="close")

    template(slot="title") {{ despatchHeaderTitle }}

    template(slot="detail")
      div.Popup-despatch-info(v-if="!headerIsEmpty")
        div
          Icon(name="icon-calender")
          span {{ despatchDate }}
        div
          Icon(name="icon-popup-despatch-no")
          span {{ despatch.despatchHeader.despatchNumber }}

    template(slot="content")
      form.Form(
      id="form-despatch-detail"
      @submit.prevent="onSubmitDespatchDetailForm")

        .Form-item.required
          label.Form-item-label
            | {{ $t('Despatches.detail_Popup_FormField_StoreName') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="despatch-detail-refund-store-select"
              name="storeName",
              v-model="item.storeId",
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')",
              v-validate="'required|is_not:-1|verify_inventory'"
              :inputClass="{ 'is-danger': veeErrors.has('storeSelect') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              label="name",
              :disabled="tenantMultiCurrencyIsActive"
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Unselected')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              v-model="item.stockItem"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StockItemName')"
              v-validate="'required|verify_inventory'"
              :inputClass="{ 'is-danger': inventoryValidation }"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="fetchStockItems(item, true)"
            )

          p.Form-item-help.is-danger(
            v-show="inventoryValidation"
          ) {{ validationErrorMessage }}

          .Form-item-info(v-if="showStockDetail")
            div
              .text {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit') }}
              .value {{ item.stockItem.baseUnitName }}
            div
              .text {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoTax') }}
              .value
                customSelectInput(
                  name="taxId",
                  :optionData="Taxes"
                  v-model="item.taxId"
                  :data-vv-as="$t('Despatches.detail_Popup_FormField_Tax')"
                  :error="veeErrors.has('taxId')"
                  v-validate="'required|is_not:-1'"
                  id="select-refund-tax"
                  optionIdName="option-despatch-popup"
                )

                showValidateError(
                  v-show="veeErrors.has('taxId')"
                  :errorName="veeErrors.first('taxId')"
                )

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}

          .control
            customNumberInput(
              name="quantity"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
              v-model="item.quantity"
              :error="veeErrors.has('quantity')"
              v-validate="allowNegativeStock ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: item.unitPrice} : {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:-1, max_value:stockItemInfo.baseUnitQuantity, multiplyTwoField: item.unitPrice}"
            )

            .Form-item-info(v-if="showStockDetail && stockItemInfo")
              div
                .text {{ $t('Despatches.detail_Popup_FormField_StockOnHand') }}
                .value {{ stockItemInfo.baseUnitQuantity }}

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            id="select-despatch-popup-baseunit"
            name="unitId"
            v-model="item.unitId"
            v-validate="'required|is_not:-1'"
            :data-vv-as="$t('Despatches.detail_Popup_FormField_BaseUnit')"
            :disabled="isEdit"
            :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
              v-if="!(isFinalized && isEdit)"
              value="-1"
              id="option-despatches-popup-baseunit-unselected") {{ $t('Despatches.detail_Popup_FormField_BaseUnit_Unselected') }}
              option(
              v-for="unit in filteredUnitList"
              :key="unit.id"
              :value="unit.id"
              :id="`option-despatches-popup-baseunit-${unit.name}`"
              ) {{ unit.name }}

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

          .Form-item-info(v-if="calculateBaseQuantity")
            span.text {{ $t('Despatches.detail_Popup_FormField_BaseUnit_CalculatedText') }}
            span.value {{ $t('Despatches.detail_Popup_FormField_BaseUnit_CalculatedValue', { value: calculateBaseQuantity, unit: baseQuantityUnit }) }}

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_NetUnitPrice') }}
          .control
            currency-input.txt(
            name="netUnitPrice"
            :disabled="isSupplierNotPermissionToGlobal"
            :class="{ 'is-danger': veeErrors.has('netUnitPrice') }"
            :data-vv-as="$t('Despatches.detail_Popup_FormField_NetUnitPrice')"
            v-model="item.unitPrice"
            v-validate="{required: true, greater_than:0, multiplyTwoField: item.quantity}")

            showValidateError(
              v-show="veeErrors.has('netUnitPrice')"
              :errorName="veeErrors.first('netUnitPrice')"
            )

          .Form-item-info(v-if="calculateBasePrice")
            span.text {{ $t('Despatches.detail_Popup_FormField_NetUnitPrice_Calculated') }}
            span.value {{ calculateBasePrice | formatCurrency }}

        .Form-item
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Discount1') }}
          .control
            customNumberInput(
              name="discount1"
              id="despatches-detail-discount1"
              v-validate="'greater_than:-1|max_value:100'"
              :isTypePercentage="true"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount1')"
              placeholder=",00"
              v-model="item.discount1"
              :error="veeErrors.has('discount1')"
              :disabled="isSupplierNotPermissionToGlobal"
            )

            showValidateError(
              v-show="veeErrors.has('discount1')"
              :errorName="veeErrors.first('discount1')"
            )
          p.Form-item-help.is-danger(
            v-show="!discountControl"
            ) {{ $t('Despatches.discountControl_Message') }}

        .Form-item.margin-discount
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Discount2') }}
          .control
            customNumberInput(
              name="discount2"
              id="despatches-detail-discount2"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount2')"
              :isTypePercentage="true"
              placeholder=",00"
              v-validate="'greater_than:-1|max_value:100'"
              :error="veeErrors.has('discount2')"
              v-model="item.discount2"
              :disabled="(isEmptyDiscount1 || +(item.discount1) === 0) || isSupplierNotPermissionToGlobal"
            )

            showValidateError(
              v-show="veeErrors.has('discount2')"
              :errorName="veeErrors.first('discount2')"
            )

      .Form-item(v-if="!isEmptyDiscount1")
        .Form-item-info-multiple
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ discountedNetUnitPrice | formatCurrency}}
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ netUnitPriceLineTotal | formatCurrency }}

    .Form-item(v-if="!isEmptyDiscount1")
        .Form-item-info-multiple
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ discountedNetUnitPrice | formatCurrency }}
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ netUnitPriceLineTotal | formatCurrency}}

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-despatch-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Despatches.popup_RefundDetailName') })"
        v-model="saveAndNew")

      Button(
      id="btn-despatch-detail-popup-submit"
      type="submit"
      size="medium"
      variant="full",
      primary
      form="form-despatch-detail"
      :disabled="isLoading || (isSupplierNotPermissionToGlobal && item.unitPrice === 0) || inventoryValidation")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Despatches.detail_Popup_FinishEditButton') }}
          template(v-else) {{ $t('Despatches.detail_Popup_Refund_FinishCreateButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CheckInventoryMixin from '@/mixins/validateInventory'
import disableSubmit from '@/mixins/disable-submit-button'
import moment from 'moment'
import { getObjectValue, isEmpty, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RefundDetailForm',
  mixins: [CheckInventoryMixin, disableSubmit],
  props: {
    status: {
      type: String,
      default: 'new'
    },
    documentEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      despatch: {
        despatchHeader: null,
        despatchDetail: null
      },

      selectOptions: [],
      stockItem: {},

      item: {
        stockItem: null,
        baseUnitName: null,
        taxId: '-1',
        quantity: null,
        unitId: '-1',
        unitPrice: 0,
        storeId: {
          id: '-1',
          name: ''
        },
        discount1: null,
        discount2: null
      },

      saveAndNew: true,
      isEmptyPriceQuote: true,
      discountControl: true,
      isSupplierGlobal: false,
      stockItemInfo: {},
      allowNegativeStock: false,
      selectMiniStoreList: []
    }
  },

  created () {
    this.setRoleArea()
  },

  async mounted () {
    if (this.isEdit) {
      // edit
      let despatchDetail = this.$route.params.despatchDetail
      if (!despatchDetail) {
        // get detail
        let detailId = this.$route.params.detailId
        await this.get_REFUND_DETAIL(detailId).then(res => {
          if (res.status === 200) {
            despatchDetail = res.data.despatchDetail
          }
        })
      }
      this.despatch.despatchDetail = despatchDetail
      await this.fetchStockItems(despatchDetail, false)
      // get header
      await this.get_REFUND(despatchDetail.despatchHeaderId).then(res => {
        if (res.status === 200) {
          this.despatch.despatchHeader = res.data.despatchHeader
        }
      })
      // form bind
      if (this.Taxes.find(element => element.id === despatchDetail.taxId)) {
        this.item.taxId = despatchDetail.taxId
      }
      this.item.baseUnitName = despatchDetail.stockItem.unitCode

      this.item.stockItem = despatchDetail.stockItem
      this.item.quantity = despatchDetail.quantity
      this.item.unitPrice = despatchDetail.unitPrice
      this.item.unitId = despatchDetail.unitId
      this.item.storeId = { id:despatchDetail.storeId, name:despatchDetail.storeName }
      this.item.discount1 = despatchDetail.discount1
      this.item.discount2 = despatchDetail.discount2

      this.get_PRICE_QUOTE({
        DespatchHeaderId: this.despatch.despatchHeader.id,
        StockItemId: this.item.stockItem.id,
        UnitId: this.item.unitId
      }).then(res => {
        if (res.data.priceQuote) this.isSupplierGlobal = res.data.priceQuote.isSupplierGlobal
      })
    } else {
      // new
      let despatchHeader = this.$route.params.despatchHeader

      if (!despatchHeader) {
        // get header
        let despatchHeaderId = this.$route.params.id
        await this.get_REFUND(despatchHeaderId).then(res => {
          if (res.status === 200) {
            despatchHeader = res.data.despatchHeader
            if (this.tenantMultiCurrencyIsActive) this.item.storeId = { id:despatchHeader.parentStoreId, name: despatchHeader.parentStoreName }
          }
        })
      }
      if (this.tenantMultiCurrencyIsActive) this.item.storeId = { id:despatchHeader.parentStoreId, name: despatchHeader.parentStoreName }
      else {
        await this.fetchUser().then(res => {
          if (res.status === 200) this.item.storeId = { id: res.data.user.defaultStoreId, name: res.data.user.defaultStoreName }
          else this.item.storeId = -1
        })
      }
      this.despatch.despatchHeader = despatchHeader
    }
    this.getMiniStoreList()
  },

  watch: {
    async 'item.stockItem' (val) {
      if (this.isEdit || !val) return
      await this.getStockData(val.id)
      await this.setUnit(val.unitId)
      await this.getPriceQuote()
    },
    async 'item.unitId' () {
      if (this.isEdit) return
      await this.getPriceQuote()
    },

    'isEmptyDiscount1' () {
      if (this.isEdit && this.isEmptyDiscount1) {
        this.item.discount2 = null
      }
      if ((this.isEmptyDiscount1 || Number(this.item.discount1) === 0) && !this.isEmptyDiscount2) {
        this.discountControl = false
      } else {
        this.discountControl = true
      }
    }
  },
  computed: {
    ...mapGetters('Tax', [
      'Taxes'
    ]),

    ...mapGetters('Settings', [
      'RoleArea',
      'tenantMultiCurrencyIsActive'
    ]),

    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),

    directSelectOption () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    validationErrorMessage () {
      return this.veeErrors.has('storeName')
        ? this.veeErrors.first('storeName')
        : this.veeErrors.has('stockItem')
          ? this.veeErrors.first('stockItem')
          : ''
    },

    checkInventoryParams () {
      /*
        *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
        */
      return {
        storeId: this.item.storeId.id,
        inventoryDate: this.despatch.despatchHeader ? this.despatch.despatchHeader.despatchDate : null,
        stockItemIdList: this.item.stockItem ? [this.item.stockItem.id] : null
      }
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    despatchDate () {
      return moment(this.despatch.despatchHeader.despatchDate).format('D MMMM YYYY')
    },

    isEmptyDiscount1 () {
      return this.item.discount1 === null || !Number(this.item.discount1) || this.item.discount1 < 0
    },

    isEmptyDiscount2 () {
      return !this.item.discount2 || this.item.discount2 < 0
    },

    filteredUnitList () {
      if (!this.showStockDetail) return []
      if (this.isFinalized && this.isEdit) {
        return [this.findUnit(this.item.unitId)]
      }
      const baseUnitId = this.isEdit
        ? this.findUnit(this.item.unitId).baseUnitId
        : this.findUnit(this.item.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    newItem () {
      return {
        storeId: this.item.storeId.id,
        quantity: this.item.quantity,
        unitPrice: this.item.unitPrice,
        taxId: this.item.taxId,
        unitId: this.item.unitId,
        discount1: this.item.discount1 || null,
        discount2: this.item.discount2 || null,
        despatchHeaderId: getObjectValue('despatchHeader.id', this.despatch),
        stockItemId: this.item.stockItem.id
      }
    },

    newEdit () {
      return {
        quantity: this.item.quantity,
        unitPrice: this.item.unitPrice,
        taxId: this.item.taxId,
        unitId: this.item.unitId,
        discount1: this.item.discount1 || null,
        discount2: this.item.discount2 || null,
        storeId: this.item.storeId.id,
        id: this.$route.params.detailId
      }
    },

    isLoading () {
      return this.isDisabledSubmit || this.$wait.is(['create_REFUND_DETAIL', 'update_REFUND_DETAIL'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isDocumentEdit () {
      return this.documentEdit === true
    },

    isFinalized () {
      return !this.headerIsEmpty && this.despatch.despatchHeader.status === 2
    },

    headerIsEmpty () {
      return isEmpty(this.despatch.despatchHeader)
    },

    despatchHeaderTitle () {
      if (this.headerIsEmpty) return ''
      return this.despatch.despatchHeader.supplier.name
    },

    baseUnit () {
      return this.findUnit(this.item.unitId)
    },

    calculateBaseQuantity () {
      const valid = this.item.unitId !== '-1' &&
                      this.item.quantity &&
                      !this.veeErrors.has('unitId') &&
                      !this.veeErrors.has('quantity')
      if (!valid) return false

      const unit = this.baseUnit
      const value = this.item.quantity * unit.baseQuantity
      if (parseInt(value, 10) !== value) {
        return value.toString().slice(0, 5) // "integer" + "." + "precision:3" = 5 chars
      }
      return value
    },

    baseUnitPrice () {
      return this.item.unitPrice / this.baseUnit.baseQuantity
    },

    baseUnitPrice4Discount1 () {
      return this.baseUnitPrice * (1 - this.item.discount1 / 100)
    },

    baseUnitPrice4Discount2 () {
      return this.baseUnitPrice4Discount1 * (1 - this.item.discount2 / 100)
    },

    baseQuantityUnit () {
      const unit = this.findUnit(this.item.unitId)
      return unit.baseUnitName
    },

    calculateBasePrice () {
      const valid = this.item.unitId !== '-1' &&
                      this.item.unitPrice &&
                      !this.veeErrors.has('unitId')
      if (!valid) return false
      let value
      if (this.isEmptyDiscount1) {
        value = this.baseUnitPrice
      } else {
        if (this.isEmptyDiscount2) {
          value = this.baseUnitPrice4Discount1
        } else {
          value = this.baseUnitPrice4Discount2
        }
      }
      return value
    },

    unitPriceWithDiscount1 () {
      return this.item.unitPrice * (1 - this.item.discount1 / 100)
    },

    unitPriceWithDiscount2 () {
      return this.unitPriceWithDiscount1 * (1 - this.item.discount2 / 100)
    },

    discountedNetUnitPrice () {
      let value
      if (this.isEmptyDiscount1) {
        value = this.item.unitPrice
      }
      if (this.isEmptyDiscount2) {
        value = this.unitPriceWithDiscount1
      } else {
        value = this.unitPriceWithDiscount2
      }
      return value
    },

    netUnitPriceLineTotal () {
      return this.discountedNetUnitPrice * this.item.quantity
    },

    showStockDetail () {
      if (this.item.stockItem) return this.item.stockItem.hasOwnProperty('id')
    },

    isSupplierNotPermissionToGlobal () {
      return this.RoleArea === 'local' && this.isSupplierGlobal
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stock', [
      'searchStockItem',
      'getStockItem',
      'defaultStockItemPrice'
    ]),

    ...mapActions('Settings', [
      'fetchUser',
      'setRoleArea'
    ]),

    ...mapActions('Refund', [
      'get_REFUND',
      'get_REFUND_DETAIL',
      'create_REFUND_DETAIL',
      'update_REFUND_DETAIL'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList',
      'getStore',
      'fetchStoreByName'
    ]),

    ...mapActions('Transfer', [
      'getStockInfo'
    ]),

    ...mapMutations('Refund', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'UPDATE_REFUND_ITEM'
    ]),

    ...mapMutations('Stores', [
      'RESET_STORE'
    ]),

    ...mapActions('Despatch', [
      'get_PRICE_QUOTE'
    ]),
    
    close (val) {
      this.RESET_STORE()
      if (!val) {
        this.$router.push({
          name: this.$route.matched[0].name
        })
      }
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.item.storeId.id = data.id
        this.item.storeId.name = data.name
      }
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    resetForm (overrides = {}) {
      this.selectOptions = []
      this.stockItem = {}
      this.item = {
        stockItem: overrides.stockItem || null,
        baseUnitName: overrides.baseUnitName || null,
        taxId: overrides.taxId || '-1',
        quantity: overrides.quantity || null,
        unitId: overrides.unitId || '-1',
        unitPrice: overrides.unitPrice || 0,
        storeId: overrides.storeId || '-1'
      }
    },

    async fetchStockItems (despatch,isNew) {
      this.stockItemInfo = {}
      const storeInfo = isNew ? despatch.storeId.id : despatch.storeId
      await this.getStoreInfo(storeInfo)
      const payload = {
        storeId: isNew ? despatch.storeId.id : despatch.storeId,
        stockItemId: despatch.stockItem.id,
        unitId: despatch.stockItem.baseUnitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },

    getStoreInfo (storeId) {
      this.getStore(storeId).then(res => {
        this.allowNegativeStock = res.data.store.allowNegativeStock
      })
    },

    async getDefaultStockItemPrice (id) {
      const payload = {
        SupplierId: this.despatch.despatchHeader.supplier.id,
        StockItemId: this.stockItem.id,
        UnitId: id,
        Date: moment().format('YYYY-MM-DD')
      }
      this.defaultStockItemPrice(payload).then(res => {
        if (res.status === 200) {
          this.item.unitPrice = res.data.defaultunitPrice
        }
      })
    },

    async getStockData (id) {
      await this.getStockItem(id).then(res => {
        if (res.status === 200) {
          this.stockItem = res.data.stockItem
          if (this.stockItem.taxId) {
            this.item.taxId = this.stockItem.taxId
          }
          this.item.baseUnitName = this.stockItem.baseUnitName
        }
      })
    },

    setUnit (unitId) {
      this.item.unitId = unitId
    },

    onSubmitDespatchDetailForm: vueWaitLoader(async function () {
      await this.$validator.validateAll()
        .then(async result => {
          if (!result || !this.discountControl) this.enableSubmitButton()
          else {
            if (this.isEdit) {
              await this.submitEdit()
            } else {
              await this.submitNew()
            }
          }
        })
    }, 'submitForm'),

    submitNew () {
      this.create_REFUND_DETAIL(this.newItem)
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Created', { name: this.item.stockItem.name })
            this.notifyShow({ message })
            this.get_REFUND(res.data.despatchDetail.despatchHeaderId).then(res => {
              if (res.status === 200) {
                this.UPDATE_REFUND_ITEM(res.data.despatchHeader)
                this.SHOW_DETAIL(res.data)
              }
            })
            if (this.saveAndNew) {
              this.resetForm({
                storeId: {id: this.item.storeId.id, name: this.item.storeId.name}
              })
              this.$nextTick(() => {
                this.$validator.reset()
              })
            } else {
              this.close()
            }
          }
          this.enableSubmitButton()
        })
    },

    submitEdit () {
      this.update_REFUND_DETAIL(this.newEdit).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: this.item.stockItem.name })
          this.notifyShow({ message })

          this.get_REFUND(this.despatch.despatchHeader.id).then(res => {
            if (res.status === 200) {
              this.UPDATE_REFUND_ITEM(res.data.despatchHeader)
              if (!this.isDocumentEdit) this.SHOW_DETAIL(res.data)
            }
          })
          this.close()
          if (this.isDocumentEdit) this.$emit('getList', this.despatch.despatchHeader.id)
        }
        this.enableSubmitButton()
      })
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 4]})
        .then(res => {
          if (res.status === 200) {
            this.selectOptions = res.data.items
          }
        })
    },

    async getPriceQuote () {
      const payload = {
        DespatchHeaderId: this.despatch.despatchHeader.id,
        StockItemId: this.item.stockItem.id,
        UnitId: this.item.unitId
      }
      this.item.discount1 = null
      this.item.discount2 = null
      this.item.unitPrice = 0

      if (!this.isEdit) {
        this.get_PRICE_QUOTE(payload).then(res => {
          if (res.status === 200) {
            if (res.data.priceQuote !== null) {
              this.item.discount1 = res.data.priceQuote.discount1 || null
              this.item.discount2 = res.data.priceQuote.discount2 || null
              this.item.baseUnitPrice = res.data.priceQuote.baseUnitPrice || 0
              this.item.unitPrice = res.data.priceQuote.unitPrice || 0
              this.isSupplierGlobal = res.data.priceQuote.isSupplierGlobal
            } else {
              this.discountControl = true
              this.item.baseUnitPrice = 0
            }
          }
        })
      } else {
        this.item = JSON.parse(JSON.stringify(this.despatch.despatchDetail))
      }
    }
  }
}
</script>

<style lang="scss">

  .Popup-despatch-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;

    div {
      margin-right: 30px;

      svg {
        color: $color-success;
        margin-right: 5px;
      }
    }
  }

  .Form-item-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    font-size: $font-size-small;

    > div {
      display: flex;
      margin-right: 20px;
      align-items: center;

      select {
        margin-bottom: -2px;
        border: 0;
        background-color: $color-gray;
      }
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

  .Form-item-info-multiple {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    flex-wrap: wrap;
    margin-top: 17px;

    &-row {
      display: flex;
      justify-content: space-between;
      border: solid $color-gray 1px;
      width: 100%;
      padding: 17px 10px;
    }

    &-row:first-child {
       border-bottom: none;
     }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

.margin-discount {
  margin-top: 17px;
}

</style>

import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import Despatches from '../model'
import BatchList from '../model/refund-batch'
import { setTranslations } from '@/i18n'
import { getRefundErrorHeaderFields } from '../fields/refundErrorFields'

export default {
  namespaced: true,

  state: {
    refunds: new Despatches(),
    batchList: new BatchList(),
    pageNumber: 1,
    pageSize: 99999,
    totalCount: null
  },

  getters: {
    refund_List: state => state.refunds.list,
    refund_IsEmpty: state => state.refunds.list.length === 0,
    refund_PageNumber: state => state.pageNumber,
    refund_PageSize: state => state.pageSize,
    refund_PageLast: state => Math.ceil(state.totalCount / state.pageSize),
    refund_TotalCount: state => state.totalCount,
    batchList: state => state.batchList.list
  },

  actions: {
    async get_REFUND_LIST({ state, commit, dispatch }, params) {
      let URL = 'Return/header/all'

      const results = await requestHandler({
        title: 'get_REFUND_LIST',
        methodName: 'get_REFUND_LIST',
        method: () => {
          return HTTP.post(URL, {
            pageNumber: 1,
            pageSize: state.pageSize,
            ...params
          })
        },
        success: result => {
          commit('UPDATE_REFUND_LIST', result.data)
          return result
        }
      })
      return results
    },

    async get_REFUND({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'get_REFUND',
        methodName: 'get_REFUND',
        method: () => {
          return HTTP.get('Return', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async get_REFUND_FINALIZE({ state, commit, dispatch }, Id, invoiceType = 2) {
      const results = await requestHandler({
        title: 'get_REFUND_FINALIZE',
        methodName: 'get_REFUND_FINALIZE',
        method: () => {
          return HTTP.put('Return/finalize', { ...Id, invoiceType })
        },
        success: async result => {
          if (!result.data.isSucceeded) {
            const i18n = await setTranslations()
            const fields = getRefundErrorHeaderFields(i18n)
            const resultData = result.data.inCorrectReturnedStocks.map(el => {
              return {
                stockItem: el
              }
            })
            dispatch('Notify/NOTIFY_DATA_SHOW', { data: resultData, fields: fields }, { root: true })
            dispatch('Notify/notifyErrorShow', { errorMessage: i18n.t('ApiErrorCode.3010') }, { root: true })
            return false
          } else {
            return result
          }
        }
      })
      return results
    },

    // HEADER
    async create_REFUND_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'create_REFUND_HEADER',
        methodName: 'create_REFUND_HEADER',
        method: () => {
          return HTTP.post('Return/header', item)
        },
        success: result => result
      })
      return results
    },

    async update_REFUND_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'update_REFUND_HEADER',
        methodName: 'update_REFUND_HEADER',
        method: () => {
          return HTTP.put('Return/header', item)
        },
        success: result => result
      })
      return results
    },

    async delete_REFUND_HEADER({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'delete_REFUND_ITEM',
        methodName: 'delete_REFUND_ITEM',
        method: () => {
          return HTTP.delete('Return', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    // DETAIL
    async get_REFUND_DETAIL({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'get_REFUND_DETAIL',
        methodName: 'get_REFUND_DETAIL',
        method: () => {
          return HTTP.get('Return/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async create_REFUND_DETAIL({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'create_REFUND_DETAIL',
        methodName: 'create_REFUND_DETAIL',
        method: () => {
          return HTTP.post('Return/detail', item)
        },
        success: result => result
      })
      return results
    },

    async update_REFUND_DETAIL({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'update_REFUND_DETAIL',
        methodName: 'update_REFUND_DETAIL',
        method: () => {
          return HTTP.put('Return/detail', item)
        },
        success: result => result
      })
      return results
    },

    async delete_REFUND_DETAIL({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'delete_REFUND_ITEM',
        methodName: 'delete_REFUND_ITEM',
        method: () => {
          return HTTP.delete('Return/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkRefundNumber({ state, commit, dispatch }, params) {
      const results = await requestHandler({
        title: 'checkRefundNumber',
        methodName: 'checkRefundNumber',
        method: () => {
          return HTTP.get('Return/header/check/despatchnumber', {
            params
          })
        },
        success: result => result
      })
      return results
    },

    async postSelectedRefunds({ commit }, list) {
      const results = await requestHandler({
        title: 'postSelectedRefunds',
        methodName: 'postSelectedRefunds',
        method: () => {
          return HTTP.post('Return/integration/accounting/send', list)
        },
        success: result => result
      })
      return results
    },

    async getRefundBatchDetails({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getBatchDetails',
        methodName: 'getBatchDetails',
        method: () => {
          return HTTP.post('Batch/return/all', payload)
        },
        success: result => result
      })
      return results
    },

    async getExpireDateData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getExpireDateData',
        methodName: 'getExpireDateData',
        method: () => {
          return HTTP.post('Despatch/detail/batch', payload)
        },
        success: result => {
          commit('UPDATE_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },
    
    async updateRefundBatchQuantity({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'updateRefundBatchQuantity',
        methodName: 'updateRefundBatchQuantity',
        method: () => {
          return HTTP.put('Batch/return/quantity', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.refunds = new Despatches()
      state.pageNumber = 1
      state.pageSize = 99
      state.totalCount = null
    },

    UPDATE_REFUND_LIST(state, data) {
      state.refunds.addItems(data.despatchHeaderList)
      state.pageNumber = data.pageNumber
      state.totalCount = data.totalCount
    },

    ADD_REFUND_ITEM(state, item) {
      // state.refund.addItem(item)
      // state.totalCount = state.totalCount + 1
    },

    UPDATE_REFUND_ITEM(state, item) {
      state.refunds.updateItem(item)
    },

    //
    // DETAIL
    //
    HIDE_DETAIL(state, despatchHeader) {
      despatchHeader.showDetail = false
    },

    HIDE_OTHERS_DETAILS(state, despatchHeader) {
      const others = state.refunds.list.filter(despatch => despatch.id !== despatchHeader.id)
      others.forEach(otherDespatch => {
        otherDespatch.showDetail = false
      })
    },

    SHOW_DETAIL(state, data) {
      const refund = state.refunds.findItem(data.despatchHeader.id)
      refund.addDetails(data.despatchDetailList)
      refund.showDetail = true
    },
    UPDATE_BATCH_LIST(state, data) {
      state.batchList.addItems(data.despatchDetailList)
    },

    SHOW_BATCH_DETAIL(state, data) {
      let batchList = state.batchList.findItem(data.id)
      batchList.showDetail = true
      batchList.addDetails(data.batchList)
    },
    HIDE_BATCH_DETAIL(state, batchList) {
      batchList.showDetail = false
    }
  }
}

<template lang="pug">
  Modal(:isOpen="true" @closeModal="closeModal")
    template(slot="title")
      span {{$t('EInvoice.selectMatchedOrder')}}
      
    template(slot="content")

      TableLoading(v-if="isFetchingData")

      NestedTable(
        v-if="approveOrders.length && !isFetchingData"
        ref="ordersNestedTable"
        :fields="fields",
        :detailFields="detailFields",
        :data="approveOrders"
        :statusIcon="false"        
        :canAddNewDetail="false"
        :isFinalizeAction="false"
        @showDetailAction="showOrderDetail"
      )

      EmptyState(
        v-if="!approveOrders.length && !isFetchingData"
        :title="$t('EInvoice.selectOrder_empty')"
        :description="$t('EInvoice.selectOrder_empty')")
      
      portal(to="checkbox")
        div(slot-scope="header")
          CustomRadio(
            id="select-order-for-eInvoice"
            :checked="header.row === selectedOrder"
            label=""
            @change="selectOrder(header.row)")
      
      portal(to="description")
        div(slot-scope="header")
          Button.show-MiniPopup.relative.width-max-content(
            v-if="header.row.description",
            size="medium"
            variant="info"
            :id="`eInvoice-order-header-info-button-${header.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ header.row.description }}

    template(slot="footer")
      Button.m-top-5(
      v-if="approveOrders.length && !isFetchingData"
      id="nested-detail-finalize-order",
      inline,
      size="small"
      primary
      iconName="icon-btn-success",
      iconClass="icon-btn-success",
      :isLoading="isWaitingUpdate"
      :disabled="!selectedOrder"
      @click="saveSelectedOrder")
        span {{ $t('EInvoice.saveSelection') }}

     
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getOrdersHeaderFields, getOrdersDetailFields } from '../fields/approvedOrders'

export default {
  name: 'EInvoiceSelectOrder',
  data () {
    return {
      fields: getOrdersHeaderFields(this.$t),
      detailFields: getOrdersDetailFields(this.$t),
      eInvoice: [],
      selectedOrder: null
    }
  },
  async mounted() {
    this.eInvoice = this.$route.params
    await this.getOrdersHeaderAll()
  },

  computed: {

    ...mapGetters('Orders', [
      'approveOrders'
    ]),

    isFetchingData () {
      return this.$wait.is('getOrdersHeader')
    },

    isWaitingUpdate() {
      return this.$wait.is('updateOrderForEInvoice')
    }
  },

  methods: {
    ...mapActions('EInvoice', [
      'updateOrderForEInvoice'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Orders', ['getOrdersHeader', 'getOrderDetail']),

    ...mapMutations('Orders', [
      'SHOW_DETAIL',
      'HIDE_DETAIL'
    ]),

    selectOrder (item) {
      this.selectedOrder = item
    },

    saveSelectedOrder () {
      const payload = {
        rawEInvoiceId: this.eInvoice.id,
        orderNo: this.selectedOrder.orderNumber
      }
      this.updateOrderForEInvoice(payload).then(res => {
        if (res) {
          let message = this.$t('EInvoice.order_selected_successfully')
          this.notifyShow({ message })
          this.$emit('getList')
          this.closeModal()
        }
      })

    },

    closeModal () {
      this.$router.push({name: 'EInvoice'})
    },

    async getOrdersHeaderAll () {
      const payload = {
        storeIdList: [this.eInvoice.storeId],
        supplierIdList: [this.eInvoice.supplierId],
        startDate: null,
        endDate: null,
        pageNumber: 1,
        pageSize: 99999999,
        filterType: 2,
        useInfinityPageSize: true
      }
      await this.getOrdersHeader(payload)
    },

    showOrderDetail (header) {
      const item = this.approveOrders.find(i => i.id === header.id)
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      this.getOrderDetail(item)
    },
  }

}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

:deep() .Modal-Body {
  overflow: auto !important;
}
:deep() .Modal-Body-content {
  width: 85vw !important;
  height: 60vh !important;
}

:deep() .Modal-Body-footer {
  display: flex;
  justify-content: end;
}

.title-docType {
  font-size: 16px
}

:deep() .select {
  width: unset;
}

:deep() .Modal-Body-header .title {
  display: flex;
  flex-direction: column;
}

:deep() .NestedTable-item {
  width: auto !important;
}
</style>

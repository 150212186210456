<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Despatches.detail_Remove_TableName')",
  loading="Despatch/delete_DESPATCH_DETAIL",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DespatchRemove',

  data () {
    return {
      fields: [{
        name: 'stockItem.name',
        is_visible: true,
        short_title: this.$t('Despatches.detail_Remove_TableColumn_StockItemName_Short'),
        title: this.$t('Despatches.detail_Remove_TableColumn_StockItemName')
      }, {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Despatches.detail_Remove_TableColumn_Quantity_Short'),
        title: this.$t('Despatches.detail_Remove_TableColumn_Quantity')
      }, {
        name: 'stockItem.unitCode',
        is_visible: true,
        short_title: this.$t('Despatches.detail_Remove_TableColumn_UnitCode_Short'),
        title: this.$t('Despatches.detail_Remove_TableColumn_UnitCode')
      }, {
        name: 'unitPrice',
        is_visible: true,
        is_currency: true,
        short_title: this.$t('Despatches.detail_Remove_TableColumn_NetUnitPrice_Short'),
        title: this.$t('Despatches.detail_Remove_TableColumn_NetUnitPrice')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.get_DESPATCH_DETAIL(this.$route.params.detailId)
        .then(res => {
          this.item = res.data.despatchDetail
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH',
      'delete_DESPATCH_DETAIL',
      'get_DESPATCH_DETAIL'
    ]),

    ...mapMutations('Despatch', [
      'UPDATE_DESPATCH_ITEM',
      'SHOW_DETAIL'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_DESPATCH_DETAIL(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Despatches.detail_Notification_Deleted', { name: this.item.stockItem.name })
          this.notifyShow({ message })
          this.get_DESPATCH(this.item.despatchHeaderId).then(res => {
            if (res) {
              this.UPDATE_DESPATCH_ITEM(res.data.despatchHeader)
              this.SHOW_DETAIL(res.data)
            }
          })
          this.close()
        }
      })
    }, 'deleteForm')
  }
}
</script>

<style lang="scss">
</style>

export function getOrdersHeaderFields($t) {
  return [
		{
			name: 'checkbox',
			is_visible: true,
			type: 'portal',
			get short_title() {
				return ''
			},
			get title() {
				return ''
			}
		},
		{
			name: 'supplierName',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_supplier')
			},
			get title() {
				return $t('Orders.order_header_supplier')
			}
		},
		{
			name: 'storeName',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_store')
			},
			get title() {
				return $t('Orders.order_header_store')
			}
		},
		{
			name: 'orderDate',
			type: 'date',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_date')
			},
			get title() {
				return $t('Orders.order_header_date')
			}
		},
		{
			name: 'createdOn',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_created_on_date')
			},
			get title() {
				return $t('Orders.order_created_on_date')
			}
		},
		{
			name: 'netTotalAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_totalAmount')
			},
			get title() {
				return $t('Orders.order_header_totalAmount')
			}
		},
		{
			name: 'taxTotalAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_taxAmount')
			},
			get title() {
				return $t('Orders.order_header_taxAmount')
			}
		},
		{
			name: 'grossTotalAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_grossAmount')
			},
			get title() {
				return $t('Orders.order_header_grossAmount')
			}
		},
		{
			name: 'orderNumber',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_header_orderNo')
			},
			get title() {
				return $t('Orders.order_header_orderNo')
			}
		},
		{
			name: 'integrationStatusName',
			is_visible: false,
			selectValue: 'integrationStatusId',
			get short_title() {
				return $t('Orders.status')
			},
			get title() {
				return $t('Orders.status')
			}
		},
		{
			name: 'description',
			type: 'portal',
			is_visible: true,
			get short_title() {
				return ''
			},
			get title() {
				return ''
			}
		}
	]
}

export function getOrdersDetailFields($t) {
	return [
		{
			name: 'detailCheckbox',
			type: 'portal',
			is_visible: false
		},
		{
			type: 'fixed',
			name: 'stockItemName',
			style: 'min-width: 200px;',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_stock')
			},
			get title() {
				return $t('Orders.order_detail_stock')
			}
		},
		{
			name: 'unitName',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_unitName')
			},
			get title() {
				return $t('Orders.order_detail_unitName')
			}
		},
		{
			name: 'quantity',
			type: 'mono',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_quantity')
			},
			get title() {
				return $t('Orders.order_detail_quantity')
			}
		},
		{
			name: 'stockItemNumber',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_stockItemNumber')
			},
			get title() {
				return $t('Orders.order_detail_stockItemNumber')
			}
		},
		{
			name: 'supplierStockItemCode',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_supplierStockItemCode')
			},
			get title() {
				return $t('Orders.order_detail_supplierStockItemCode')
			}
		},
		{
			name: 'price',
			is_visible: true,
			type: 'currency',
			get short_title() {
				return $t('Orders.order_detail_price')
			},
			get title() {
				return $t('Orders.order_detail_price')
			}
		},
		{
			name: 'discount1',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_discount1')
			},
			get title() {
				return $t('Orders.order_detail_discount1')
			}
		},
		{
			name: 'discount2',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_discount2')
			},
			get title() {
				return $t('Orders.order_detail_discount2')
			}
		},
		{
			name: 'taxAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_taxAmount')
			},
			get title() {
				return $t('Orders.order_detail_taxAmount')
			}
		},
		{
			name: 'netAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_netAmount')
			},
			get title() {
				return $t('Orders.order_detail_netAmount')
			}
		},
		{
			name: 'grossAmount',
			type: 'currency',
			is_visible: true,
			get short_title() {
				return $t('Orders.order_detail_grossAmount')
			},
			get title() {
				return $t('Orders.order_detail_grossAmount')
			}
		},
		{
			name: 'detailDescription',
			type: 'portal',
			is_visible: true,
			get short_title() {
				return ''
			},
			get title() {
				return ''
			}
		}
	]
}

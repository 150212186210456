export default function getDespatchDocumentDetailFields($t) {
  return [
    {
      name: 'stockItem',
      type: 'portal',
      fixedColumnHeader: true,
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      }
    },
    {
      name: 'originalOrderQuantity',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_OrderQuantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_OrderQuantity')
      }
    },
    {
      name: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'unitName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      }
    },
    {
      name: 'unitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_NetUnitPrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_NetUnitPrice')
      }
    },
    {
      name: 'netAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_NetAmount')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_NetAmount')
      }
    },
    {
      name: 'baseQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_BaseQuantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BaseQuantity')
      }
    },
    {
      name: 'baseUnitName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_BaseUnit')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BaseUnit')
      }
    },
    {
      name: 'netBaseUnitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_BasePrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BasePrice')
      }
    },
    {
      name: 'discount1',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Discount1')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Discount1')
      }
    },
    {
      name: 'discount2',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Discount2')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Discount2')
      }
    },
    {
      name: 'discountedUnitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_DiscountedUnitPrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_DiscountedUnitPrice')
      }
    },
    {
      name: 'discountedBaseUnitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_DiscountedBaseUnitPrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_DiscountedBaseUnitPrice')
      }
    },
    {
      name: 'taxRatio',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_TaxRatio')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_TaxRatio')
      }
    },
    {
      name: 'discountedNetUnitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_DiscountedNetUnitPrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_DiscountedNetUnitPrice')
      }
    },
    {
      name: 'taxAmount',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_Tax_Amount')
      },
      get title() {
        return $t('Despatches.detail_Tax_Amount')
      }
    },
    {
      name: 'grossAmount',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_Gross_Amount')
      },
      get title() {
        return $t('Despatches.detail_Gross_Amount')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_StoreName')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_StoreName')
      }
    }
  ]
}

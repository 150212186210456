var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Despatches.popup_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Despatches.popup_CreateTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchForm.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "Form-item required" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.popup_FormField_SupplierName"))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "firstInput",
                      attrs: {
                        "component-name": "despatch-supplier",
                        name: "supplier",
                        "data-vv-as": _vm.$t(
                          "Despatches.popup_FormField_SupplierName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("supplier"),
                        },
                        disabled:
                          _vm.withDetailSave || _vm.despatch.status === 1,
                        "on-search": _vm.getOptions,
                        options: _vm.selectOptions,
                        label: "name",
                        placeholder: _vm.$t(
                          "Despatches.popup_FormField_SupplierName_Placeholder"
                        ),
                        loading: _vm.isLoadingSearchSupplier,
                      },
                      model: {
                        value: _vm.item.supplier,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "supplier", $$v)
                        },
                        expression: "item.supplier",
                      },
                    }),
                  ],
                  1
                ),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("supplier"),
                      expression: "veeErrors.has('supplier')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("supplier") },
                }),
              ],
              1
            ),
            _vm.tenantMultiCurrencyIsActive ||
            _vm.tenantMustHaveSingleStoreInSameDespatchActive
              ? _c(
                  "div",
                  { staticClass: "Form-item required" },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.popup_FormField_StoreName"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "secondaryInput",
                          attrs: {
                            "component-name": "despatch-store-select",
                            name: "storeSelect",
                            "data-vv-as": _vm.$t(
                              "Despatches.popup_FormField_StoreName"
                            ),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("storeSelect"),
                            },
                            disabled:
                              _vm.withDetailSave ||
                              _vm.despatch.status === 1 ||
                              !_vm.item.supplier,
                            "on-search": _vm.getOptionsStore,
                            options: _vm.selectMiniStoreList,
                            label: "name",
                            searchAll: true,
                            withDebounce: false,
                            placeholder: _vm.$t(
                              "Despatches.popup_FormField_StoreName_Placeholder"
                            ),
                            loading: _vm.isSearchStoreLists,
                          },
                          on: {
                            input: _vm.inputSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.item.parentStore,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "parentStore", $$v)
                            },
                            expression: "item.parentStore",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("storeSelect"),
                          expression: "veeErrors.has('storeSelect')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("storeSelect") },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.popup_FormField_DespatchNumber"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:32|verify_number",
                        expression: "'required|max:32|verify_number'",
                      },
                    ],
                    class: { disabled: !_vm.item.supplier },
                    attrs: {
                      name: "despatchNumber",
                      id: "input-despatch-popup-number",
                      "data-vv-as": _vm.$t(
                        "Despatches.popup_FormField_DespatchNumber"
                      ),
                      error: _vm.veeErrors.has("despatchNumber"),
                      disabled: !_vm.item.supplier,
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.item.despatchNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "despatchNumber", $$v)
                      },
                      expression: "item.despatchNumber",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingNumber,
                          expression: "isPendingNumber",
                        },
                      ],
                      attrs: {
                        id: "btn-despatch-popup-loading-number",
                        type: "button",
                        variant: "icon loading",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateNumber,
                        expression: "isValidateNumber",
                      },
                    ],
                    attrs: {
                      id: "btn-despatch-popup-ok-number",
                      type: "button",
                      variant: "icon check",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("despatchNumber"),
                        expression: "veeErrors.has('despatchNumber')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("despatchNumber") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.popup_DespatchDate"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has(`despatchDate`) },
                    attrs: {
                      name: "despatchDate",
                      "data-vv-as": _vm.$t("Despatches.popup_DespatchDate"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                      disabledStartDate: _vm.disableDate,
                    },
                    model: {
                      value: _vm.item.despatchDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "despatchDate", $$v)
                      },
                      expression: "item.despatchDate",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("despatchDate"),
                        expression: "veeErrors.has('despatchDate')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("despatchDate") },
                  }),
                ],
                1
              ),
            ]),
            _vm.isStoreDifferentCurrency && !_vm.isCurrencySame
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Despatches.popup_Rate"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("currency-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:0|required",
                            expression: "'greater_than:0|required'",
                          },
                        ],
                        staticClass: "txt currency",
                        attrs: {
                          id: "exchange-rate-despatch",
                          name: "exchangeRate",
                          "data-vv-as": _vm.$t(
                            "Despatches.popup_FormField_Rate"
                          ),
                          placeholder: _vm.$t(
                            "Despatches.popup_FormField_Rate"
                          ),
                          error: _vm.veeErrors.has("exchangeRate"),
                        },
                        model: {
                          value: _vm.item.exchangeRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "exchangeRate", $$v)
                          },
                          expression: "item.exchangeRate",
                        },
                      }),
                      _c("div", { staticClass: "currency-symbol" }, [
                        _vm._v(_vm._s(_vm.rateIsoCode)),
                      ]),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("exchangeRate"),
                            expression: "veeErrors.has('exchangeRate')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("exchangeRate"),
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            !_vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "Form-item", class: _vm.isOnlyListUsageClass },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.popup_FormField_despatchListName")
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "div",
                        { staticClass: "despatch-list" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.isOnlyListUsageControl },
                                expression:
                                  "{required: isOnlyListUsageControl}",
                              },
                            ],
                            staticClass: "form-item-select",
                            attrs: {
                              "component-name": "despatch-list",
                              name: "despatchList",
                              disabled: _vm.despatchListDisabled,
                              "on-search": _vm.getDespatchListOptions,
                              options: _vm.selectOptionsDespatchList,
                              label: "name",
                              "data-vv-as": _vm.$t(
                                "Despatches.popup_FormField_despatchListName"
                              ),
                              error: _vm.veeErrors.has("despatchList"),
                              searchAll: true,
                              placeholder: _vm.$t(
                                "Despatches.popup_FormField_despatchListName_Placeholder"
                              ),
                            },
                            model: {
                              value: _vm.item.despatchList,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "despatchList", $$v)
                              },
                              expression: "item.despatchList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("despatchList"),
                          expression: "veeErrors.has('despatchList')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("despatchList") },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "selected-items" },
                  _vm._l(_vm.selectedDespatchLists, function (item, index) {
                    return _c(
                      "Button",
                      {
                        key: index,
                        attrs: {
                          size: "small",
                          seashell: "",
                          id: `selected-despatch-list-item-${index}`,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeDespatchListFromList(item)
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _c("Icon", {
                          staticClass: "m-left-5",
                          attrs: {
                            name: "icon-popup-close",
                            id: `selected-despatch-list-item-delete-${index}`,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("DualRadioButton", {
                  attrs: {
                    disabled: _vm.isEdit,
                    isActive: _vm.item.unitPriceType === 2 ? true : false,
                    isActiveLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchTypeNET"
                    ),
                    isPassiveLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchTypeBRUT"
                    ),
                    headerLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchType"
                    ),
                  },
                  on: { delegateIsActiveValue: _vm.changeUnitPriceType },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Global.description"))),
                ]),
                _c("customTextareaInput", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:128",
                      expression: "'max:128'",
                    },
                  ],
                  ref: "descriptionInput",
                  attrs: {
                    name: "description",
                    "data-vv-as": _vm.$t("Global.description"),
                    error: _vm.veeErrors.has("description"),
                    rows: "3",
                  },
                  model: {
                    value: _vm.item.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "description", $$v)
                    },
                    expression: "item.description",
                  },
                }),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("description"),
                      expression: "veeErrors.has('description')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("description") },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                id: "btn-despatch-popup-submit",
                type: "submit",
                size: "medium",
                variant: "full",
                primary: "",
                form: "form-despatch",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("Despatches.popup_FinishEditButton"))
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("Despatches.popup_FinishCreateButton"))
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { isOpen: true }, on: { closeModal: _vm.closeModal } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.headerData.errorCode &&
                    _vm.headerData.rawEInvoiceStatus === 2,
                  expression:
                    "headerData.errorCode && headerData.rawEInvoiceStatus === 2",
                },
              ],
              staticClass: "error-code relative width-max-content m-top-10",
              attrs: { clearVariant: true },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.hasError")))])]
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.headerData.rawEInvoiceStatus === 1,
                  expression: "headerData.rawEInvoiceStatus === 1",
                },
              ],
              staticClass: "waiting-label relative width-max-content m-top-10",
              attrs: { clearVariant: true },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.waiting")))])]
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.headerData.rawEInvoiceStatus === 3,
                  expression: "headerData.rawEInvoiceStatus === 3",
                },
              ],
              staticClass: "success-label relative width-max-content m-top-10",
              attrs: { clearVariant: true },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.successful")))])]
          ),
          _c("span", { staticClass: "m-top-10 title-supplier" }, [
            _vm._v(_vm._s(_vm.headerData.supplierName)),
          ]),
          _c("span", { staticClass: "m-top-10 title-docType" }, [
            _vm._v(
              _vm._s(
                _vm.headerData.docType === 1
                  ? _vm.$t("Supplier.tableColumn_Invoice")
                  : _vm.$t("Supplier.eArchive")
              ) +
                " - " +
                _vm._s(_vm.headerData.invoiceNo)
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c("TableView", {
            attrs: {
              actions: [""],
              readOnly: true,
              data: _vm.invoiceDetailList,
              fields: _vm.detailFields,
              addScrollToNoFixedColumns: true,
              componentName: "EInvoicePage",
            },
          }),
          _c("portal", {
            attrs: { to: "matchingStockItemName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1
                        ? _c("customSelectInput", {
                            staticClass: "custom-select",
                            attrs: {
                              name: "matchingStockItems",
                              optionData: _vm.matchingStockItems,
                              optionKey: "id",
                              optionIdKey: "id",
                              disabled: !row.isActive,
                              id: "eInvoice-matching-stockItem-list",
                              extraDataEmit: row,
                              selectedDataRequired: true,
                              isChangeValue: true,
                              hideDefault: false,
                              optionIdName: "eInvoice-matching-stockItem-list",
                            },
                            on: { change: _vm.updateMatchingStockItem },
                            model: {
                              value: row.matchingStockItemId,
                              callback: function ($$v) {
                                _vm.$set(row, "matchingStockItemId", $$v)
                              },
                              expression: "row.matchingStockItemId",
                            },
                          })
                        : _c("span", [
                            _vm._v(_vm._s(row.matchingStockItemName)),
                          ]),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "matchingUnitName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1
                        ? _c("customSelectInput", {
                            staticClass: "custom-select",
                            attrs: {
                              name: "matchingUnitId",
                              disabled: !row.isActive,
                              optionData: _vm.matchingUnits,
                              optionKey: "id",
                              optionIdKey: "id",
                              id: "eInvoice-matching-unit-list",
                              extraDataEmit: row,
                              selectedDataRequired: true,
                              isChangeValue: true,
                              hideDefault: false,
                              optionIdName: "eInvoice-matching-unit-list",
                            },
                            on: { change: _vm.updateMatchingUnit },
                            model: {
                              value: row.matchingUnitId,
                              callback: function ($$v) {
                                _vm.$set(row, "matchingUnitId", $$v)
                              },
                              expression: "row.matchingUnitId",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(row.matchingUnitName))]),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template lang="pug">
transition.transition(
    name ="zoom"
  )
    .Alert

      .Alert-body

        .Alert-body-title
          span {{ $t('EInvoice.uploading_EArchive_Document')}}
        .Alert-body-subtitle.m-top-15
          span {{ $t('EInvoice.docInfos')}}
        .Alert-body-header.m-top-10
          span {{ $t('EInvoice.totalCount', {length: data.length }) }}
          span {{data.filter(i => i.code && i.code.status === 200).length}} / {{data.length}}

        .Alert-item(v-for="item in data")
          .Alert-item-name
            Icon(name="icon-file").m-right-10

            span.tooltip-relative
              span {{ getFileName(item.name) }}
              Tooltip.tooltip(
                :text="(item.name)"
                white,
                bottom
              )

          .Alert-item-code(v-if="item.code && item.code.status === 200 && !isLoading")
            span.message-success {{ $t('EInvoice.successful') }}
            Icon(name="icon-success").m-left-10
          .Alert-item-code(v-else-if="item.code && !item.code.status !== 200 && !isLoading")
            span.message-error {{ $t('EInvoice.ubl_errorCode_' + item.code) }}
            Icon(name="icon-failed").m-left-10
          .Alert-item-code(v-else)
            span.message-pending {{ $t('EInvoice.loading') }}
            Icon(name="icon-pending").m-left-10
        .Alert-footer
          Button.m-top-15(
            primary
            type= "button"
            size= "small"
            @click="closeAlert"
            :disabled="isLoading"
          )
            span {{ $t('Global.alert_OkButton') }}

</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'UBLUpload',

  data () {
    return {

    }
  },


  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Recipes', [
      'delete_RECIPE_HEADER',
      'get_RECIPE_HEADER',
      'get_RECIPE_DETAIL'
    ]),

    getFileName (file) {
      return file.length > 30 ? file.substr(0, 30).concat('...') : file
    },

    closeAlert () {
      this.$emit('closeUploadScreen')
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_RECIPE_HEADER(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'removeRecipeHeaderForm')
  }
}
</script>

<style lang="scss" scoped>

  .zoom-enter-active,
  .zoom-leave-active {
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.4);
  }

  .zoom-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .zoom-leave-to {
    transform: scale(0.6);
  }

  .Alert {
    z-index: $z-index-xl;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &-footer {
      justify-content: space-evenly;
      display: flex;
      Button {
        width: 200px;
      }
    }

    &-body {
      text-align: center;
      &-title {
        font-size: $font-size-Hbigger
      }
      &-subtitle {
        font-size: $font-size-big;
      }
      &-header {
        display: flex;
        justify-content: space-between;
      }

    }

    &-icon {
      margin-bottom: 30px;

      svg {
          width: 56px;
          height: 56px;
          color: #FE5A37;
      }
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
    }

    &-item {
      width: 640px;
      border: 1px solid #EAEAEB;
      margin-top: 10px;
      display: flex;
      padding: 13px 40px;
      justify-content: space-between;
      max-width: 900px;
      box-shadow: 0 2px 6px rgba(black, 0.05);
      background-color: white;
      &-name {
        display: flex;
        align-items: center;
      }
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 40px;
      display: flex;
      align-items: center;

      .btn {
        min-width: 180px;
        margin-left: 10px;
        margin-right: 10px;

        span {
            text-transform: uppercase;
        }
      }
    }
  }
  .message {
    &-success{
      color: $color-success;
      font-size: $font-size-normal;
      font-weight: 400;
    }
    &-error{
      color: $color-warning;
      font-size: $font-size-normal;
      font-weight: 400;
    }
    &-pending{
      color: $color-primary;
      font-size: $font-size-normal;
      font-weight: 400;
    }
  }
  .tooltip {
    width: 100%;
  }
  :deep() .appTooltip .text {
    max-width: unset !important;
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.despatchHeaderTitle)),
      ]),
      _c("template", { slot: "detail" }, [
        !_vm.headerIsEmpty
          ? _c("div", { staticClass: "Popup-despatch-info" }, [
              _c(
                "div",
                [
                  _c("Icon", { attrs: { name: "icon-calender" } }),
                  _c("span", [_vm._v(_vm._s(_vm.despatchDate))]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Icon", { attrs: { name: "icon-popup-despatch-no" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.despatch.despatchHeader.despatchNumber)),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch-detail" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchDetailForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_StoreName"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1|verify_inventory",
                        expression: "'required|is_not:-1|verify_inventory'",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      "component-name": "despatch-detail-refund-store-select",
                      name: "storeName",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName"
                      ),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeSelect"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.selectMiniStoreList,
                      label: "name",
                      disabled: _vm.tenantMultiCurrencyIsActive,
                      searchAll: true,
                      withDebounce: false,
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName_Unselected"
                      ),
                      loading: _vm.isSearchStoreLists,
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.item.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "storeId", $$v)
                      },
                      expression: "item.storeId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeName"),
                        expression: "veeErrors.has('storeName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeName") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("Despatches.detail_Popup_FormField_StockItemName")
                  )
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|verify_inventory",
                        expression: "'required|verify_inventory'",
                      },
                    ],
                    attrs: {
                      name: "stockItem",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StockItemName"
                      ),
                      inputClass: { "is-danger": _vm.inventoryValidation },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      disabled: _vm.isEdit,
                      label: "name",
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                      ),
                      directSelectOption: _vm.directSelectOption,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.fetchStockItems(_vm.item, true)
                      },
                    },
                    model: {
                      value: _vm.item.stockItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "stockItem", $$v)
                      },
                      expression: "item.stockItem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inventoryValidation,
                      expression: "inventoryValidation",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.validationErrorMessage))]
              ),
              _vm.showStockDetail
                ? _c("div", { staticClass: "Form-item-info" }, [
                    _c("div", [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.item.stockItem.baseUnitName)),
                      ]),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FormField_StockItemName_InfoTax"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("customSelectInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            attrs: {
                              name: "taxId",
                              optionData: _vm.Taxes,
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_Tax"
                              ),
                              error: _vm.veeErrors.has("taxId"),
                              id: "select-refund-tax",
                              optionIdName: "option-despatch-popup",
                            },
                            model: {
                              value: _vm.item.taxId,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "taxId", $$v)
                              },
                              expression: "item.taxId",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("taxId"),
                                expression: "veeErrors.has('taxId')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("taxId") },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_Quantity"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.allowNegativeStock
                          ? {
                              required: true,
                              decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                              min_value: 0,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                              multiplyTwoField: _vm.item.unitPrice,
                            }
                          : {
                              required: true,
                              decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                              greater_than: -1,
                              max_value: _vm.stockItemInfo.baseUnitQuantity,
                              multiplyTwoField: _vm.item.unitPrice,
                            },
                        expression:
                          "allowNegativeStock ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: item.unitPrice} : {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:-1, max_value:stockItemInfo.baseUnitQuantity, multiplyTwoField: item.unitPrice}",
                      },
                    ],
                    attrs: {
                      name: "quantity",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_Quantity"
                      ),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.item.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "quantity", $$v)
                      },
                      expression: "item.quantity",
                    },
                  }),
                  _vm.showStockDetail && _vm.stockItemInfo
                    ? _c("div", { staticClass: "Form-item-info" }, [
                        _c("div", [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockOnHand"
                                )
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.stockItemInfo.baseUnitQuantity)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_BaseUnit"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.unitId,
                          expression: "item.unitId",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      staticClass: "select",
                      class: { "is-danger": _vm.veeErrors.has("unitId") },
                      attrs: {
                        id: "select-despatch-popup-baseunit",
                        name: "unitId",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_BaseUnit"
                        ),
                        disabled: _vm.isEdit,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.item,
                            "unitId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      !(_vm.isFinalized && _vm.isEdit)
                        ? _c(
                            "option",
                            {
                              attrs: {
                                value: "-1",
                                id: "option-despatches-popup-baseunit-unselected",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_Popup_FormField_BaseUnit_Unselected"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.filteredUnitList, function (unit) {
                        return _c(
                          "option",
                          {
                            key: unit.id,
                            attrs: {
                              id: `option-despatches-popup-baseunit-${unit.name}`,
                            },
                            domProps: { value: unit.id },
                          },
                          [_vm._v(_vm._s(unit.name))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("unitId"),
                        expression: "veeErrors.has('unitId')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("unitId") },
                  }),
                ],
                1
              ),
              _vm.calculateBaseQuantity
                ? _c("div", { staticClass: "Form-item-info" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_Popup_FormField_BaseUnit_CalculatedText"
                          )
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_Popup_FormField_BaseUnit_CalculatedValue",
                            {
                              value: _vm.calculateBaseQuantity,
                              unit: _vm.baseQuantityUnit,
                            }
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("Despatches.detail_Popup_FormField_NetUnitPrice")
                  )
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("currency-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          greater_than: 0,
                          multiplyTwoField: _vm.item.quantity,
                        },
                        expression:
                          "{required: true, greater_than:0, multiplyTwoField: item.quantity}",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("netUnitPrice") },
                    attrs: {
                      name: "netUnitPrice",
                      disabled: _vm.isSupplierNotPermissionToGlobal,
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_NetUnitPrice"
                      ),
                    },
                    model: {
                      value: _vm.item.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "unitPrice", $$v)
                      },
                      expression: "item.unitPrice",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("netUnitPrice"),
                        expression: "veeErrors.has('netUnitPrice')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("netUnitPrice") },
                  }),
                ],
                1
              ),
              _vm.calculateBasePrice
                ? _c("div", { staticClass: "Form-item-info" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_Popup_FormField_NetUnitPrice_Calculated"
                          )
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatCurrency")(_vm.calculateBasePrice))
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_Discount1"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "greater_than:-1|max_value:100",
                        expression: "'greater_than:-1|max_value:100'",
                      },
                    ],
                    attrs: {
                      name: "discount1",
                      id: "despatches-detail-discount1",
                      isTypePercentage: true,
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_Discount1"
                      ),
                      placeholder: ",00",
                      error: _vm.veeErrors.has("discount1"),
                      disabled: _vm.isSupplierNotPermissionToGlobal,
                    },
                    model: {
                      value: _vm.item.discount1,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "discount1", $$v)
                      },
                      expression: "item.discount1",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("discount1"),
                        expression: "veeErrors.has('discount1')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("discount1") },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.discountControl,
                      expression: "!discountControl",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.$t("Despatches.discountControl_Message")))]
              ),
            ]),
            _c("div", { staticClass: "Form-item margin-discount" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_Discount2"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "greater_than:-1|max_value:100",
                        expression: "'greater_than:-1|max_value:100'",
                      },
                    ],
                    attrs: {
                      name: "discount2",
                      id: "despatches-detail-discount2",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_Discount2"
                      ),
                      isTypePercentage: true,
                      placeholder: ",00",
                      error: _vm.veeErrors.has("discount2"),
                      disabled:
                        _vm.isEmptyDiscount1 ||
                        +_vm.item.discount1 === 0 ||
                        _vm.isSupplierNotPermissionToGlobal,
                    },
                    model: {
                      value: _vm.item.discount2,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "discount2", $$v)
                      },
                      expression: "item.discount2",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("discount2"),
                        expression: "veeErrors.has('discount2')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("discount2") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        !_vm.isEmptyDiscount1
          ? _c("div", { staticClass: "Form-item" }, [
              _c("div", { staticClass: "Form-item-info-multiple" }, [
                _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.discountedNetUnitPrice)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.netUnitPriceLineTotal)
                      )
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      !_vm.isEmptyDiscount1
        ? _c("div", { staticClass: "Form-item" }, [
            _c("div", { staticClass: "Form-item-info-multiple" }, [
              _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText"
                      )
                    )
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatCurrency")(_vm.discountedNetUnitPrice))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText"
                      )
                    )
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatCurrency")(_vm.netUnitPriceLineTotal))
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-despatch-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Despatches.popup_RefundDetailName"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-detail-popup-submit",
                type: "submit",
                size: "medium",
                variant: "full",
                primary: "",
                form: "form-despatch-detail",
                disabled:
                  _vm.isLoading ||
                  (_vm.isSupplierNotPermissionToGlobal &&
                    _vm.item.unitPrice === 0) ||
                  _vm.inventoryValidation,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Despatches.detail_Popup_FinishEditButton")
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_Refund_FinishCreateButton"
                            )
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
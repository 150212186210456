var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { isOpen: true }, on: { closeModal: _vm.closeModal } },
    [
      _c("template", { slot: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.selectMatchedDespatch")))]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
          _vm.despatch_List.length > 0 && !_vm.isFetchingData
            ? _c("TableView", {
                attrs: {
                  componentName: "DespatchEInvoiceTable",
                  fields: _vm.fields,
                  data: _vm.despatch_List,
                  selectable: "checkbox",
                  selectableAll: "checkbox",
                  selectedRows: _vm.selectedMenuItems,
                  actions: [""],
                  readOnly: true,
                },
                on: {
                  showAllSelectedItems: _vm.onSelectMenuItemAll,
                  delegateOnItemSelect: _vm.onSelect,
                },
              })
            : _vm._e(),
          !_vm.despatch_List.length && !_vm.isFetchingData
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("EInvoice.selectDespatch_empty"),
                  description: _vm.$t("EInvoice.selectDespatch_empty"),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.despatch_List.length
            ? _c(
                "Button",
                {
                  staticClass: "m-top-5",
                  attrs: {
                    id: "nested-detail-finalize-order",
                    inline: "",
                    size: "small",
                    primary: "",
                    iconName: "icon-btn-success",
                    iconClass: "icon-btn-success",
                    isLoading: _vm.isWaitingUpdate,
                    disabled: !_vm.isSaveDespatch,
                  },
                  on: { click: _vm.saveSelectedDespatch },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.saveSelection")))])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BaseClass from '@/utils/baseclass'
import moment from 'moment'

export default class RawEInvoices extends BaseClass {
  addItem(item) {
    let newItem = new EInvoices(item)
    this.list.push(newItem)
  }
  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.currencyCode = item.currencyCode
      findItem.customerAlias = item.customerAlias
      findItem.customerBranch = item.customerBranch
      findItem.customerTaxNo = item.customerTaxNo
      findItem.despatchNo = item.despatchNo
      findItem.despatchHeaderIds = item.despatchHeaderIds
      findItem.discountAmount = item.discountAmount
      findItem.docType = item.docType
      findItem.errorCode = item.errorCode
      findItem.showDetail = false
      findItem.detailList = []
      findItem.ettn = item.ettn
      findItem.grossTotalAmount = item.grossTotalAmount
      findItem.invoiceNo = item.invoiceNo
      findItem.matchingStoreId = item.matchingStoreId
      findItem.matchingStoreName = item.matchingStoreName
      findItem.matchingSupplierId = item.matchingSupplierId
      findItem.matchingSupplierName = item.matchingSupplierName
      findItem.orderNo = item.orderNo
      findItem.supplierName = item.supplierName
      findItem.supplierTaxNo = item.supplierTaxNo
      findItem.taxAmount = item.taxAmount
      findItem.totalAmount = item.totalAmount
      findItem.modifiedOn = item.modifiedOn
      findItem.invoiceDate = item.invoiceDate
      findItem.rawEInvoiceStatus = item.rawEInvoiceStatus
      findItem.isActive = item.isActive
      findItem.rowTextClass = item.rowTextClass
      findItem.supplierBranchName = item.supplierBranchName
      findItem.supplierBranchCode = item.supplierBranchCode
      findItem.storeGroupId = item.storeGroupId
      findItem.disableSelection = item.isSelectable
    }
  }
}
export class EInvoices {
  constructor(item) {
    this.id = item.id
    this.currencyCode = item.currencyCode
    this.customerAlias = item.customerAlias
    this.customerBranch = item.customerBranch
    this.customerTaxNo = item.customerTaxNo
    this.despatchNo = item.despatchNo
    this.despatchHeaderIds = item.despatchHeaderIds
    this.discountAmount = item.discountAmount
    this.docType = item.docType
    this.detailList = []
    this.showDetail = false
    this.errorCode = item.errorCode
    this.ettn = item.ettn
    this.grossTotalAmount = item.grossTotalAmount
    this.invoiceNo = item.invoiceNo
    this.matchingStoreId = item.matchingStoreId
    this.matchingStoreName = item.matchingStoreName
    this.matchingSupplierId = item.matchingSupplierId
    this.matchingSupplierName = item.matchingSupplierName
    this.orderNo = item.orderNo
    this.supplierName = item.supplierName
    this.supplierTaxNo = item.supplierTaxNo
    this.taxAmount = item.taxAmount
    this.totalAmount = item.totalAmount
    this.rawEInvoiceStatus = item.rawEInvoiceStatus
    this.modifiedOn = item.modifiedOn
    this.isActive = item.isActive
    this.supplierBranchName = item.supplierBranchName
    this.supplierBranchCode = item.supplierBranchCode
    this.rowTextClass = item.rowTextClass
    this.storeGroupId = item.storeGroupId
    this.invoiceDate = moment(item.invoiceDate).format('DD-MM-YYYY')
    this.modifiedOn = moment(item.modifiedOn).format('DD-MM-YYYY')
    this.disableSelection = item.rawEInvoiceStatus === 3 || item.rawEInvoiceStatus === 4
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new EInvoiceDetail(
        {
          detail,
          matchingSupplierId: this.matchingSupplierId,
          matchingSupplierName: this.matchingSupplierName,
          matchingStoreName: this.matchingStoreName,
          matchingStoreId: this.matchingStoreId,
          errorCode: this.errorCode,
          isActive: this.isActive,
          rawEInvoiceStatus: this.rawEInvoiceStatus,
          supplierName: this.supplierName,
          docType: this.docType,
          invoiceNo: this.invoiceNo
        }))
    })
  }
  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }
}

export class EInvoiceDetail {
  constructor({detail, matchingSupplierId, matchingSupplierName, matchingStoreName, matchingStoreId, errorCode, isActive, rawEInvoiceStatus, supplierName, docType, invoiceNo}) {
    this.amount = detail.amount
    this.currencyCode = detail.currencyCode
    this.description = detail.description
    this.discountAmount = detail.discountAmount
    this.id = detail.id
    this.itemCode = detail.itemCode
    this.matchingStockItemId = detail.matchingStockItemId
    this.matchingStockItemName = detail.matchingStockItemName
    this.matchingTaxId = detail.matchingTaxId
    this.matchingTaxName = detail.matchingTaxName
    this.matchingUnitId = detail.matchingUnitId
    this.matchingUnitName = detail.matchingUnitName
    this.name = detail.name
    this.matchingSupplierId = matchingSupplierId
    this.matchingSupplierName = matchingSupplierName
    this.matchingStoreName = matchingStoreName
    this.matchingStoreId = matchingStoreId
    this.errorCode = errorCode
    this.quantity = detail.quantity
    this.rawEInvoiceId = detail.rawEInvoiceId
    this.taxAmount = detail.taxAmount
    this.taxRate = detail.taxRate
    this.unitCode = detail.unitCode
    this.unitPrice = detail.unitPrice
    this.isActive = isActive
    this.rawEInvoiceStatus = rawEInvoiceStatus
    this.supplierName = supplierName
    this.docType = docType
    this.invoiceNo = invoiceNo
  }
}

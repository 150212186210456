var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$route.params.despatchHeader.supplier.name)),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch-detail" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchDetailForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: {
                    id: `despatches-detail-popup-form-label-store-name`,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Despatches.detail_Popup_FormField_StoreName")
                    )
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      "component-name": "despatch-detail-multiple-store-select",
                      name: "storeName",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName"
                      ),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeSelect"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.selectMiniStoreList,
                      label: "name",
                      disabled:
                        _vm.tenantMultiCurrencyIsActive ||
                        _vm.tenantMustHaveSingleStoreInSameDespatchActive,
                      searchAll: true,
                      withDebounce: false,
                      placeholder: _vm.$t(
                        "Despatches.popup_FormField_StoreName_Placeholder"
                      ),
                      loading: _vm.isSearchStoreLists,
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.form.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "storeId", $$v)
                      },
                      expression: "form.storeId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeSelect"),
                        expression: "veeErrors.has('storeSelect')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeSelect") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: `despatches-detail-popup-form-label-taxes` },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.detail_Popup_FormField_Tax"))
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    attrs: {
                      name: "tax",
                      optionData: _vm.Taxes,
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_Tax"
                      ),
                      id: "option-despatches-popup-tax",
                      optionIdName: "option-despatches-popup-store-name",
                    },
                    model: {
                      value: _vm.form.taxId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taxId", $$v)
                      },
                      expression: "form.taxId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("tax"),
                        expression: "veeErrors.has('tax')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("tax") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item discount-field" }, [
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-discount-1`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_Discount1")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control tooltip-relative" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "greater_than:-1|max_value:100",
                          expression: "'greater_than:-1|max_value:100'",
                        },
                      ],
                      attrs: {
                        id: "despatches-detail-discount1",
                        name: "discount1",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_Discount1"
                        ),
                        placeholder: _vm.$t(
                          "Despatches.popupForm_Field_Discount1_Placeholder"
                        ),
                        error: _vm.veeErrors.has("discount1"),
                        disabled: _vm.priceTypeControl,
                      },
                      model: {
                        value: _vm.form.discount1,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "discount1", $$v)
                        },
                        expression: "form.discount1",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("discount1"),
                          expression: "veeErrors.has('discount1')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("discount1") },
                    }),
                    _vm.priceTypeControl
                      ? _c("tooltip", {
                          staticClass: "small",
                          attrs: {
                            white: "",
                            bottom: "",
                            text: _vm.$t("Despatches.priceTypeWarningMultiple"),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-discount-2`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_Discount2")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control tooltip-relative" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "greater_than:-1|max_value:100",
                          expression: "'greater_than:-1|max_value:100'",
                        },
                      ],
                      attrs: {
                        name: "discount2",
                        id: "despatches-detail-discount2",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_Discount2"
                        ),
                        placeholder: _vm.$t(
                          "Despatches.popupForm_Field_Discount2_Placeholder"
                        ),
                        error: _vm.veeErrors.has("discount2"),
                        disabled:
                          !_vm.form.discount1 ||
                          +_vm.form.discount1 === 0 ||
                          _vm.priceTypeControl,
                      },
                      model: {
                        value: _vm.form.discount2,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "discount2", $$v)
                        },
                        expression: "form.discount2",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("discount2"),
                          expression: "veeErrors.has('discount2')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("discount2") },
                    }),
                    _vm.priceTypeControl
                      ? _c("tooltip", {
                          staticClass: "small",
                          attrs: {
                            white: "",
                            bottom: "",
                            text: _vm.$t("Despatches.priceTypeWarningMultiple"),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-detail-popup-submit",
                type: "submit",
                primary: "",
                variant: "full",
                size: "medium",
                form: "form-despatch-detail",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Despatches.detail_Popup_FinishEditButton"))
                    ),
                  ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
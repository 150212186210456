var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [[_vm._v(_vm._s(_vm.$t("Despatches.convertToTransfer")))]],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-transfer" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Transfer.buyerStore"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.toStoreId,
                          expression: "form.toStoreId",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      staticClass: "select",
                      attrs: {
                        name: "buyerStoreName",
                        "data-vv-as": _vm.$t("Transfer.buyerStore"),
                        error: _vm.veeErrors.has("buyerStoreName"),
                        id: "select-transfer-transfer-buyerStore",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "toStoreId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "-1",
                            disabled: true,
                            id: "option-convert-transfer-popup-store-name-unselected",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Despatches.detail_Popup_FormField_StoreName_Unselected"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._l(_vm.sortedStoreList, function (store) {
                        return _c(
                          "option",
                          {
                            key: store.id,
                            attrs: {
                              id: `option-convert-transfer-popup-store-name-${store.name}`,
                            },
                            domProps: { value: store.id },
                          },
                          [_vm._v(_vm._s(store.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Transfer.Transfer_Date"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has("transferDate") },
                    attrs: {
                      id: "convert-transfer-header-datepicker",
                      name: "transferDate",
                      "data-vv-as": _vm.$t("Transfer.Transfer_Date"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                      disabledStartDate: _vm.disableDate,
                    },
                    model: {
                      value: _vm.form.transferDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "transferDate", $$v)
                      },
                      expression: "form.transferDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inventoryValidation,
                      expression: "inventoryValidation",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.closedInventoryError")))]
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                id: "btn-convert-transfer-popup-submit",
                type: "submit",
                variant: "full",
                size: "medium",
                primary: "",
                form: "form-transfer",
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Despatches.popupForm_Button_ConvertToTransfer")
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
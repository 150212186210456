import Index from '../'
import Form from '../popup/form'
import FormDetail from '../popup/form-detail'
import DespatchRemove from '../popup/remove-despatch'
import DespatchDetailRemove from '../popup/remove-detail'
import multipleDespatchEdit from '../popup/form-detail-multiple-despatch'
import DespatchConvertToTransferForm from '../popup/convert-to-transfer'
// Refund
import Refund from '../refund'
import Invoice from '../invoice'
import Ordered from '../ordered'
import EInvoice from '../eInvoice'
import EInvoiceSelectOrder from '../modals/eInvoice-order-select'
import EInvoiceSelectDespatch from '../modals/eInvoice-despatch-select'
import EInvoiceDetail from '../modals/eInvoice-detail'
import RefundNew from '../popup/add-refund'
import RefundFormDetail from '../popup/refund-form-detail'
import RefundDetailRemove from '../popup/remove-refund-detail'
import RefundRemove from '../popup/remove-refund'
import DespatchFinalizeForm from '../popup/finalize-despatch'
import RefundFinalizeForm from '../popup/finalize-refund'
import DespatchDocumentDetail from '../despatch-detail-document'
import RefundDocumentDetail from '../refund-detail-document'

export default [
  {
    path: '/despatch',
    name: 'Despatch',
    component: Index,
    children: [
      {
        path: 'new',
        name: 'DespatchHeaderNew',
        component: Form
      },
      {
        path: ':id/edit',
        name: 'DespatchHeaderEdit',
        component: Form,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/:date/convert-to-transfer',
        name: 'DespatchConvertToTransferForm',
        component: DespatchConvertToTransferForm
      },
      {
        path: 'multiple-edit',
        name: 'multipleDespatchEdit',
        component: multipleDespatchEdit
      },
      {
        path: ':id/remove',
        name: 'DespatchRemove',
        component: DespatchRemove
      },
      {
        path: ':id/detail-new',
        name: 'DespatchDetailNew',
        component: FormDetail
      },
      {
        path: ':id/finalize-despatch',
        name: 'DespatchFinalize',
        component: DespatchFinalizeForm,
        props: {
          status: 'single'
        }
      },
      {
        path: '/multiple-finalize-despatch',
        name: 'MultipleDespatchFinalize',
        component: DespatchFinalizeForm,
        props: {
          status: 'multiple'
        }
      },
      {
        path: ':detailId/detail-edit',
        name: 'DespatchDetailEdit',
        component: FormDetail,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':detailId/detail-remove',
        name: 'DespatchDetailRemove',
        component: DespatchDetailRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'despatch',
      category: 'Buy',
      permissionKey: 'DespatchsMenu',
      page: 'despatch'
    }
  },
  // refund
  {
    path: '/despatch/refund',
    name: 'Refund',
    component: Refund,
    children: [
      {
        path: 'new',
        name: 'RefundNew',
        component: RefundNew
      },
      {
        path: ':id/edit',
        name: 'RefundHeaderEdit',
        component: RefundNew,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/detail-new',
        name: 'RefundDetailNew',
        component: RefundFormDetail
      },
      {
        path: ':detailId/detail-edit',
        name: 'RefundDetailEdit',
        component: RefundFormDetail,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'RefundRemove',
        component: RefundRemove
      },
      {
        path: ':detailId/detail-remove',
        name: 'RefundDetailRemove',
        component: RefundDetailRemove
      },
      {
        path: ':id/finalize-refund',
        name: 'RefundFinalizeForm',
        component: RefundFinalizeForm,
        props: {
          status: 'single'
        }
      },
      {
        path: '/multiple-refund-despatch',
        name: 'MultipleRefundFinalize',
        component: RefundFinalizeForm,
        props: {
          status: 'multiple'
        }
      }
    ],
    meta: {
      permissionKey: 'DespatchsMenu',
      page: 'despatch'
    }
  },
  {
    path: '/despatch/invoice',
    name: 'Invoice',
    component: Invoice,
    meta: {
      permissionKey: 'DespatchsMenu',
      page: 'despatch'
    }
  },
  {
    path: '/despatch/detail/:id/:documentNumber',
    name: 'DespatchDocumentDetail',
    component: DespatchDocumentDetail,
    meta: {
      tabPermissionKey: 'DespatchsMenu',
      page: 'despatch'
    },
    children: [
      {
        path: ':id/detail-edit',
        name: 'DespatchDocumentDetailEdit',
        component: FormDetail,
        props: {
          status: 'edit',
          documentEdit: true
        }
      }
    ]
  },
  {
    path: '/refund/detail/:id/:documentNumber',
    name: 'RefundDocumentDetail',
    component: RefundDocumentDetail,
    meta: {
      tabPermissionKey: 'DespatchsMenu',
      page: 'despatch'
    },
    children: [
      {
        path: ':id/detail-edit',
        name: 'RefundDocumentDetailEdit',
        component: RefundFormDetail,
        props: {
          status: 'edit',
          documentEdit: true
        }
      }
    ]
  },
  {
    path: '/despatch/ordered',
    name: 'OrderedDespatch',
    component: Ordered,
    meta: {
      tabPermissionKey: 'OrderDespatchMenu',
      page: 'despatch'
    }
  },
  {
    path: '/despatch/eInvoice',
    name: 'EInvoice',
    component: EInvoice,
    meta: {
      page: 'despatch',
      permissionKey: 'EInvoiceMenu'
    },
    children: [
      {
        path: ':id/eInvoice-detail',
        name: 'EInvoiceDetail',
        component: EInvoiceDetail
      },
      {
        path: ':id/item/select-order/storeId:storeId/supplierId:supplierId',
        name: 'EInvoiceSelectOrder',
        component: EInvoiceSelectOrder
      },
      {
        path: ':id/item/select-despatch/supplierId:supplierId/startDate:startDate/endDate:endDate',
        name: 'EInvoiceSelectDespatch',
        component: EInvoiceSelectDespatch,
      }
    ]
  }
]

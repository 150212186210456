<template lang="pug">
Modal(:isOpen="true" @closeModal="closeModal")
    template(slot="title")
      Button.error-code.relative.width-max-content.m-top-10(
        :clearVariant="true"
        v-show="headerData.errorCode && headerData.rawEInvoiceStatus === 2"
      )
        span {{ $t('EInvoice.hasError') }}

      Button.waiting-label.relative.width-max-content.m-top-10(
        v-show="headerData.rawEInvoiceStatus === 1"
        :clearVariant="true"
      )
        span {{ $t('EInvoice.waiting') }}

      Button.success-label.relative.width-max-content.m-top-10(
        v-show="headerData.rawEInvoiceStatus === 3"
        :clearVariant="true"
      )
        span {{ $t('EInvoice.successful') }}

      span.m-top-10.title-supplier {{ headerData.supplierName }}

      span.m-top-10.title-docType {{ headerData.docType === 1 ? $t('Supplier.tableColumn_Invoice') : $t('Supplier.eArchive') }} - {{ headerData.invoiceNo }}

    template(slot="content")
      TableView(
        :actions="['']"
        :readOnly="true"
        :data="invoiceDetailList"
        :fields="detailFields"
        :addScrollToNoFixedColumns="true"
        :componentName="'EInvoicePage'"
      )

      portal(to="matchingStockItemName")
        div(slot-scope="row")
          customSelectInput.custom-select(
            name="matchingStockItems",
            v-if="row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1"
            :optionData="matchingStockItems"
            v-model="row.matchingStockItemId",
            optionKey="id"
            optionIdKey="id"
            :disabled="!row.isActive"
            id="eInvoice-matching-stockItem-list"
            :extraDataEmit="row"
            :selectedDataRequired="true"
            @change="updateMatchingStockItem"
            :isChangeValue="true"
            :hideDefault="false"
            optionIdName="eInvoice-matching-stockItem-list"
          )
          span(v-else) {{row.matchingStockItemName}}
      portal(to="matchingUnitName")
        div(slot-scope="row")
          customSelectInput.custom-select(
            name="matchingUnitId",
            v-if="row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1"
            :disabled="!row.isActive"
            :optionData="matchingUnits"
            v-model="row.matchingUnitId",
            optionKey="id"
            optionIdKey="id"
            id="eInvoice-matching-unit-list"
            :extraDataEmit="row"
            :selectedDataRequired="true"
            @change="updateMatchingUnit"
            :isChangeValue="true"
            :hideDefault="false"
            optionIdName="eInvoice-matching-unit-list"
          )

          span(v-else) {{row.matchingUnitName}}
</template>

<script>
import CustomSelect from '@/view/global/custom-select'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import {getEInvoiceDetailFields} from '../fields/eInvoice'

export default {
  name: 'EInvoiceDetail',
  data () {
    return {
      invoiceDetailList: [],
      detailFields: getEInvoiceDetailFields(this.$t),
      matchingStockItems: [],
      matchingUnits: [],
      headerData: []
    }
  },
  async created () {
    await this.getMatchingUnitData(this.$route.params.header.matchingSupplierId)
    await this.getMatchingStockItemData(this.$route.params.header.matchingSupplierId)
    await this.getDetailData()
    this.headerData = this.$route.params.header
  },

  components: {
    CustomSelect
  },

  computed: {

    ...mapGetters('EInvoice', [
      'EInvoicePage',
      'EInvoiceList'
    ])
  },

  methods: {
    ...mapActions('EInvoice', [
      'getEInvoiceDetail',
      'getStockItemsFromSupplier',
      'updateStockItemCode',
      'getUnitsFromSupplier',
      'updateUnitCode'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('EInvoice', [
      'HIDE_EINVOICE_DETAIL',
      'SHOW_EINVOICE_DETAIL',
      'UPDATE_EINVOICE_LIST'
    ]),

    closeModal () {
      this.$router.push({name: 'EInvoice'})
    },

    async getDetailData () {
      await this.getEInvoiceDetail(this.$route.params.id).then(res => {
        if (res) {
          this.SHOW_EINVOICE_DETAIL({ RawEInvoiceId: this.$route.params.id, data: res.data })
          this.invoiceDetailList = this.EInvoiceList.find(i => i.id === this.$route.params.id) && (this.EInvoiceList.find(i => i.id === this.$route.params.id).detailList || [])
          if (!this.invoiceDetailList.length) this.$router.push({name: 'EInvoice'})
        }
      })
    },
    async updateMatchingUnit (item) {
      const payload = {
        rawEInvoiceId: item.extraDataEmit.rawEInvoiceId,
        rawEInvoiceLineId: item.extraDataEmit.id,
        supplierId: item.extraDataEmit.matchingSupplierId,
        unitCode: item.selectedItem ? item.selectedItem.unitCode : null
      }
      const message = this.$t('EInvoice.matchingUnit_matchingStockItem_successMessage')
      await this.updateUnitCode(payload).then(res => {
        if (res) {
          this.notifyShow({ message })
        }
      })
    },
    async getMatchingUnitData (SupplierId) {
      const res = await this.getUnitsFromSupplier(SupplierId)
      if (res) {
        this.matchingUnits = res.data.unitlist
      }
    },
    async getMatchingStockItemData (SupplierId) {
      const res = await this.getStockItemsFromSupplier(SupplierId)
      if (res) {
        this.matchingStockItems = res.data.stockItemlist
      }
    },
    async updateMatchingStockItem (item) {
      const payload = {
        rawEInvoiceId: item.extraDataEmit.rawEInvoiceId,
        rawEInvoiceLineId: item.extraDataEmit.id,
        supplierId: item.extraDataEmit.matchingSupplierId,
        itemCode: item.selectedItem ? item.selectedItem.supplierStockItemCode : null
      }
      const message = this.$t('EInvoice.matchingStockItem_successMessage')
      await this.updateStockItemCode(payload).then(res => {
        if (res) {
          this.notifyShow({ message })
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>

.error-code{
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  background:  rgba(($color-rejection), 0.2);
  color: $color-rejection
}
.success-label {
  @extend.error-code;
  background: rgba(($color-success), 0.2);
  color: $color-success
}
.waiting-label {
  @extend.error-code;
  background: rgba(($color-primary), 0.2);
  color: $color-primary
}

:deep() .Modal-Body {
  overflow: auto !important;
}
:deep() .Modal-Body-content {
  width: 70vw !important;
  height: 60vh !important;
}
:deep() .Tableee-scroll .Tableee-main {
  overflow-y: visible !important
}
:deep() .Tableee-main.fixed {
  overflow-x: visible !important;
  overflow-y: visible !important

}
:deep() .Tableee-scroll {
  overflow: visible !important;
}
:deep() .Tableee-head .Tableee-col.fixed {
  z-index: 200;
  &:first-child {
    left: 0;
    z-index: 500;
  }
  &:nth-child(2) {
    left: 0;
    z-index: 100;
  }
}
.title-docType {
  font-size: 16px
}

:deep() .select {
  width: unset;
}

:deep() .Modal-Body-header .title {
  display: flex;
  flex-direction: column;
}

</style>

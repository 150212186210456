export function getEInvoiceFields($t) {
  return [
    {
      name: 'errorCode',
      is_visible: true,
      fixedColumnHeader: true,
      type: 'portal',
      get short_title() {
        return $t('EInvoice.status')
      },
      get title() {
        return $t('EInvoice.status')
      }
    },
    {
      name: 'docType',
      is_visible: true,
      fixedColumnHeader: true,
      type: 'portal',
      get short_title() {
        return $t('EInvoice.docType')
      },
      get title() {
        return $t('EInvoice.docType')
      }
    },
    {
      name: 'supplierName',
      fixedColumnHeader: true,
      is_visible: true,
      type: 'supplier',
      get short_title() {
        return $t('EInvoice.companyName')
      },
      get title() {
        return $t('EInvoice.companyName')
      }
    },
    {
      name: 'invoiceNo',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.invoiceNo')
      },
      get title() {
        return $t('EInvoice.invoiceNo')
      }
    },
    {
      name: 'orderNo',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('EInvoice.orderNo')
      },
      get title() {
        return $t('EInvoice.orderNo')
      }
    },
    {
      name: 'despatchNo',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('EInvoice.despatchNo')
      },
      get title() {
        return $t('EInvoice.despatchNo')
      }
    },
    {
      name: 'customerBranch',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.customerBranch')
      },
      get title() {
        return $t('EInvoice.customerBranch')
      }
    },
    {
      name: 'matchingStoreName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.matchingCustomerBranch')
      },
      get title() {
        return $t('EInvoice.matchingCustomerBranch')
      }
    },
    {
      name: 'supplierTaxNo',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.customerTaxNo')
      },
      get title() {
        return $t('EInvoice.customerTaxNo')
      }
    },
    {
      name: 'currencyCode',
      is_visible: false,
      get short_title() {
        return $t('EInvoice.currencyCode')
      },
      get title() {
        return $t('EInvoice.currencyCode')
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.totalAmount')
      },
      get title() {
        return $t('EInvoice.totalAmount')
      }
    },
    {
      name: 'taxAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.taxAmount')
      },
      get title() {
        return $t('EInvoice.taxAmount')
      }
    },
    {
      name: 'discountAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.discountAmount')
      },
      get title() {
        return $t('EInvoice.discountAmount')
      }
    },
    {
      name: 'grossTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.grossTotalAmount')
      },
      get title() {
        return $t('EInvoice.grossTotalAmount')
      }
    },
    {
      name: 'invoiceDate',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.invoiceDate')
      },
      get title() {
        return $t('EInvoice.invoiceDate')
      }
    },
    {
      name: 'modifiedOn',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.modifiedOn')
      },
      get title() {
        return $t('EInvoice.modifiedOn')
      }
    },
    {
      name: 'supplierBranchCode',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.supplierBranchCode')
      },
      get title() {
        return $t('EInvoice.supplierBranchCode')
      }
    },
    {
      name: 'supplierBranchName',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('EInvoice.supplierBranchName')
      },
      get title() {
        return $t('EInvoice.supplierBranchName')
      }
    }
  ]
}

export function getEInvoiceDetailFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t('EInvoice.name')
      },
      get title() {
        return $t('EInvoice.name')
      }
    },
    {
      name: 'matchingStockItemName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.matchingStockItemName')
      },
      get title() {
        return $t('EInvoice.matchingStockItemName')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.unitCode')
      },
      get title() {
        return $t('EInvoice.unitCode')
      }
    },
    {
      name: 'matchingUnitName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.matchingUnitName')
      },
      get title() {
        return $t('EInvoice.matchingUnitName')
      }
    },

    {
      name: 'matchingTaxName',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.matchingTaxName')
      },
      get title() {
        return $t('EInvoice.matchingTaxName')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.quantity')
      },
      get title() {
        return $t('EInvoice.quantity')
      }
    },
    {
      name: 'itemCode',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.itemCode')
      },
      get title() {
        return $t('EInvoice.itemCode')
      }
    },
    {
      name: 'unitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.unitPrice')
      },
      get title() {
        return $t('EInvoice.unitPrice')
      }
    },
    {
      name: 'currencyCode',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.currencyCode')
      },
      get title() {
        return $t('EInvoice.currencyCode')
      }
    },
    {
      name: 'taxAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.taxAmount')
      },
      get title() {
        return $t('EInvoice.taxAmount')
      }
    },
    {
      name: 'taxRate',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('EInvoice.taxRate')
      },
      get title() {
        return $t('EInvoice.taxRate')
      }
    }
  ]
}

<template lang="pug">
  Modal(:isOpen="true" @closeModal="closeModal")
    template(slot="title")
      span {{ $t('EInvoice.selectMatchedDespatch')}}
      
    template(slot="content")

      TableLoading(v-if="isFetchingData")

      TableView(
        v-if="despatch_List.length > 0 && !isFetchingData"
        :componentName="'DespatchEInvoiceTable'"
        :fields="fields"
        :data="despatch_List"
        :selectable="'checkbox'"
        :selectableAll="'checkbox'"
        :selectedRows="selectedMenuItems"
        :actions="['']"
        :readOnly="true"
        @showAllSelectedItems="onSelectMenuItemAll"
        @delegateOnItemSelect="onSelect"
      )

      EmptyState(
        v-if="!despatch_List.length && !isFetchingData"
        :title="$t('EInvoice.selectDespatch_empty')"
        :description="$t('EInvoice.selectDespatch_empty')")

    template(slot="footer")
      Button.m-top-5(
      v-if="despatch_List.length"
      id="nested-detail-finalize-order",
      inline,
      size="small"
      primary
      iconName="icon-btn-success",
      iconClass="icon-btn-success",
      :isLoading="isWaitingUpdate"
      :disabled="!isSaveDespatch"
      @click="saveSelectedDespatch")
        span {{ $t('EInvoice.saveSelection') }}

     
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getDespatchHeaderFields } from '../fields/eInvoice-despatch'

export default {
  name: 'EInvoiceSelectOrder',
  data () {
    return {
      fields: getDespatchHeaderFields(this.$t),
      dataList: [],
      eInvoice: [],
      selectedDespatch: null,
      saveDespatchStatus: false,
      selectedMenuItems: []
    }
  },
  async mounted() {
    this.eInvoice = this.$route.params
    await this.getDespatchDataAll(this.eInvoice)
  },

  computed: {

    ...mapGetters('Despatch', [
      'despatch_List',
    ]),

    isWaitingUpdate() {
      return this.$wait.is('updateOrderForEInvoice')
    },

    isFetchingData () {
      return this.$wait.is('get_DESPATCH_LIST')
    },

    isSaveDespatch () {
      return this.saveDespatchStatus === true
    }
  },

  watch: {
    selectedMenuItems() {
      const result = this.selectedMenuItems.reduce((acc, menuItem) => {
        if (menuItem.grossTotalAmount) {
          return acc + menuItem.grossTotalAmount;
        }
        return acc;
      }, 0);
      // Burada toFixed3 e göre kıyaslama yapmamız gerekiyor ama küsüratlarda uyuşmazlık olduğundan ötürü 2 ye göre kıyaslama yapıyoruz.
      if (Number(result.toFixed(2)) === this.eInvoice.grossTotalAmount) this.saveDespatchStatus = true
      else this.saveDespatchStatus = false
    }
  },

  methods: {
    ...mapActions('EInvoice', [
      'updateDespatchForEInvoice'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Despatch', [
      'get_DESPATCH_LIST'
    ]),


    getCheckedDespatch (data) {
      const despatchList = [...this.despatch_List]
      despatchList.forEach(despatch => {
        data.forEach(item => {
          if (despatch.supplierId === item) {
            this.selectedMenuItems.push(despatch)
          }
        })
      })
    },

    onSelect (eInvoice) {
      const isChecked = !this.selectedMenuItems.some(item => item.id === eInvoice.id)
      if (isChecked) this.selectedMenuItems.push(eInvoice)
      else this.selectedMenuItems.splice(this.selectedMenuItems.findIndex(item => item.id === eInvoice.id), 1)
    },

    onSelectMenuItemAll (isChecked) {
      this.selectedMenuItems = []
      if (isChecked) this.selectedMenuItems.push(...this.despatch_List)
    },

    saveSelectedDespatch () {
      const despatchNumbers = Object.values(this.selectedMenuItems).map(item => item.despatchNumber);
      this.updateDespatchForEInvoice({
        rawEInvoiceId: this.eInvoice.id,
        despatchNumbers: despatchNumbers.length > 0 ? despatchNumbers : null
      })
      this.closeModal()
    },

    closeModal () {
      this.$router.push({name: 'EInvoice'})
    },

    async getDespatchDataAll (eInvoice) {
      this.get_DESPATCH_LIST({
        SupplierList: [eInvoice.supplierId],
        startDate: eInvoice.startDate,
        endDate: eInvoice.endDate,
        recordFilterType: 1,
        pageNumber: 1,
        pageSize: 99999999,
        showDrafts: false
      })
      if (eInvoice.despatchHeaderIds !== null) this.getCheckedDespatch(this.eInvoice.despatchHeaderIds)
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

:deep(.Modal-Body)  {
  overflow: auto !important;
}
:deep(.Modal-Body-content)  {
  min-width: min-content !important;
  height: 60vh !important;
}
:deep(.Modal-Body-footer) {
  display: flex;
  justify-content: end;
}
.title-docType {
  font-size: 16px
}

:deep(.select) {
  width: unset;
}
:deep(.Modal-Body-header .title)  {
  display: flex;
  flex-direction: column;
}

:deep(.NestedTable-item) {
  width: auto !important;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.despatchHeaderTitle)),
      ]),
      _c("template", { slot: "detail" }, [
        !_vm.headerIsEmpty
          ? _c("div", { staticClass: "Popup-despatch-info" }, [
              _c(
                "div",
                [
                  _c("Icon", { attrs: { name: "icon-calender" } }),
                  _c("span", [_vm._v(_vm._s(_vm.despatchDate))]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Icon", { attrs: { name: "icon-popup-despatch-no" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.despatch.despatchHeader.despatchNumber)),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch-detail" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchDetailForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: {
                    id: `despatches-detail-popup-form-label-store-name`,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Despatches.detail_Popup_FormField_StoreName")
                    )
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1|verify_inventory",
                        expression: "'required|is_not:-1|verify_inventory'",
                      },
                    ],
                    ref: "secondaryInput",
                    attrs: {
                      "component-name": "despatch-popup-store-select",
                      name: "storeName",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName"
                      ),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeName"),
                      },
                      disabled:
                        _vm.tenantMultiCurrencyIsActive ||
                        _vm.tenantMustHaveSingleStoreInSameDespatchActive,
                      "on-search": _vm.getOptionsStore,
                      options: _vm.selectMiniStoreList,
                      label: "name",
                      searchAll: true,
                      withDebounce: false,
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName_Placeholder"
                      ),
                      loading: _vm.isSearchStoreLists,
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.item.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "storeId", $$v)
                      },
                      expression: "item.storeId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeName"),
                        expression: "veeErrors.has('storeName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeName") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: {
                    id: `despatches-detail-popup-form-label-stock-item-name`,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Despatches.detail_Popup_FormField_StockItemName")
                    )
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|verify_inventory",
                        expression: "'required|verify_inventory'",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      "component-name": "form-despatch-detail-stock-item",
                      name: "stockItem",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StockItemName"
                      ),
                      inputClass: { "is-danger": _vm.inventoryValidation },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      disabled: _vm.isEdit,
                      label: "name",
                      loading: _vm.isSearchingStockItem,
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                      ),
                      directSelectOption: _vm.directSelectOption,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.fetchStockItems(_vm.item)
                      },
                    },
                    model: {
                      value: _vm.item.stockItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "stockItem", $$v)
                      },
                      expression: "item.stockItem",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.inventoryValidation,
                        expression: "inventoryValidation",
                      },
                    ],
                    attrs: { errorName: _vm.validationErrorMessage },
                  }),
                ],
                1
              ),
              _vm.showStockDetail
                ? _c(
                    "div",
                    { staticClass: "Form-item-info" },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            attrs: {
                              id: `despatches-detail-popup-form-text-stock-item-name-info-base-unit`,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "value",
                            attrs: {
                              id: `despatches-detail-popup-form-value-stock-item-name-info-base-unit`,
                            },
                          },
                          [_vm._v(_vm._s(_vm.item.stockItem.baseUnitName))]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            attrs: {
                              id: `despatches-detail-popup-form-text-stock-item-name-info-tax`,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockItemName_InfoTax"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "value",
                            attrs: {
                              id: `despatches-detail-popup-form-value-stock-item-name-info-tax`,
                            },
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|is_not:-1",
                                    expression: "'required|is_not:-1'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.taxId,
                                    expression: "item.taxId",
                                  },
                                ],
                                staticClass: "tax",
                                class: {
                                  "is-danger": _vm.veeErrors.has("taxId"),
                                },
                                attrs: {
                                  name: "taxId",
                                  id: `select-despatch-popup`,
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_Tax"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.item,
                                      "taxId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "-1",
                                      id: "option-despatch-popup-unselected",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Despatches.detail_Popup_FormField_TaxId_Unselected"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.Taxes, function (Tax) {
                                  return _c(
                                    "option",
                                    {
                                      key: Tax.id,
                                      attrs: {
                                        id: `option-despatch-popup-${Tax.id}`,
                                      },
                                      domProps: { value: Tax.id },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "%" + Tax.ratio + " - " + Tax.name
                                        )
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("taxId"),
                            expression: "veeErrors.has('taxId')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("taxId") },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item required row-field" }, [
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-quantity`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_Quantity")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.item.quantity,
                          expression: "item.quantity",
                          modifiers: { number: true },
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            _vm.checkPermission("IgnoreQuantityDeviation") ===
                            false
                              ? {
                                  required: true,
                                  min_value: _vm.item.minQuantityDeviationResult
                                    ? _vm.item.minQuantityDeviationResult
                                    : 0,
                                  max_value: _vm.item.maxQuantityDeviationResult
                                    ? _vm.item.maxQuantityDeviationResult
                                    : 999999999999,
                                }
                              : {
                                  min_value: 0,
                                  max_value: 99999999999,
                                  multiplyTwoField: _vm.item.unitPrice,
                                  required: true,
                                },
                          expression:
                            "checkPermission('IgnoreQuantityDeviation') === false ? {required: true, min_value: item.minQuantityDeviationResult ? item.minQuantityDeviationResult : 0, max_value: item.maxQuantityDeviationResult ? item.maxQuantityDeviationResult : 999999999999} : {min_value: 0, max_value: 99999999999, multiplyTwoField: item.unitPrice, required: true}",
                        },
                      ],
                      staticClass: "txt no-spinners",
                      class: { "is-danger": _vm.veeErrors.has("quantity") },
                      attrs: {
                        name: "quantity",
                        autocomplete: "off",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_Quantity"
                        ),
                        id: "despatches-detail-popup-form-input-quantity",
                        type: "number",
                        step: "any",
                      },
                      domProps: { value: _vm.item.quantity },
                      on: {
                        change: function ($event) {
                          return _vm.reCalculate("quantity")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.item,
                            "quantity",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("quantity"),
                          expression: "veeErrors.has('quantity')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("quantity") },
                    }),
                    _vm.item || _vm.stockItemInfo
                      ? _c("div", { staticClass: "Form-item-info" }, [
                          _c("div", [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_Popup_FormField_StockOnHand"
                                  )
                                )
                              ),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.item.stockOnHandQuantity ||
                                    _vm.stockItemInfo.baseUnitQuantity
                                )
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-base-unit`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_BaseUnit")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.unitId,
                            expression: "item.unitId",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|is_not:-1",
                            expression: "'required|is_not:-1'",
                          },
                        ],
                        staticClass: "select",
                        class: { "is-danger": _vm.veeErrors.has("unitId") },
                        attrs: {
                          id: "select-despatch-popup-baseunit",
                          name: "unitId",
                          "data-vv-as": _vm.$t(
                            "Despatches.detail_Popup_FormField_BaseUnit"
                          ),
                          disabled: _vm.isEdit,
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.item,
                              "unitId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        !(_vm.isFinalized && _vm.isEdit)
                          ? _c(
                              "option",
                              {
                                attrs: {
                                  value: "-1",
                                  id: "option-despatches-popup-baseunit-unselected",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Despatches.detail_Popup_FormField_BaseUnit_Unselected"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.filteredUnitList, function (unit) {
                          return _c(
                            "option",
                            {
                              key: unit.id,
                              attrs: {
                                id: `option-despatches-popup-baseunit-${unit.name}`,
                              },
                              domProps: { value: unit.id },
                            },
                            [_vm._v(_vm._s(unit.name))]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("unitId"),
                          expression: "veeErrors.has('unitId')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("unitId") },
                    }),
                  ],
                  1
                ),
                _vm.calculateBaseQuantity
                  ? _c("div", { staticClass: "Form-item-info" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FormField_BaseUnit_CalculatedText"
                            )
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FormField_BaseUnit_CalculatedValue",
                              {
                                value: _vm.calculateBaseQuantity,
                                unit: _vm.baseQuantityUnit,
                              }
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "Form-item row-field",
                class: _vm.isPriceAvailable ? "required" : "",
              },
              [
                _c("div", { staticClass: "item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "Form-item-label",
                      attrs: {
                        id: `despatches-detail-popup-form-label-net-unit-price`,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_Popup_FormField_NetUnitPrice"
                          )
                        )
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "control tooltip-relative" },
                    [
                      _c("currency-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value:
                              _vm.checkPermission("IgnorePriceDeviation") ===
                              false
                                ? {
                                    required: _vm.isPriceAvailable,
                                    min_value: _vm.item.minPriceDeviationResult
                                      ? _vm.item.minPriceDeviationResult
                                      : 0,
                                    max_value: _vm.item.maxPriceDeviationResult
                                      ? _vm.item.maxPriceDeviationResult
                                      : 999999999999,
                                    multiplyTwoField: [
                                      _vm.item.quantity,
                                      _vm.item.netAmount,
                                    ],
                                    required: _vm.isPriceAvailable,
                                  }
                                : {
                                    greater_than: 0,
                                    required: true,
                                    multiplyTwoField: [
                                      _vm.item.quantity,
                                      _vm.item.netAmount,
                                    ],
                                  },
                            expression:
                              "checkPermission('IgnorePriceDeviation') === false ? {required: isPriceAvailable, min_value: item.minPriceDeviationResult ? item.minPriceDeviationResult : 0, max_value: item.maxPriceDeviationResult ? item.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [item.quantity, item.netAmount], required: isPriceAvailable} : {greater_than:0, required: true, multiplyTwoField: [item.quantity, item.netAmount]}",
                          },
                        ],
                        staticClass: "txt",
                        class: { "is-danger": _vm.veeErrors.has("unitPrice") },
                        attrs: {
                          name: "unitPrice",
                          disabled:
                            _vm.isSupplierNotPermissionToGlobal ||
                            !_vm.isPriceAvailable ||
                            _vm.priceType === 2,
                          "data-vv-as": _vm.$t(
                            "Despatches.detail_Popup_FormField_NetUnitPrice"
                          ),
                          id: "despatches-detail-unit-price",
                        },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.reCalculate("unitPrice")
                          },
                        },
                        model: {
                          value: _vm.item.unitPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "unitPrice", $$v)
                          },
                          expression: "item.unitPrice",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "currency-symbol",
                          class:
                            _vm.isSupplierNotPermissionToGlobal ||
                            !_vm.isPriceAvailable ||
                            _vm.priceType === 2
                              ? "currency-symbol-faded"
                              : "",
                        },
                        [_vm._v(_vm._s(_vm.currencySymbol))]
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("unitPrice"),
                            expression: "veeErrors.has('unitPrice')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("unitPrice") },
                      }),
                      _vm.priceType === 2
                        ? _c("tooltip", {
                            staticClass: "small",
                            attrs: {
                              white: "",
                              top: "",
                              text: _vm.$t("Despatches.priceTypeWarning"),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "Form-item-label",
                      attrs: {
                        id: `despatches-detail-popup-form-label-net-amount`,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_NetAmount")
                        )
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "control tooltip-relative" },
                    [
                      _c("currency-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-0.001",
                            expression: "'greater_than:-0.001'",
                          },
                        ],
                        staticClass: "txt currency-input",
                        class: { "is-danger": _vm.veeErrors.has("netAmount") },
                        attrs: {
                          name: "netAmount",
                          disabled:
                            _vm.isSupplierNotPermissionToGlobal ||
                            !+_vm.item.quantity ||
                            !_vm.isPriceAvailable ||
                            _vm.priceType === 2,
                          "data-vv-as": _vm.$t(
                            "Despatches.detail_TableColumn_NetAmount"
                          ),
                          id: "despatches-detail-unit-price",
                        },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.reCalculate("netAmount")
                          },
                        },
                        model: {
                          value: _vm.item.netAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "netAmount", $$v)
                          },
                          expression: "item.netAmount",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "currency-symbol",
                          class:
                            _vm.isSupplierNotPermissionToGlobal ||
                            !+_vm.item.quantity ||
                            !_vm.isPriceAvailable ||
                            _vm.priceType === 2
                              ? "currency-symbol-faded"
                              : "",
                        },
                        [_vm._v(_vm._s(_vm.currencySymbol))]
                      ),
                      _vm.priceType === 2
                        ? _c("tooltip", {
                            staticClass: "small",
                            attrs: {
                              white: "",
                              top: "",
                              text: _vm.$t("Despatches.priceTypeWarning"),
                            },
                          })
                        : _vm._e(),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("netAmount"),
                            expression: "veeErrors.has('netAmount')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("netAmount") },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm.calculateBasePrice
              ? _c("div", { staticClass: "Form-item-info" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.detail_Popup_FormField_NetUnitPrice_Calculated"
                        )
                      )
                    ),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.currencySymbol) +
                        " " +
                        _vm._s(_vm._f("formatCurrency")(_vm.calculateBasePrice))
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item discount-field" }, [
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-discount-1`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_Discount1")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control tooltip-relative" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "greater_than:-1|max_value:100",
                          expression: "'greater_than:-1|max_value:100'",
                        },
                      ],
                      attrs: {
                        id: "despatches-detail-discount1",
                        name: "discount1",
                        isTypePercentage: true,
                        error: _vm.veeErrors.has("discount1"),
                        placeholder: ",00",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_Discount1"
                        ),
                        disabled:
                          _vm.isSupplierNotPermissionToGlobal ||
                          !_vm.isPriceAvailable ||
                          _vm.priceType === 2,
                      },
                      model: {
                        value: _vm.item.discount1,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "discount1", $$v)
                        },
                        expression: "item.discount1",
                      },
                    }),
                    _vm.priceType === 2
                      ? _c("tooltip", {
                          staticClass: "small",
                          attrs: {
                            white: "",
                            top: "",
                            text: _vm.$t("Despatches.priceTypeWarning"),
                          },
                        })
                      : _vm._e(),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("discount1"),
                          expression: "veeErrors.has('discount1')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("discount1") },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.discountControl,
                        expression: "!discountControl",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.$t("Despatches.discountControl_Message")))]
                ),
              ]),
              _c("div", { staticClass: "item" }, [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: {
                      id: `despatches-detail-popup-form-label-discount-2`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Despatches.detail_Popup_FormField_Discount2")
                      )
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control tooltip-relative" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "greater_than:-1|max_value:100",
                          expression: "'greater_than:-1|max_value:100'",
                        },
                      ],
                      attrs: {
                        id: "despatches-detail-discount2",
                        name: "discount2",
                        error: _vm.veeErrors.has("discount2"),
                        isTypePercentage: true,
                        placeholder: ",00",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_Discount2"
                        ),
                        disabled:
                          _vm.isEmptyDiscount1 ||
                          +_vm.item.discount1 === 0 ||
                          _vm.isSupplierNotPermissionToGlobal ||
                          !_vm.isPriceAvailable ||
                          _vm.priceType === 2,
                      },
                      model: {
                        value: _vm.item.discount2,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "discount2", $$v)
                        },
                        expression: "item.discount2",
                      },
                    }),
                    _vm.priceType === 2
                      ? _c("tooltip", {
                          staticClass: "small",
                          attrs: {
                            white: "",
                            top: "",
                            text: _vm.$t("Despatches.priceTypeWarning"),
                          },
                        })
                      : _vm._e(),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("discount2"),
                          expression: "veeErrors.has('discount2')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("discount2") },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        !_vm.isEmptyDiscount1
          ? _c("div", { staticClass: "Form-item" }, [
              _c("div", { staticClass: "Form-item-info-multiple" }, [
                _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency")(_vm.discountedunitPrice))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency")(_vm.unitPriceLineTotal))
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-despatch-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Despatches.detail_Popup_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-detail-popup-submit",
                type: "submit",
                primary: "",
                variant: "full",
                size: "medium",
                form: "form-despatch-detail",
                disabled:
                  _vm.isLoading ||
                  (_vm.isSupplierNotPermissionToGlobal &&
                    _vm.item.unitPrice === 0) ||
                  _vm.inventoryValidation,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Despatches.detail_Popup_FinishEditButton")
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Despatches.detail_Popup_FinishCreateButton")
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
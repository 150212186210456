<template lang="pug">
DeleteAlert(
  :item="itemWithFormattedDate",
  :fields="fields",
  tableName="Alım girişini",
  loading="Despatch/delete_DESPATCH_HEADER",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DespatchRemove',

  data () {
    return {
      fields: [{
        name: 'supplier.name',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_SupplierName_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_SupplierName')
      }, {
        name: 'despatchDateFormatted',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_DespatchDate_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_DespatchDate')
      }, {
        name: 'despatchNumber',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_DespatchNumber_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_DespatchNumber')
      }],
      item: {}
    }
  },
  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.get_DESPATCH(this.$route.params.id)
        .then(res => {
          this.item = res.data.despatchHeader
        })
    }
  },

  computed: {
    itemWithFormattedDate () {
      const despatchDateFormatted = new Date(this.item.despatchDate).toLocaleDateString('tr')
      return Object.assign({}, this.item, {
        despatchDateFormatted
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH_LIST',
      'delete_DESPATCH_HEADER',
      'get_DESPATCH'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      let result = await this.delete_DESPATCH_HEADER(this.item.id)
      if (result.status === 200) {
        const message = this.$t('Despatches.removeDespatch_Notificiation_Deleted', {
          date: this.item.invoiceDate
        })
        this.notifyShow({ message })
        this.get_DESPATCH_LIST({})
        this.close()
      }
    }, 'deleteForm')
  }
}
</script>

<style lang="scss">
</style>

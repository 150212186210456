import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import RawEInvoices from '../model/raw-eInvoice'
import moment from 'moment'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    eInvoices: new RawEInvoices(),
    totalCount: null,
    startDate: moment().startOf('month').hour(0).minute(0)._d,
    endDate: moment().hour(23).minute(59)._d,
    eInvoiceDetail: [],
    matchingStores: [],
    matchingBranches: [],
    pageHeaderLinks: [
      'Despatch',
      'Invoice',
      'Refund',
      'OrderedDespatch',
      'EInvoice'
    ],
    eInvoicePage: {
      number: 1,
      size: 20,
      total: 0
    },
    InvoiceHTML: null,
    invoiceTransformStatus: false
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    EInvoiceList: state => state.eInvoices.list,
    EInvoiceDetail: state => state.eInvoiceDetail,
    EInvoicePage: state => ({
      ...state.eInvoicePage,
      last: Math.ceil(state.eInvoicePage.total / state.eInvoicePage.size)
    }),
    InvoiceHTML: state => state.InvoiceHTML,
    matchingStores: state => state.matchingStores,
    matchingBranches: state => state.matchingBranches,
    InvoiceTransformStatus: state => state.invoiceTransformStatus
  },

  actions: {

    async getEInvoiceList({ state, commit }, payload) {
      const pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.eInvoicePage.number })
      const params = {
        ...payload,
        pageNumber
      }
      const results = await requestHandler({
        title: 'getEInvoiceList',
        methodName: 'getEInvoiceList',
        method: () => {
          return HTTP.post('RawEInvoice/all', params)
        },
        success: result => {
          commit('UPDATE_EINVOICE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getEInvoiceDetail({ commit, dispatch }, RawEInvoiceId) {
      const results = await requestHandler({
        title: 'getEInvoiceDetail',
        methodName: 'getEInvoiceDetail',
        method: () => {
          return HTTP.get('rawEInvoice/line/all', {
            params: { RawEInvoiceId }
          })
        },
        success: result => result
      })
      return results
    },

    async getStoreFromSupplier({ commit, dispatch }, SupplierId) {
      const results = await requestHandler({
        title: 'getStoreFromSupplier',
        methodName: 'getStoreFromSupplier',
        method: () => {
          return HTTP.get('Supplier/getstores', {
            params: { SupplierId }
          })
        },
        success: result => {
          commit('UPDATE_STORE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getBranchCodesFromTaxNumber({ commit, dispatch }, TaxNumber) {
      const results = await requestHandler({
        title: 'getBranchCodesFromTaxNumber',
        methodName: 'getBranchCodesFromTaxNumber',
        method: () => {
          return HTTP.get('suppliergroup/getsupplierbranchcodes', {
            params: { TaxNumber }
          })
        },
        success: result => {
          commit('UPDATE_BRANCH_CODES', result.data)
          return result
        }
      })
      return results
    },

    async getUnitsFromSupplier({ commit, dispatch }, SupplierId) {
      const results = await requestHandler({
        title: 'getUnitsFromSupplier',
        methodName: 'getUnitsFromSupplier',
        method: () => {
          return HTTP.get('Supplier/getunits', {
            params: {SupplierId}
          })
        },
        success: result => result
      })
      return results
    },

    async getStockItemsFromSupplier({ commit, dispatch }, SupplierId) {
      const results = await requestHandler({
        title: 'getStockItemsFromSupplier',
        methodName: 'getStockItemsFromSupplier',
        method: () => {
          return HTTP.get('Supplier/getstockitems', {
            params: { SupplierId }
          })
        },
        success: result => result
      })
      return results
    },

    async updateCustomerBranch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateCustomerBranch',
        methodName: 'updateCustomerBranch',
        method: () => {
          return HTTP.put('RawEInvoice/customerbranch', payload)
        },
        success: result => result
      })
      return results
    },

    async updateOrderForEInvoice({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateOrderForEInvoice',
        methodName: 'updateOrderForEInvoice',
        method: () => {
          return HTTP.put('RawEInvoice/orderno', payload)
        },
        success: result => result
      })
      return results
    },

    async updateDespatchForEInvoice({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateDespatchForEInvoice',
        methodName: 'updateDespatchForEInvoice',
        method: () => {
          return HTTP.put('RawEInvoice/despatchno', payload)
        },
        success: result => result
      })
      return results
    },


    async updateSupplierBranchData({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateSupplierBranchData',
        methodName: 'updateSupplierBranchData',
        method: () => {
          return HTTP.put('RawEInvoice/supplierbranchcode', payload)
        },
        success: result => result
      })
      return results
    },

    async updateEInvoiceTransformStatus({ commit, dispatch }, isActive) {
      const results = await requestHandler({
        title: 'updateEInvoiceTransformStatus',
        methodName: 'updateEInvoiceTransformStatus',
        method: () => {
          return HTTP.put(`RawEInvoice/updateeinvoicetransformstatus?IsActive=${isActive}`)
        },
        success: result => result
      })
      return results
    },

    async changeEInvoiceActivationState({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateEInvoiceTransformStatus',
        methodName: 'updateEInvoiceTransformStatus',
        method: () => {
          return HTTP.put('RawEInvoice/activationstate', payload)
        },
        success: result => result
      })
      return results
    },

    async updateUnitCode({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateUnitCode',
        methodName: 'updateUnitCode',
        method: () => {
          return HTTP.put('RawEInvoice/line/unitcode', payload)
        },
        success: result => result
      })
      return results
    },

    async updateStockItemCode({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateStockItemCode',
        methodName: 'updateStockItemCode',
        method: () => {
          return HTTP.put('RawEInvoice/line/itemcode', payload)
        },
        success: result => result
      })
      return results
    },

    async getInvoiceTemplate({ state, commit }, payload) {
      const params = {
        ...payload
      }
      const results = await requestHandler({
        title: 'getInvoiceTemplate',
        methodName: 'getInvoiceTemplate',
        method: () => {
          return HTTP.post('RawEInvoice/renderhtml', params)
        },
        success: result => {
          commit('UPDATE_INVOICE_TEMPLATE', result.data)
        }
      })
      return results
    },
    async importUBL({ state, commit }, file) {
      const loadingName = 'importUBL'
      let formData = new FormData()
      formData.append('file', file)
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post(
            'RawEInvoice/import',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
        },
        incorrect: errorResult => {
          return errorResult
        },
        success: result => result
      })
      return results
    },

    async transformSelectedInvoices({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'transformSelectedInvoices',
        methodName: 'transformSelectedInvoices',
        method: () => {
          return HTTP.post('RawEInvoice/transform', payload)
        },
        success: result => result
      })
      return results
    },
    async getInvoiceTransformStatus({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getInvoiceTransformStatus',
        methodName: 'getInvoiceTransformStatus',
        method: () => {
          return HTTP.get('RawEInvoice/geteinvoicetransformstatus')
        },
        success: result => {
          commit('GET_INVOICE_TRANSFORM_STATUS', result.data)
        }
      })
      return results
    }
  },

  mutations: {

    UPDATE_START_DATE(state, startDate) {
      state.startDate = startDate
    },

    UPDATE_END_DATE(state, endDate) {
      state.endDate = endDate
    },

    GET_EINVOICE_DETAIL_LIST(state, eInvoice) {
      let eInvoiceDetailList = state.eInvoices.findItem(eInvoice.RawEInvoiceId)
      eInvoiceDetailList.addDetails(eInvoice.data.rawEInvoiceLineList)
    },

    UPDATE_EINVOICE_LIST(state, data) {
      state.eInvoices.addItems(data.rawEInvoiceList)
      state.eInvoices.list.forEach(item => {
        item.rowTextClass = item.isActive ? '' : 'TableViewPassiveItems'
      })
      state.eInvoicePage.number = data.pageNumber
      state.eInvoicePage.size = data.pageSize
      state.eInvoicePage.total = data.totalCount
    },

    SET_FOCUS(state, item) {
      item.isFocused = true
    },

    SET_NOFOCUS(state, item) {
      item.isFocused = false
    },
    SHOW_EINVOICE_DETAIL(state, eInvoice) {
      let eInvoiceDetailList = state.eInvoices.findItem(eInvoice.RawEInvoiceId)
      if (eInvoiceDetailList) eInvoiceDetailList.addDetails(eInvoice.data.rawEInvoiceLineList)
    },

    HIDE_EINVOICE_DETAIL(state, eInvoice) {
      eInvoice.showDetail = false
    },

    UPDATE_INVOICE_TEMPLATE(state, data) {
      state.InvoiceHTML = data.rawEInvoiceHtml
    },
    UPDATE_STORE_LIST(state, data) {
      state.matchingStores = data.storelist
    },
    UPDATE_BRANCH_CODES(state, data) {
      state.matchingBranches = data.supplierList
    },
    GET_INVOICE_TRANSFORM_STATUS(state, data) {
      state.invoiceTransformStatus = data.isActive
    }
  }
}

import BaseClass from '@/utils/baseclass'
import moment from 'moment'

export default class BatchList extends BaseClass {
  addItem(item) {
    let newItem = new Batch(item)
    this.list.push(newItem)
  }
  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.quantity = item.quantity
      findItem.remainingQuantity = item.remainingQuantity
      findItem.stockItemName = item.stockItemName
      findItem.stockItemId = item.stockItemId
      findItem.shelfLife = item.shelfLife
      findItem.detailList = []
      findItem.showDetail = false
      findItem.storeName = item.storeName
      findItem.storeId = item.storeId
      findItem.unitCode = item.unitCode
    }
  }
}
export class Batch {
  constructor(item) {
    this.id = item.id
    this.quantity = item.quantity
    this.remainingQuantity = item.remainingQuantity
    this.stockItemName = item.stockItemName
    this.shelfLife = item.shelfLife
    this.detailList = []
    this.showDetail = false
    this.storeName = item.storeName
    this.unitCode = item.unitCode
    this.stockItemId = item.stockItemId
    this.storeId = item.storeId
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new BatchDetail(detail, this.id, this.shelfLife))
    })
  }
  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }
}

export class BatchDetail {
  constructor(detail, id, shelfLife) {
    this.id = detail.id
    this.quantity = detail.quantity
    this.givenQuantity = detail.givenQuantity
    this.productionDate = detail.productionDate
    this.expirationDate = detail.expirationDate
    this.degree = detail.degree
    this.shipmentTime = detail.shipmentTime
    this.lotNumber = detail.lotNumber
    this.warehouseTime = detail.warehouseTime
    this.shelfStorageLocationListHeaderId = detail.shelfStorageLocationListHeaderId
    this.despatchDetailId = id
    this.shelfLife = shelfLife
  }
}

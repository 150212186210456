<template lang="pug">
DeleteAlert(
  :item="itemWithFormattedDate",
  :fields="fields",
  tableName="İade girişini",
  loading="Refund/delete_REFUND_HEADER",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RefundRemove',

  data () {
    return {
      fields: [{
        name: 'supplier.name',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_SupplierName_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_SupplierName')
      }, {
        name: 'despatchDateFormatted',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_DespatchDate_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_DespatchDate')
      }, {
        name: 'despatchNumber',
        is_visible: true,
        short_title: this.$t('Despatches.removeDespatch_TableColumn_DespatchNumber_Short'),
        title: this.$t('Despatches.removeDespatch_TableColumn_DespatchNumber')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.get_REFUND(this.$route.params.id)
        .then(res => {
          this.item = res.data.despatchHeader
        })
    }
  },

  computed: {
    itemWithFormattedDate () {
      const despatchDateFormatted = new Date(this.item.despatchDate).toLocaleDateString('tr')
      return Object.assign({}, this.item, {
        despatchDateFormatted
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Refund', [
      'delete_REFUND_HEADER',
      'get_REFUND'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_REFUND_HEADER(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Despatches.refund_Notificiation_Deleted', {
            date: this.item.despatchDate
          })
          this.notifyShow({ message })
          this.close()
          this.$emit('getList')
        }
      })
    }, 'deleteForm')
  }
}
</script>

<style lang="scss">
</style>

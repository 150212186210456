import requestHandler from "@/utils/requestHandler";
import HTTP from "@/utils/http";
import Despatches, { Invoices } from "../model";
import BatchList from "../model/batch";
import moment from "moment";
import { getPaginationNumber } from "@/utils/pagination";

export default {
  namespaced: true,

  state: {
    despatches: new Despatches(),
    invoices: new Invoices(),
    batchList: new BatchList(),
    totalCount: null,
    startDate: moment().startOf("month").hour(0).minute(0)._d,
    endDate: moment().hour(23).minute(59)._d,
    supplierIds: [],
    parentStoreIds: [],
    pageHeaderLinks: [
      "Despatch",
      "Invoice",
      "Refund",
      "OrderedDespatch",
      "EInvoice",
    ],
    invoicePage: {
      number: 1,
      size: 20,
      total: 0,
    },
    despatchPage: {
      number: 1,
      size: 20,
      total: 0,
    },
  },

  getters: {
    pageHeaderLinks: (state) => state.pageHeaderLinks,
    despatch_List: (state) => state.despatches.list,
    invoice_List: (state) => state.invoices.list,
    batchList: (state) => state.batchList.list,
    despatch_IsEmpty: (state) => state.despatches.list.length === 0,
    invoice_IsEmpty: (state) => state.invoices.list.length === 0,
    despatch_PageNumber: (state) => state.pageNumber,
    despatch_PageSize: (state) => state.pageSize,
    despatch_PageLast: (state) => Math.ceil(state.totalCount / state.pageSize),
    despatch_TotalCount: (state) => state.totalCount,
    startDate: (state) => state.startDate,
    endDate: (state) => state.endDate,
    InvoicePage: (state) => ({
      ...state.invoicePage,
      last: Math.ceil(state.invoicePage.total / state.invoicePage.size),
    }),
    DespatchPage: (state) => ({
      ...state.despatchPage,
      last: Math.ceil(state.despatchPage.total / state.despatchPage.size),
    }),
  },

  actions: {
    async get_DESPATCH_LIST(
      { state, commit, dispatch },
      {
        recordFilterType = 1,
        to = 1,
        SupplierList = state.supplierIds,
        startDate = state.startDate,
        endDate = state.endDate,
        pageSize,
        showDrafts,
        DespatchNumber,
        ParentStoreIds,
      }
    ) {
      const pageNumber = getPaginationNumber({
        selectedPage: to,
        currentPage: state.despatchPage.number,
      });

      let payload = {
        recordFilterType: recordFilterType,
        supplierIds: SupplierList,
        pageSize,
        pageNumber,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        showDrafts,
        DespatchNumber,
        parentStoreIds: ParentStoreIds,
      };

      const results = await requestHandler({
        title: "get_DESPATCH_LIST",
        methodName: "get_DESPATCH_LIST",
        method: () => {
          return HTTP.post("Despatch/header/all", payload);
        },
        success: (result) => {
          commit("UPDATE_DESPATCH_LIST", result.data);
          return result;
        },
      });
      return results;
    },

    async get_INVOICE_LIST({ state, commit }, payload) {
      const pageNumber = getPaginationNumber({
        selectedPage: payload.pageNumber,
        currentPage: state.invoicePage.number,
      });

      const params = {
        ...payload,
        pageNumber,
      };

      const results = await requestHandler({
        title: "get_INVOICE_LIST",
        methodName: "get_INVOICE_LIST",
        method: () => {
          return HTTP.post("Invoice/all", params);
        },
        success: (result) => {
          commit("UPDATE_INVOICE_LIST", result.data);
          return result;
        },
      });
      return results;
    },

    async get_DESPATCH({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "get_DESPATCH",
        methodName: "get_DESPATCH",
        method: () => {
          return HTTP.get("Despatch", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async multiple_FINALİZE({ commit, dispatch }, params) {
      const results = await requestHandler({
        title: "multiple_FINALİZE",
        methodName: "multiple_FINALİZE",
        method: () => {
          return HTTP.post("Invoice", params);
        },
        success: (result) => result,
      });
      return results;
    },

    async get_INVOICE({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "get_INVOICE",
        methodName: "get_INVOICE",
        method: () => {
          return HTTP.get("Invoice", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async get_DESPATCH_FINALIZE(
      { state, commit, dispatch },
      {
        id,
        recordType,
        invoiceNumber,
        invoiceDate,
        invoiceTotalAmount,
        invoiceType = 1,
        paymentType,
        paymentDate,
        paymentOptionId,
        isEInvoice,
        description
      }
    ) {
      const results = await requestHandler({
        title: "get_DESPATCH_FINALIZE",
        methodName: "get_DESPATCH_FINALIZE",
        method: () => {
          if (recordType === 1) {
            return HTTP.put("Despatch/finalize", { id, recordType, description });
          } else {
            return HTTP.put("Despatch/finalize", {
              id,
              recordType,
              invoiceNumber,
              invoiceDate,
              invoiceTotalAmount,
              invoiceType,
              paymentType,
              paymentDate,
              paymentOptionId,
              isEInvoice,
              description
            });
          }
        },
        success: (result) => result,
      });
      return results;
    },

    // HEADER
    async create_DESPATCH_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: "create_DESPATCH_HEADER",
        methodName: "create_DESPATCH_HEADER",
        method: () => {
          return HTTP.post("Despatch/header", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async getExchangeRate({ state, commit, dispatch }, params) {
      const results = await requestHandler({
        title: "getExchangeRate",
        methodName: "getExchangeRate",
        method: () => {
          return HTTP.get("ExchangeRate/bycurrencydefinition", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async update_DESPATCH_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: "update_DESPATCH_HEADER",
        methodName: "update_DESPATCH_HEADER",
        method: () => {
          return HTTP.put("Despatch/header", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async delete_DESPATCH_HEADER({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "delete_DESPATCH_ITEM",
        methodName: "delete_DESPATCH_ITEM",
        method: () => {
          return HTTP.delete("Despatch", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    // DETAIL
    async get_DESPATCH_DETAIL({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "get_DESPATCH_DETAIL",
        methodName: "get_DESPATCH_DETAIL",
        method: () => {
          return HTTP.get("Despatch/detail", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async get_PRICE_QUOTE(
      { state, commit, dispatch },
      { DespatchHeaderId, StockItemId, UnitId }
    ) {
      const results = await requestHandler({
        title: "get_PRICE_QUOTE",
        methodName: "get_PRICE_QUOTE",
        method: () => {
          return HTTP.get("PriceQuote/short", {
            params: { DespatchHeaderId, StockItemId, UnitId },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async create_DESPATCH_DETAIL({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: "create_DESPATCH_DETAIL",
        methodName: "create_DESPATCH_DETAIL",
        method: () => {
          return HTTP.post("Despatch/detail", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async update_DESPATCH_DETAIL({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: "update_DESPATCH_DETAIL",
        methodName: "update_DESPATCH_DETAIL",
        method: () => {
          return HTTP.put("Despatch/detail", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async update_BULK_DESPATCH_DETAIL({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: "update_BULK_DESPATCH_DETAIL",
        methodName: "update_BULK_DESPATCH_DETAIL",
        method: () => {
          return HTTP.put("Despatch/detail/bulk", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async delete_DESPATCH_DETAIL({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "delete_DESPATCH_DETAIL",
        methodName: "delete_DESPATCH_ITEM",
        method: () => {
          return HTTP.delete("Despatch/detail", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async getDespatchPriceFilter({ commit }, params) {
      const results = await requestHandler({
        title: "getDespatchPriceFilter",
        methodName: "getDespatchPriceFilter",
        method: () => {
          return HTTP.get("despatch/header/price/filter", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async get_PaymentList({ commit }) {
      const results = await requestHandler({
        title: "get_payment_LIST",
        methodName: "get_payment_LIST",
        method: () => {
          return HTTP.get("PaymentOption/all");
        },
        success: (result) => result,
      });
      return results;
    },

    async checkDespatchNumber({ state, commit, dispatch }, params) {
      const results = await requestHandler({
        title: "checkDespatchNumber",
        methodName: "checkDespatchNumber",
        method: () => {
          return HTTP.get("Despatch/header/check/despatchnumber", {
            params,
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async checkInvoiceNumber({ commit }, params) {
      const results = await requestHandler({
        title: "checkInvoiceNumber",
        methodName: "checkInvoiceNumber",
        method: () => {
          return HTTP.get("Invoice/check/invoicenumber", {
            params,
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async postSelectedInvoices({ commit }, list) {
      const results = await requestHandler({
        title: "postSelectedInvoices",
        methodName: "postSelectedInvoices",
        method: () => {
          return HTTP.post("Invoice/integration/accounting/send", list);
        },
        success: (result) => result,
      });
      return results;
    },

    async postInvoicePartition({ commit }, id) {
      const results = await requestHandler({
        title: "postInvoicePartition",
        methodName: "postInvoicePartition",
        method: () => {
          return HTTP.put("Invoice/decompose", { id });
        },
        success: (result) => result,
      });
      return results;
    },

    async getInvoicePriceFilter({ commit }, params) {
      const results = await requestHandler({
        title: "getInvoicePriceFilter",
        methodName: "getInvoicePriceFilter",
        method: () => {
          return HTTP.get("invoice/price/filter", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async getExpireDateData({ commit }, payload) {
      const results = await requestHandler({
        title: "getExpireDateData",
        methodName: "getExpireDateData",
        method: () => {
          return HTTP.post("Despatch/detail/batch", payload);
        },
        success: (result) => {
          commit("UPDATE_BATCH_LIST", result.data);
          return result;
        },
      });
      return results;
    },

    async checkLotNumber({ state, commit, dispatch }, params) {
      const results = await requestHandler({
        title: "checkLotNumber",
        methodName: "chechLotNumber",
        method: () => {
          return HTTP.get("Batch/check/lotnumber", {
            params,
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async createBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "createBatch",
        methodName: "createBatch",
        method: () => {
          return HTTP.post("Batch", payload);
        },
        success: (result) => result,
      });
      return results;
    },

    async updateBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "updateBatch",
        methodName: "updateBatch",
        method: () => {
          return HTTP.put("Batch", payload);
        },
        success: (result) => result,
      });
      return results;
    },

    async getBatchDetails({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "getBatchDetails",
        methodName: "getBatchDetails",
        method: () => {
          return HTTP.post("Batch/all", payload);
        },
        success: (result) => result,
      });
      return results;
    },
    async deleteBatch({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: "deleteBatch",
        methodName: "deleteBatch",
        method: () => {
          return HTTP.delete("Batch", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    /* async validateBatchQuantity({ state, commit, dispatch }, despatchDetailIds) {
      const results = await requestHandler({
        title: 'validateBatchQuantity',
        methodName: 'validateBatchQuantity',
        method: () => {
          return HTTP.get('Despatch/detail/batch/validate', {despatchDetailIds})
        },
        success: result => result
      })
      return results
    } */
  },

  mutations: {
    RESET(state) {
      state.despatches = new Despatches();
      state.pageNumber = 1;
      state.pageSize = 9999;
      state.totalCount = null;
      state.invoicePage = {
        number: 1,
        size: 20,
        total: 0,
      };
    },

    UPDATE_START_DATE(state, startDate) {
      state.startDate = startDate;
    },

    UPDATE_END_DATE(state, endDate) {
      state.endDate = endDate;
    },

    UPDATE_INVOICE_LIST(state, data) {
      state.invoices.addItems(data.invoiceList);
      state.invoicePage.number = data.pageNumber;
      state.invoicePage.size = data.pageSize;
      state.invoicePage.total = data.totalCount;
    },

    UPDATE_DESPATCH_LIST(state, data) {
      state.despatches.addItems(data.despatchHeaderList);
      state.totalCount = data.totalCount;
      state.despatchPage.number = data.pageNumber;
      state.despatchPage.size = data.pageSize;
      state.despatchPage.total = data.totalCount;
    },

    UPDATE_DESPATCH_ITEM(state, item) {
      state.despatches.updateItem(item);
    },

    SET_FOCUS(state, item) {
      item.isFocused = true;
    },

    SET_NOFOCUS(state, item) {
      item.isFocused = false;
    },

    //
    // DETAIL
    //
    HIDE_DETAIL(state, despatchHeader) {
      despatchHeader.showDetail = false;
    },

    HIDE_OTHERS_DETAILS(state, despatchHeader) {
      const others = state.despatches.list.filter(
        (despatch) => despatch.id !== despatchHeader.id
      );
      others.forEach((otherDespatch) => {
        otherDespatch.showDetail = false;
      });
    },

    SHOW_DETAIL(state, data) {
      let despatch = state.despatches.findItem(data.despatchHeader.id);
      despatch.addDetails(data.despatchDetailList, data.id);
      despatch.showDetail = true;
    },

    UPDATE_DETAIL_FROM_UI(state, { detail, copyDetail }) {
      detail.updateDetail(copyDetail);
    },

    SHOW_INVOICE_DETAIL(state, data) {
      let invoice = state.invoices.findItem(data.invoice.id);
      invoice.addDetails(data.despatchHeaderList);
      invoice.showDetail = true;
    },

    HIDE_INVOICE_DETAIL(state, invoice) {
      invoice.showDetail = false;
    },

    UPDATE_SUPPLIER_IDS(state, supplierList) {
      state.supplierIds = supplierList;
    },
    UPDATE_PARENT_STORE_IDS(state, parentStoreList) {
      state.parentStoreIds = parentStoreList;
    },

    SHOW_DESPATCH_IN_INVOICE_DETAIL(state, data) {
      state.invoices.list.map((invoice) => {
        invoice.invoiceDetailList.map((despatch) => {
          if (despatch.id === data.despatchHeader.id) {
            despatch.addDetails(data.despatchDetailList);
            despatch.showDetail = !despatch.showDetail;
          } else {
            despatch.showDetail = false;
            despatch.despatchDetailList = [];
          }
        });
      });
    },

    HIDE_DESPATCH_INVOICE_DETAIL(state, despatch) {
      despatch.showDetail = false;
    },
    UPDATE_BATCH_LIST(state, data) {
      state.batchList.addItems(data.despatchDetailList);
    },

    SHOW_BATCH_DETAIL(state, data) {
      let batchList = state.batchList.findItem(data.id);
      batchList.showDetail = true;
      batchList.addDetails(data.batch.batchList);
    },
    HIDE_BATCH_DETAIL(state, batchList) {
      batchList.showDetail = false;
    },
  },
};

<template lang="pug">
Popup

    template(slot="title" v-if="isMultiple") {{ supplierName }}
    template(slot="title" v-else) {{ !despatch.despatchHeader.supplier ? "-" : despatch.despatchHeader.supplier.name}}

    template(slot="detail" v-else)
      div.Popup-despatch-info
        div
          Icon(name="icon-calender")
          span {{ despatchDate }}
        div
          Icon(name="icon-popup-despatch-no")
          span {{ despatch.despatchHeader.despatchNumber }}

    template(slot="content")
      form.Form(
      id="form-despatch-finalize"
      @submit.prevent="onSubmitDespatchFinalizeForm")

        .Form-item(v-show="isInvoice")
          .Form-item-info-multiple
            .Form-item-info-multiple-row
              .text {{ $t('Despatches.refund_Finalize_NetTotalAmount') }}
              .value {{ calculateNetTotalAmount | formatCurrency }}
            .Form-item-info-multiple-row
              .text {{ $t('Despatches.refund_Finalize_TaxTotalAmount') }}
              .value {{ calculateTaxTotalAmount | formatCurrency }}
            .Form-item-info-multiple-row
              .text {{ $t('Despatches.refund_Finalize_GrossTotalAmount') }}
              .value {{ calculateGrossTotalAmount | formatCurrency }}

        .Form-item.required(v-if="!isMultiple")
            .control

              .finalize-invoice-type
                .finalize-invoice-type-radio.no-bottom-border
                  custom-radio.finalize-invoice-type-radio-button(
                  :id="`radio-despatch-invoice-type-invoice-1`"
                  :label="$t('Despatches.Payment_isPassive_Text')"
                  :checked="!isInvoice"
                  @change="changeInvoiceType(false)")
                .finalize-invoice-type-radio
                  custom-radio.finalize-invoice-type-radio-button(
                  :id="`radio-despatch-invoice-type-invoice-2`"
                  :label="$t('Despatches.Payment_isActive_Text')"
                  :checked="isInvoice"
                  @change="changeInvoiceType(true)")

        .Form-item.required(v-if="isInvoice")
          label.Form-item-label {{ $t('Despatches.finalize_Popup_FormField_invoiceNumber') }}

          .control
            input.txt(
            name="invoiceNumber"
            autocomplete="off"
            :data-vv-as="$t('Despatches.finalize_Popup_FormField_invoiceNumber')"
            v-model="item.invoiceNumber"
            v-validate="'required|max:32|verify_invoiceNumber'",
            :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
            id="input-finalize-despatch-invoice-number"
            :class="{ 'is-danger': veeErrors.has('invoiceNumber') }")

            Button(
            id="btn-recipes-popup-loading-code"
            type="button",
            variant="icon loading"
            tabindex="-1",
            v-show="isPendingInvoiceNumber")
              Loading(theme="disable")
            Button(
            id="btn-recipes-popup-ok-code"
            type="button",
            variant="icon check"
            tabindex="-1",
            iconName="icon-check",
            iconClass="icon-check",
            v-show="isValidateInvoiceNumber")


          p.Form-item-help.is-danger(
            v-show="veeErrors.has('invoiceNumber')"
            ) {{ veeErrors.first('invoiceNumber') }}

        .Form-item(v-if="isInvoice")
          .invoice-type
            .invoice-type-radio
              custom-radio.popup-item-check(
              id="radio-finalize-refund-e-invoice-type"
              :label="$t('Despatches.eInvoice')"
              :checked="isElectronicInvoice"
              @change="changeToElectronicInvoice")

        .Form-item.required(v-if="isInvoice")
          label.Form-item-label {{ $t('Despatches.Invoice_Date') }}
          .control.form-item-select
            CustomDatepicker(
              name="invoiceDate",
              :data-vv-as="$t('Despatches.Invoice_Date') ",
              v-model="item.invoiceDate"
              :class="{ 'is-danger': veeErrors.has(`invoiceDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="invoiceDateDisabled")

        .Form-item.required(v-if="isInvoice")
          label.Form-item-label {{ $t('Despatches.finalize_Popup_FormField_invoiceGrossTotal') }}

          .control
            currency-input.txt(
            name="invoiceGrossTotal"
            :data-vv-as="$t('Despatches.finalize_Popup_FormField_invoiceGrossTotal')"
            v-model="item.invoiceGrossTotal"
            v-validate="'required|verify_gross'"
            id="input-currency-finalize-despatch-invoice-gross-total"
            :class="{ 'is-danger': veeErrors.has('invoiceGrossTotal') }")

            p.Form-item-help.is-danger(
              v-show="veeErrors.has('invoiceGrossTotal')"
              ) {{ veeErrors.first('invoiceGrossTotal') }}

        .Form-item.required(v-if="isInvoice")
          label.Form-item-label {{ $t('Despatches.finalize_Popup_FormField_invoiceType') }}
          .payment-type
            .payment-type-radio
              custom-radio.popup-item-check(
              id="radio-finalize-payment-type-cash"
              :label="$t('Despatches.PaymentTypeCash')"
              :checked="isCash"
              @change="changePaymentType(true)")

            .payment-type-radio
              custom-radio.popup-item-check(
              id="radio-finalize-payment-type-future"
              :label="$t('Despatches.PaymentTypeFuture')"
              :checked="isCash === false"
              @change="changePaymentType(false)")

          p.Form-item-help.is-danger(
            v-if="showPaymentTypeError"
            ) {{ $t('Despatches.paymentTypeErrorMessage') }}

        .Form-item(v-if="isInvoice && isCash")
          label.Form-item-label
            | {{ $t('Despatches.paymentTypeList') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            id="select-despatch-invoice-popup-payment-list"
            name="paymentList"
            v-model="item.paymentOptionId")
              option(
              :value="null"
              id="option-despatches-invoice-popup-paymentlist-unselected") {{ $t('Supplier.detail_Popup_FormField_SupplierGroups_Unselected') }}
              option(
              v-for="group in paymentList"
              :key="group.id"
              :value="group.id"
              :id="`option-suppliers-popup-group-name-${group.name}`"
              ) {{ group.name }}
        .Form-item.required(v-show="isInvoice")
          label.Form-item-label {{ $t('Despatches.Payment_Date') }}
          .control.form-item-select
            .fake-datepicker(v-if="isCash || isCash === null")
              Icon(name="icon-datepicker-big")
              input.fake-payment-date(
                class="txt"
                :value="fakePaymentDate"
                disabled
              )

            CustomDatepicker(
              v-else,
              name="paymentDate",
              :data-vv-as="$t('Despatches.paymentDate') ",
              v-model="item.paymentDate"
              :class="{ 'is-danger': veeErrors.has(`invoiceDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="PaymentDateDisabled")

        .Form-item(v-if="isInvoice")
          label.Form-item-label {{ $t('Global.description') }}
          customTextareaInput(
            ref="descriptionInput"
            v-model="item.description"
            v-validate="'max:128'"
            name="description"
            :data-vv-as="$t('Global.description')"
            :error="veeErrors.has('description')"
            rows="3")
          showValidateError(
            v-show="veeErrors.has('description')"
            :errorName="veeErrors.first('description')")

    template(slot="footer")

      Button(
      id="btn-despatch-detail-popup-submit"
      type="submit"
      size="medium"
      variant="full"
      primary
      form="form-despatch-finalize"
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="!isInvoice") {{ $t('Despatches.detail_Popup_FinishFinalizeCreateButtonType1') }}
          template(v-else) {{ $t('Despatches.detail_Popup_FinishFinalizeCreateButtonType2') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { Validator, mapFields } from 'vee-validate'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DespatchFinalizeForm',

  props: {
    status: {
      type: String,
      default: 'single'
    }
  },

  watch: {
    'isCash' () {
      this.updatePaymentDate()
    },
    'item.invoiceDate' () {
      this.updatePaymentDate()
      if (!this.isCash && this.isVisitedTypeDeferre) {
        this.item.paymentDate = moment(this.item.invoiceDate).add(1, 'days')._d
      }
    }
  },

  data () {
    return {
      item: {
        invoiceNumber: null,
        invoiceDate: new Date(),
        invoiceGrossTotal: null,
        paymentDate: null,
        paymentOptionId: null,
        description: null
      },
      despatch: {
        despatchHeader: {
          netTotalAmount: 0,
          taxTotalAmount: 0,
          grossTotalAmount: 0
        },
        despatchDetail: null
      },
      isInvoice: false,
      isCash: null,
      showPaymentTypeError: false,
      isVisitedTypeDeferre: false,
      selectedDespatches: [],
      paymentList: [],
      isElectronicInvoice: false
    }
  },


  created () {
    // const self = this

    Validator.extend('verify_gross', {
      // Custom validation message
      getMessage: () => this.$t('Despatches.checkGrossAmountTotal'),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        resolve({
          valid: this.isMultiple
            ? value === Number(this.calculateGrossTotalAmount.toFixed(3))
            : value === Number(this.despatch.despatchHeader.grossTotalAmount.toFixed(3))
        })
      })
    })

    Validator.extend('verify_invoiceNumber', {
      // Custom validation message

      getMessage: () => this.$t('Despatches.checkInvoiceNumber'),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params = {
          InvoiceNumber: value,
          SupplierId: this.isMultiple ? this.selectedDespatches[0].supplier.id : this.despatch.despatchHeader.supplier.id
        }

        this.checkInvoiceNumber(params)
          .then(res => {
            if (res) {
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })

    this.isInvoice = !!this.isMultiple
  },

  async mounted () {
    const resultP = await this.get_PAYMENTTYPE_LIST()
    if (resultP) {
      this.paymentList = resultP.data.paymentTypeList
    }
    if (this.isMultiple) {
      this.selectedDespatches = this.$route.params.despatchesList
      const despatchHeaderId = this.selectedDespatches[0].id
      await this.get_REFUND(despatchHeaderId).then(res => {
        if (res.status === 200) {
          this.despatch.despatchHeader = res.data.despatchHeader
        }
      })
    } else {
      let despatchHeaderId = this.$route.params.id
      await this.get_REFUND(despatchHeaderId).then(res => {
        if (res.status === 200) {
          this.despatch.despatchHeader = res.data.despatchHeader
        }
      })

      this.item.invoiceNumber = this.despatch.despatchHeader.despatchNumber
      this.item.invoiceDate = this.despatch.despatchHeader.despatchDate
    }
    this.item.invoiceGrossTotal = this.calculateGrossTotalAmount
  },

  computed: {
    ...mapGetters('Supplier', [
      'MiniSuppliers'
    ]),

    ...mapFields({
      flagsInvoiceNumber: 'invoiceNumber'
    }),

    isPendingInvoiceNumber () {
      return this.flagsInvoiceNumber.pending
    },

    isValidateInvoiceNumber () {
      if (this.flagsInvoiceNumber.required) {
        return this.flagsInvoiceNumber.valid &&
                 !this.isPendingInvoiceNumber
      } else {
        return this.flagsInvoiceNumber.valid &&
                 this.item.invoiceNumber !== '' &&
                 this.item.invoiceNumber !== null &&
                 !this.isPendingInvoiceNumber
      }
    },

    isMultiple () {
      return this.status === 'multiple'
    },

    supplierName () {
      return this.selectedDespatches.length > 0 ? this.selectedDespatches[0].supplier.name : '-'
    },

    calculateNetTotalAmount () {
      if (!this.isMultiple) {
        return this.despatch.despatchHeader.netTotalAmount
      } else {
        let result = 0
        this.selectedDespatches.forEach(despatch => {
          result += despatch.netTotalAmount
        })
        return result
      }
    },

    deferredDay () {
      return Number(this.despatch.despatchHeader.deferredDay)
    },

    calculateTaxTotalAmount () {
      if (!this.isMultiple) {
        return this.despatch.despatchHeader.taxTotalAmount
      } else {
        let result = 0
        this.selectedDespatches.forEach(despatch => {
          result += despatch.taxTotalAmount
        })
        return result
      }
    },

    calculateGrossTotalAmount () {
      if (!this.isMultiple) {
        return this.despatch.despatchHeader.grossTotalAmount
      } else {
        let result = 0
        this.selectedDespatches.forEach(despatch => {
          result += despatch.grossTotalAmount
        })
        return result
      }
    },

    despatchDate () {
      return moment(this.despatch.despatchHeader.despatchDate).format('DD.MM.YYYY')
    },

    isLoading () {
      return this.isDisabledSubmit || this.$wait.is(['get_REFUND_LIST', 'get_DESPATCH_FINALIZE', 'get_REFUND', 'submitForm'])
    },

    recordType () {
      return this.isInvoice ? 2 : 1
    },

    fakePaymentDate () {
      return moment(this.item.invoiceDate).format('D MMMM YYYY')
    },

    PaymentDateDisabled () {
      return {
        to: moment(this.item.invoiceDate).add(1, 'days')._d
      }
    },

    invoiceDateDisabled () {
      return {
        to: moment(this.despatch.despatchHeader.despatchDate)._d,
        from: new Date()
      }
    },

    filteredUnitList () {
      if (!this.showStockDetail) return []
      if (this.isFinalized && this.isEdit) {
        return [this.findUnit(this.item.unitId)]
      }
      const baseUnitId = this.isEdit
        ? this.findUnit(this.item.unitId).baseUnitId
        : this.findUnit(this.item.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Refund', [
      'get_REFUND',
      'get_REFUND_FINALIZE',
      'get_REFUND_LIST'
    ]),

    ...mapActions('Despatch', [
      'multiple_FINALİZE',
      'checkInvoiceNumber'
    ]),

    ...mapActions('PaymentTypes', [
      'get_PAYMENTTYPE_LIST'
    ]),

    changeInvoiceType (type) {
      this.isInvoice = type
    },

    paymentDateOpened (event) {
      this.isVisitedTypeDeferre = true
    },

    updatePaymentDate () {
      if (!this.isCash && !this.isVisitedTypeDeferre) {
        this.item.paymentDate = moment(this.item.invoiceDate).add(this.deferredDay, 'days')._d
      }
    },

    changePaymentType (type) {
      this.showPaymentTypeError = false
      this.isVisitedTypeDeferre = this.isCash ? false : this.isVisitedTypeDeferre
      this.isCash = type
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitDespatchFinalizeForm: vueWaitLoader(async function () {
      if (!this.isInvoice) {
        await this.finalizeTypeWaybill()
      } else {
        await this.$validator.validateAll()
          .then(async result => {
            this.showPaymentTypeError = this.isCash === null
            if (!result || this.showPaymentTypeError) {
              return false
            } else {
              await this.finalizeTypeInvoice()
            }
          })
      }
    }, 'submitForm'),

    async finalizeTypeWaybill () {
      await this.get_REFUND_FINALIZE({id: this.despatch.despatchHeader.id, recordType: this.recordType})
        .then(res => {
          if (res) {
            let supList = []
            this.MiniSuppliers.map(sup => supList.push(sup.id))

            let message = this.$t('Despatches.refund_invoiceFinalize')
            this.notifyShow({ message })
            this.$emit('getList')
            this.$emit('takePrint', this.despatch.despatchHeader.id, true)
            this.close()
          }
        })
    },

    changeToElectronicInvoice () {
      this.isElectronicInvoice = !this.isElectronicInvoice
    },

    async finalizeTypeInvoice () {
      let supList = []
      this.MiniSuppliers.map(sup => supList.push(sup.id))
      let invoiceDate = moment(this.item.invoiceDate).format('YYYY-MM-DD')
      let paymentDate = moment(this.item.paymentDate).format('YYYY-MM-DD')
      let id = !this.isMultiple ? this.despatch.despatchHeader.id : this.selectedDespatches.map(despatch => despatch.id)
      let paymentDateForSubmit = this.isCash ? invoiceDate : paymentDate
      if (this.isMultiple) {
        await this.multiple_FINALİZE({
          DespatchList: id,
          InvoiceNumber: this.item.invoiceNumber,
          InvoiceDate: invoiceDate,
          TotalAmount: Number(this.item.invoiceGrossTotal.toFixed(3)),
          paymentType: this.isCash ? 1 : 2,
          paymentOptionId: this.item.paymentOptionId,
          paymentDate: paymentDateForSubmit,
          isEInvoice: this.isElectronicInvoice,
          description: this.item.description
        }).then(res => {
          if (res) {
            let message = this.$t('Despatches.refund_invoiceFinalize')
            this.notifyShow({ message })
            this.close()
            this.$router.push({
              name: 'Invoice',
              params: { invoiceId: res.data.id }
            })
          }
        })
      } else {
        await this.get_REFUND_FINALIZE({
          id: id,
          recordType: this.recordType,
          invoiceNumber: this.item.invoiceNumber,
          invoiceDate: invoiceDate,
          invoiceTotalAmount: Number(this.item.invoiceGrossTotal.toFixed(3)),
          paymentOptionId: this.item.paymentOptionId,
          paymentType: this.isCash ? 1 : 2,
          paymentDate: paymentDateForSubmit,
          isEInvoice: this.isElectronicInvoice,
          description: this.item.description
        }).then(res => {
          if (res) {
            let message = this.$t('Despatches.refund_invoiceFinalize')
            this.notifyShow({ message })
            this.close()
            this.$emit('getList')
            this.$emit('takeInvoicePrint', res.data.despatchHeader.invoiceId, true)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">

  .Popup-despatch-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;

    div {
      margin-right: 30px;

      svg {
        color: $color-success;
        margin-right: 5px;
      }
    }
  }

  .Form-item-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    font-size: $font-size-small;

    > div {
      display: flex;
      margin-right: 20px;
      align-items: center;

      select {
        margin-bottom: -2px;
        border: 0;
        background-color: $color-gray;
      }
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

  .Form-item-info-multiple {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    flex-wrap: wrap;
    border: solid $color-gray 1px;
    border-radius: 4px;

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 17px 10px;
      border-bottom: 1px $color-gray solid;
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

.finalize-invoice-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  &-radio {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $color-gray;
    border-top: 1px solid $color-gray;

    &-button {
      height: 50px;
      margin-left: 10px;
    }
  }
  .no-bottom-border {
    border-bottom: none;
  }
}
.Popup-header .content {
  border: none;
}
.Form-item-info-multiple {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 17px 10px;
      background-color: #fff;

    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }
.invoice-type{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-radio {
    width: 100%;
    display: flex;
  }
}
.payment-type {
  display: flex;
  margin-left: 10px;
  &-radio {
    display: flex;

    label {
      font: $font-size-small $font-family;
      margin-right: 10px;
    }
  }
}

.fake-payment-date {
  width: 100%;
  height: 46px;
  padding: 8px;
}
.fake-datepicker {
  position: relative;
  .icon-datepicker-big {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%)
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { isOpen: true }, on: { closeModal: _vm.closeModal } },
    [
      _c("template", { slot: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.selectMatchedOrder")))]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
          _vm.approveOrders.length && !_vm.isFetchingData
            ? _c("NestedTable", {
                ref: "ordersNestedTable",
                attrs: {
                  fields: _vm.fields,
                  detailFields: _vm.detailFields,
                  data: _vm.approveOrders,
                  statusIcon: false,
                  canAddNewDetail: false,
                  isFinalizeAction: false,
                },
                on: { showDetailAction: _vm.showOrderDetail },
              })
            : _vm._e(),
          !_vm.approveOrders.length && !_vm.isFetchingData
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("EInvoice.selectOrder_empty"),
                  description: _vm.$t("EInvoice.selectOrder_empty"),
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "checkbox" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("CustomRadio", {
                        attrs: {
                          id: "select-order-for-eInvoice",
                          checked: header.row === _vm.selectedOrder,
                          label: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectOrder(header.row)
                          },
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "description" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c(
                    "div",
                    {},
                    [
                      header.row.description
                        ? _c(
                            "Button",
                            {
                              staticClass:
                                "show-MiniPopup relative width-max-content",
                              attrs: {
                                size: "medium",
                                variant: "info",
                                id: `eInvoice-order-header-info-button-${header.rowIndex}`,
                                iconName: "icon-global-info",
                                justIcon: true,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MiniPopup-right-primary m-top-15 c-dark",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(header.row.description)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.approveOrders.length && !_vm.isFetchingData
            ? _c(
                "Button",
                {
                  staticClass: "m-top-5",
                  attrs: {
                    id: "nested-detail-finalize-order",
                    inline: "",
                    size: "small",
                    primary: "",
                    iconName: "icon-btn-success",
                    iconClass: "icon-btn-success",
                    isLoading: _vm.isWaitingUpdate,
                    disabled: !_vm.selectedOrder,
                  },
                  on: { click: _vm.saveSelectedOrder },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.saveSelection")))])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('Despatches.refund_popup_EditTitle') }}
      template(v-else) {{ $t('Despatches.refund_popup_CreateTitle') }}

    template(slot="content")
      form.Form(
      id="form-despatch"
      @submit.prevent="onSubmitDespatchForm")

        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popup_FormField_SupplierName') }}
          .control
            custom-search(
              ref="firstInput"
              name="supplier",
              v-model="item.supplier",
              :data-vv-as="$t('Despatches.popup_FormField_SupplierName')",
              v-validate="'required'",
              :inputClass="{ 'is-danger': veeErrors.has('supplier') }",
              :on-search="getOptions",
              :options="selectOptions",
              label="name",
              :disabled="despatch.status === 1"
              :placeholder="$t('Despatches.popup_FormField_SupplierName_Placeholder')"
              @input="resetListSelection"
            )

            showValidateError(
              v-show="veeErrors.has('supplier')"
              :errorName="veeErrors.first('supplier')"
            )


        div.Form-item.required(v-if="tenantMultiCurrencyIsActive")
          label.Form-item-label {{ $t('Despatches.popup_FormField_StoreName') }}
          .control
            custom-search(
            ref="secondaryInput"
            component-name="despatch-store-select"
            name="storeSelect",
            v-model="item.parentStore",
            :data-vv-as="$t('Despatches.popup_FormField_StoreName')",
            v-validate="'required'",
            :inputClass="{ 'is-danger': veeErrors.has('storeSelect') }",
            :on-search="getOptionsStore",
            :options="selectMiniStoreList",
            :disabled="despatch.status === 1 || !item.supplier"
            label="name",
            :searchAll="true"
            :withDebounce="false"
            :placeholder="$t('Despatches.popup_FormField_StoreName_Placeholder')"
            :loading="isSearchStoreLists"
            @input="inputSelection"
            @resetOptions="resetOptions"
          )

          showValidateError(
            v-show="veeErrors.has('storeSelect')"
            :errorName="veeErrors.first('storeSelect')"
          )
        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popupForm_RefundNumbers') }}
          .control
            customTextInput(
              name="despatchNumber"
              id="input-despatch-popup-number"
              :data-vv-as="$t('Despatches.popup_FormField_DespatchNumber')"
              :error="veeErrors.has('despatchNumber')"
              v-validate="'required|max:32|verify_number'"
              v-model="item.despatchNumber"
              :class="{ 'disabled': !item.supplier }"
              :disabled="!item.supplier"
              :isDelayInput="true"
            )
            //
            Button(
            id="btn-despatch-popup-loading-number"
            type="button",
            variant="icon loading"
            tabindex="-1",
            v-show="isPendingNumber")
              Loading(theme="disable")
            Button(
            id="btn-despatch-popup-ok-number"
            type="button",
            variant="icon check"
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateNumber")
            //
            showValidateError(
              v-show="veeErrors.has('despatchNumber')"
              :errorName="veeErrors.first('despatchNumber')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popupForm_RefundDate') }}
          .control.form-item-select

              CustomDatepicker(
                name="despatchDate",
                :data-vv-as="$t('Despatches.popupForm_RefundDate') ",
                v-model="item.despatchDate"
                :class="{ 'is-danger': veeErrors.has(`despatchDate`) }"
                v-validate="'required'"
                pickerType="manuel",
                inputIconRight="icon-datepicker-big"
                inputIcon="",
                :disabledStartDate="disableDate")

              showValidateError(
                v-show="veeErrors.has('despatchDate')"
                :errorName="veeErrors.first('despatchDate')"
              )
        .Form-item.required(v-if="isStoreDifferentCurrency && !isCurrencySame")
          label.Form-item-label {{ $t('Despatches.popup_Rate') }}
          .control.form-item-select
            currency-input.txt.currency(
              id="exchange-rate-refund"
              name="exchangeRate"
              v-model="item.exchangeRate"
              :data-vv-as="$t('Despatches.popup_FormField_Rate')"
              :placeholder="$t('Despatches.popup_FormField_Rate')"
              :error="veeErrors.has('exchangeRate')"
              v-validate="'greater_than:0|required'"
            )

            .currency-symbol {{ rateIsoCode }}

            showValidateError(
              v-show="veeErrors.has('exchangeRate')"
              :errorName="veeErrors.first('exchangeRate')"
            )
        div.Form-item(v-if="!isEdit" :class="isOnlyListUsage ? 'required' : ''")
          label.Form-item-label {{ $t('Despatches.popup_FormField_despatchListName') }}
          .control
            .despatch-list
              custom-search.form-item-select(
                component-name="despatch-list"
                name="despatchList",
                v-model="item.despatchList",
                :disabled="!item.supplier"
                :on-search="getDespatchListOptions",
                :options="selectOptionsDespatchList",
                label="name",
                :error="veeErrors.has('despatchList')"
                v-validate="{required: isOnlyListUsageCalculate}"
                :data-vv-as="$t('Despatches.popup_FormField_despatchListName') ",
                :searchAll="true"
                :placeholder="$t('Despatches.popup_FormField_despatchListName_Placeholder')"
                @input="selectDespatchList"
              )
              showValidateError(
                v-show="veeErrors.has('despatchList')"
                :errorName="veeErrors.first('despatchList')"
              )

          .selected-items(v-if="selectedDespatchLists.length > 0")
            Button(
              v-for="(item, index) in selectedDespatchLists" 
              :key="index"
              size="small"
              seashell
              :id="`input-listing-remove-${index}`",
              @click ="removeDespatchListFromList(item)"
            )
              span.item {{ item.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`input-listing-remove-item-delete-${index}`"
              )

        div.Form-item.m-top-15
          label.Form-item-label {{ $t('Despatches.popupForm_RefundCause') }}
          .control
            customTextareaInput(
              name="description"
              id="input-despatch-popup-description"
              v-model="item.description"
              v-validate="'max:512'"
              :data-vv-as="$t('Despatches.popupForm_RefundCause')"
              :error="veeErrors.has('description')"
              :class="{ 'disabled': !item.supplier }"
              :disabled="!item.supplier"
            )

            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")

      Button.item(
      id="btn-despatch-popup-submit"
      type="submit",
      primary
      variant="full"
      size="medium"
      form="form-despatch",
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Despatches.refund_popup_FinishEditButton') }}
          template(v-else) {{ $t('Despatches.refund_popup_FinishCreateButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>
<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { omit, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RefundForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      disableDate: {
        from: new Date()
      },
      selectOptions: [],
      selectMiniStoreList: [],
      selectedDespatchLists: [],
      selectOptionsDespatchList: [],
      rateIsoCode: null,
      item: {
        supplier: null,
        parentStore: {
          id: '-1',
          name: ''
        },
        despatchDate: new Date(),
        despatchNumber: null,
        despatchList: null,
        description: null,
        exchangeRate: null
      },
      despatch: {},
      isOnlyListUsage: false,
      isStoreDifferentCurrency: false
    }
  },

  watch: {
    'item.supplier' () {
      if (!this.isEdit) {
        if (this.item.supplier.currencyDefinitionId !== this.activeCurrency.id) this.rateIsoCode = this.activeCurrency.isoCode + '/' + this.item.supplier.currencyDefinitionIsoCode 
        else this.rateIsoCode = null
        this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
          if (res) this.item.exchangeRate = res.data.rate
        })
      }
    },
    'item.despatchDate' () {
      if (!this.isEdit) {
        this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
          if (res) this.item.exchangeRate = res.data.rate
        })  
      }
    },
    'item.parentStore' () {
      if (!this.isEdit) {
        if(this.item.parentStore.currencyDefinitionId !== this.item.supplier.currencyDefinitionId) {
          this.rateIsoCode = this.item.supplier.currencyDefinitionIsoCode + '/' + this.item.parentStore.currencyDefinitionIsoCode
          this.isStoreDifferentCurrency = true
          this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId,toCurrencyDefinitionId: this.item.parentStore.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
            if (res) this.item.exchangeRate = res.data.rate
          })  
        }
      }
    }
  },

  created () {
    const self = this

    const verifyNumber = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            DespatchNumber: value,
            SupplierId: self.item.supplier.id
          }
          if (self.isEdit) {
            params.Id = self.despatch.id
          }
          self.checkRefundNumber(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }
    const verifyNumberMessages = {
      tr: {
        messages: {
          verify_number: field => 'Girmeye çalıştığınız iade sistemde mevcut, lütfen kontrol ediniz!'
        }
      }
    }
    Validator.extend('verify_number', verifyNumber)
    Validator.localize(verifyNumberMessages)
  },

  async mounted () {
    if (this.isEdit) this.init()
    this.focusFirstInput()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
  },

  computed: {
    ...mapGetters('Refund', [
      'refund_PageNumber'
    ]),
    ...mapGetters('Settings', ['permissions', 'tenantMultiCurrencyIsActive']),

    ...mapGetters('Global', ['activeCurrency', 'CurrencyList']),

    ...mapGetters('Supplier', ['Suppliers']),

    ...mapFields({
      flagsDespatchNumber: 'despatchNumber'
    }),

    newItem () {
      return {
        id: this.despatch.id,
        despatchDate: moment(this.item.despatchDate).format('YYYY-MM-DD'),
        despatchNumber: this.item.despatchNumber,
        parentStoreId: this.tenantMultiCurrencyIsActive ? this.item.parentStore.id : null,
        supplierId: this.item.supplier.id,
        description: this.item.description,
        exchangeRate: this.tenantMultiCurrencyIsActive && !this.isCurrencySame ? this.item.exchangeRate : null,
      }
    },

    isLoading () {
      return this.$wait.is(['update_REFUND_DETAIL']) || this.$wait.is(['create_REFUND_HEADER'])
    },

    isOnlyListUsageCalculate () {
      return this.isOnlyListUsage && this.selectedDespatchLists.length === 0
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingNumber () {
      return this.flagsDespatchNumber.pending
    },

    isValidateNumber () {
      return this.flagsDespatchNumber.valid &&
                 this.item.despatchNumber !== null &&
                 this.item.despatchNumber !== '' &&
                 !this.isPendingNumber
    },
    isCurrencySame () {
      return this.item.supplier.currencyDefinitionId === this.item.parentStore.currencyDefinitionId
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Stores', [
      'fetchStoreByName'
    ]),

    ...mapActions('Supplier', [
      'searchSupplier',
      'getSuppliers'
    ]),

    ...mapActions('Despatch', [
      'getExchangeRate'
    ]),

    ...mapActions('Refund', [
      'get_REFUND',
      'create_REFUND_HEADER',
      'update_REFUND_HEADER',
      'checkRefundNumber'
    ]),

    ...mapActions('ListsDespatch', [
      'searchDespatchList'
    ]),

    async init () {
      let despatchHeader = this.$route.params.despatchHeader
      if (!despatchHeader) {
        let despatchHeaderId = this.$route.params.id
        await this.get_REFUND(despatchHeaderId).then(res => {
          if (res.status === 200) {
            despatchHeader = res.data.despatchHeader
          }
        })
      }
      this.despatch = despatchHeader
      await this.getSuppliers({pageNumber: 1, pageSize: 999999})
      this.item.supplier = this.Suppliers.find(i => i.id === despatchHeader.supplier.id)
      //this.item.supplier = despatchHeader.supplier
      this.item.parentStore = { id: despatchHeader.parentStoreId, name: despatchHeader.parentStoreName}
      this.item.despatchNumber = despatchHeader.despatchNumber
      this.item.despatchDate = despatchHeader.despatchDate
      this.item.description = despatchHeader.description
      this.item.exchangeRate = despatchHeader.exchangeRate
      if (this.activeCurrency.id !== this.item.supplier.currencyId) {
        this.rateIsoCode = this.activeCurrency.isoCode + '/' + this.item.supplier.currencyIsoCode 
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    focusFirstInput () {
      this.$nextTick(() => this.$refs.firstInput.$refs.search.focus())
    },

    onSubmitDespatchForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return

        if (this.isEdit) {
          await this.submitEdit()
        } else {
          await this.submitNew()
        }
      })
    }, 'submitForm'),

    resetForm () {
      this.selectOptions = []
      this.item = {
        supplier: null,
        parentStore: null,
        despatchDate: new Date(),
        despatchNumber: null,
        description: null,
        exchangeRate: null
      }
    },

    resetListSelection () {
      this.selectedDespatchLists = []
    },

    removeDespatchListFromList (item) {
      this.selectedDespatchLists.splice(this.selectedDespatchLists.indexOf(item), 1)
    },

    getDespatchListOptions (search) {
      const params = {despatchListName: search, supplierIds: [this.item.supplier.id]}
      this.searchDespatchList(params)
        .then(res => {
          if (res.status === 200) {
            this.selectOptionsDespatchList = res.data.despatchListHeaderList
          }
        })
    },

    selectDespatchList (option) {
      const isSelectedBefore = !!this.selectedDespatchLists.some(dl => dl.id === option.id)

      if (!isSelectedBefore) {
        this.selectedDespatchLists.push(option)
      }
      this.item.despatchList = null
    },

    submitNew () {
      // add item
      this.$wait.start('submit')
      let newDespatch = omit(this.newItem, ['id'])
      if (this.selectedDespatchLists.length > 0) newDespatch.despatchListIds = this.selectedDespatchLists.map(item => item.id)

      this.create_REFUND_HEADER(newDespatch).then(res => {
        if (res) {
          const message = this.$t('Despatches.popup_Refund_Notificiation_Created', {
            number: res.data.despatchHeader.despatchNumber
          })
          this.notifyShow({ message })
          if (res.data.despatchDetailList.length === 0) {
            this.$emit('showDrafts')
            this.$emit('getList')
            this.$router.push({
              name: 'RefundDetailNew',
              params: {
                id: res.data.despatchHeader.id
              }
            })
          } else {
            this.$emit('getList', res.data.despatchHeader)
            this.close()
          }
        }
        this.$wait.end('submit')
      })
    },

    submitEdit () {
      // edit item
      const payload = omit(this.newItem, ['supplierId'])
      this.update_REFUND_HEADER(payload).then(res => {
        if (res.status === 200) {
          const message = this.$t('Despatches.popup_Refund_Notificiation_Updated', {
            number: this.newItem.despatchNumber
          })
          this.notifyShow({ message })
          this.close()
          this.$emit('getList')
        }
      })
    },

    getOptions (search) {
      this.searchSupplier(search)
        .then(res => {
          this.selectOptions = res.data.items
        })
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.item.parentStore.id = data.id
        this.item.parentStore.name = data.name
        this.item.parentStore.currencyDefinitionId = data.currencyDefinitionId
        this.item.parentStore.currencyDefinitionIsoCode = data.currencyDefinitionIsoCode
        this.item.parentStore.currencyDefinitionSymbol = data.currencyDefinitionSymbol
      }
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    }
  }
}
</script>

<style lang="scss" scoped>

@import '~@/view/pages/Lists/styles/form-modal.scss';

.input-listing {

  &-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    .item-list {
      display: flex;
      align-items: center;
      background-color: $color-white;
      border: solid 1px $color-gray;
      border-radius: $border-radius;
      margin-top: 10px;
      margin-left: 10px;
      padding-left: 10px;
      .item {
        color: $color-sidebar-dark;
        font-size: $font-size-normal;
      }
      .close {
        color: $color-red;
      }
    }
  }
}
.currency {
  text-align: right;
}
</style>

<template lang="pug">
Popup

    template(slot="title") {{ despatchHeaderTitle }}

    template(slot="detail")
      div.Popup-despatch-info(v-if="!headerIsEmpty")
        div
          Icon(name="icon-calender")
          span {{ despatchDate }}
        div
          Icon(name="icon-popup-despatch-no")
          span {{ despatch.despatchHeader.despatchNumber }}

    template(slot="content")
      form.Form(
      id="form-despatch-detail"
      @submit.prevent="onSubmitDespatchDetailForm")

        .Form-item.required
          label.Form-item-label(:id="`despatches-detail-popup-form-label-store-name`")
            | {{ $t('Despatches.detail_Popup_FormField_StoreName') }}
          .control.form-item-select
            custom-search(
              ref="secondaryInput"
              component-name="despatch-popup-store-select"
              name="storeName",
              v-model="item.storeId",
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')",
              v-validate="'required|is_not:-1|verify_inventory'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :disabled="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive"
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Placeholder')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )

        .Form-item.required
          label.Form-item-label(:id="`despatches-detail-popup-form-label-stock-item-name`") {{ $t('Despatches.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              component-name="form-despatch-detail-stock-item"
              name="stockItem"
              v-model="item.stockItem"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StockItemName')"
              v-validate="'required|verify_inventory'"
              :inputClass="{ 'is-danger': inventoryValidation}"
              ref="firstField"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :loading="isSearchingStockItem"
              :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="fetchStockItems(item)"
            )

            showValidateError(
              v-show="inventoryValidation"
              :errorName="validationErrorMessage"
            )

          .Form-item-info(v-if="showStockDetail")
            div
              .text(:id="`despatches-detail-popup-form-text-stock-item-name-info-base-unit`") {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit') }}
              .value(:id="`despatches-detail-popup-form-value-stock-item-name-info-base-unit`") {{ item.stockItem.baseUnitName }}
            div
              .text(:id="`despatches-detail-popup-form-text-stock-item-name-info-tax`") {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoTax') }}
              .value(:id="`despatches-detail-popup-form-value-stock-item-name-info-tax`")
                select.tax(
                name="taxId"
                :class="{ 'is-danger': veeErrors.has('taxId') }"
                v-validate="'required|is_not:-1'"
                :id="`select-despatch-popup`"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Tax')"
                v-model="item.taxId")
                  option(value="-1" id="option-despatch-popup-unselected") {{ $t('Despatches.detail_Popup_FormField_TaxId_Unselected') }}
                  option(
                  v-for="Tax in Taxes"
                  :key="Tax.id"
                  :value="Tax.id"
                  :id="`option-despatch-popup-${Tax.id}`"
                  ) {{ '%' + Tax.ratio + ' - '+ Tax.name }}

            showValidateError(
              v-show="veeErrors.has('taxId')"
              :errorName="veeErrors.first('taxId')"
            )

        .Form-item.required.row-field
          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-quantity`") {{ $t('Despatches.detail_Popup_FormField_Quantity') }}
            .control
              input.txt.no-spinners(
              name="quantity"
              autocomplete="off"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
              v-model.number="item.quantity"
              @change="reCalculate('quantity')"
              v-validate="checkPermission('IgnoreQuantityDeviation') === false ? {required: true, min_value: item.minQuantityDeviationResult ? item.minQuantityDeviationResult : 0, max_value: item.maxQuantityDeviationResult ? item.maxQuantityDeviationResult : 999999999999} : {min_value: 0, max_value: 99999999999, multiplyTwoField: item.unitPrice, required: true}"

              :class="{ 'is-danger': veeErrors.has('quantity') }"
              id="despatches-detail-popup-form-input-quantity"
              type="number"
              step="any")

              showValidateError(
                v-show="veeErrors.has('quantity')"
                :errorName="veeErrors.first('quantity')"
              )

              .Form-item-info(v-if="item || stockItemInfo")
                div
                  .text {{ $t('Despatches.detail_Popup_FormField_StockOnHand') }}
                  .value {{ item.stockOnHandQuantity || stockItemInfo.baseUnitQuantity }}
          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-base-unit`")
              | {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
            .control.form-item-select
              Icon(name="icon-down-arrow")
              select.select(
              id="select-despatch-popup-baseunit"
              name="unitId"
              v-model="item.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_BaseUnit')"
              :disabled="isEdit"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
                option(
                v-if="!(isFinalized && isEdit)"
                value="-1"
                id="option-despatches-popup-baseunit-unselected") {{ $t('Despatches.detail_Popup_FormField_BaseUnit_Unselected') }}
                option(
                v-for="unit in filteredUnitList"
                :key="unit.id"
                :value="unit.id"
                :id="`option-despatches-popup-baseunit-${unit.name}`"
                ) {{ unit.name }}

              showValidateError(
                v-show="veeErrors.has('unitId')"
                :errorName="veeErrors.first('unitId')"
              )

            .Form-item-info(v-if="calculateBaseQuantity")
              span.text {{ $t('Despatches.detail_Popup_FormField_BaseUnit_CalculatedText') }}
              span.value {{ $t('Despatches.detail_Popup_FormField_BaseUnit_CalculatedValue', { value: calculateBaseQuantity, unit: baseQuantityUnit }) }}
                    
        .Form-item.row-field(:class="isPriceAvailable ? 'required' : ''")
          .item 
            label.Form-item-label(:id="`despatches-detail-popup-form-label-net-unit-price`") {{ $t('Despatches.detail_Popup_FormField_NetUnitPrice') }}
            .control.tooltip-relative
              currency-input.txt(
              name="unitPrice"
              :disabled="isSupplierNotPermissionToGlobal || !isPriceAvailable || priceType === 2"
              :class="{ 'is-danger': veeErrors.has('unitPrice') }"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_NetUnitPrice')"
              v-model="item.unitPrice"
              @change.native="reCalculate('unitPrice')"
              id="despatches-detail-unit-price"
              v-validate="checkPermission('IgnorePriceDeviation') === false ? {required: isPriceAvailable, min_value: item.minPriceDeviationResult ? item.minPriceDeviationResult : 0, max_value: item.maxPriceDeviationResult ? item.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [item.quantity, item.netAmount], required: isPriceAvailable} : {greater_than:0, required: true, multiplyTwoField: [item.quantity, item.netAmount]}"
            )

              .currency-symbol(:class="isSupplierNotPermissionToGlobal || !isPriceAvailable || priceType === 2 ? 'currency-symbol-faded' : ''") {{ currencySymbol }}

              showValidateError(
                v-show="veeErrors.has('unitPrice')"
                :errorName="veeErrors.first('unitPrice')"
              )
              tooltip.small(
                v-if="priceType === 2"
                white,
                top,
                :text="$t('Despatches.priceTypeWarning')")

          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-net-amount`") {{ $t('Despatches.detail_TableColumn_NetAmount') }}
            .control.tooltip-relative
              currency-input.txt.currency-input(
              name="netAmount"
              :disabled="isSupplierNotPermissionToGlobal || !(+item.quantity) || !isPriceAvailable || priceType === 2"
              :class="{ 'is-danger': veeErrors.has('netAmount') }"
              :data-vv-as="$t('Despatches.detail_TableColumn_NetAmount')"
              v-model="item.netAmount"
              @change.native="reCalculate('netAmount')"
              id="despatches-detail-unit-price"
              v-validate="'greater_than:-0.001'")

              .currency-symbol(:class="isSupplierNotPermissionToGlobal || !(+item.quantity) || !isPriceAvailable || priceType === 2 ? 'currency-symbol-faded' : ''") {{ currencySymbol }}
              
              tooltip.small(
                v-if="priceType === 2"
                white,
                top,
                :text="$t('Despatches.priceTypeWarning')")

              showValidateError(
                v-show="veeErrors.has('netAmount')"
                :errorName="veeErrors.first('netAmount')"
              )

 
        .Form-item-info(v-if="calculateBasePrice")
          span.text {{ $t('Despatches.detail_Popup_FormField_NetUnitPrice_Calculated') }}
          span.value {{currencySymbol }} {{ calculateBasePrice | formatCurrency}}

        .Form-item.discount-field
          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-discount-1`") {{ $t('Despatches.detail_Popup_FormField_Discount1') }}
            .control.tooltip-relative
              customNumberInput(
                id="despatches-detail-discount1"
                name="discount1"
                :isTypePercentage="true"
                :error="veeErrors.has('discount1')"
                placeholder=",00"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount1')"
                v-validate="'greater_than:-1|max_value:100'"
                v-model="item.discount1"
                :disabled="isSupplierNotPermissionToGlobal || !isPriceAvailable || priceType === 2"
              )

              tooltip.small(
                v-if="priceType === 2"
                white,
                top,
                :text="$t('Despatches.priceTypeWarning')")

              showValidateError(
                v-show="veeErrors.has('discount1')"
                :errorName="veeErrors.first('discount1')"
              )

            p.Form-item-help.is-danger(
            v-show="!discountControl"
            ) {{ $t('Despatches.discountControl_Message') }}

          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-discount-2`") {{ $t('Despatches.detail_Popup_FormField_Discount2') }}
            .control.tooltip-relative
              customNumberInput(
                id="despatches-detail-discount2"
                name="discount2"
                :error="veeErrors.has('discount2')"
                :isTypePercentage="true"
                placeholder=",00"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount2')"
                v-validate="'greater_than:-1|max_value:100'"
                v-model="item.discount2"
                :disabled="(isEmptyDiscount1 || +(item.discount1) === 0) || isSupplierNotPermissionToGlobal || !isPriceAvailable || priceType === 2"
              )

              tooltip.small(
                v-if="priceType === 2"
                white,
                top,
                :text="$t('Despatches.priceTypeWarning')")

              showValidateError(
                v-show="veeErrors.has('discount2')"
                :errorName="veeErrors.first('discount2')"
              )

      .Form-item(v-if="!isEmptyDiscount1")
        .Form-item-info-multiple
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_DiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ discountedunitPrice | formatCurrency }}
          .Form-item-info-multiple-row
            .text {{ $t('Despatches.detail_Popup_FormField_TotalDiscountedBaseUnitPrice_CalculatedText') }}
            .value {{ unitPriceLineTotal | formatCurrency }}

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-despatch-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Despatches.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button(
      id="btn-despatch-detail-popup-submit"
      type="submit"
      primary
      variant="full"
      size="medium"
      form="form-despatch-detail"
      :disabled="isLoading || (isSupplierNotPermissionToGlobal && item.unitPrice === 0) || inventoryValidation")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Despatches.detail_Popup_FinishEditButton') }}
          template(v-else) {{ $t('Despatches.detail_Popup_FinishCreateButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CheckInventoryMixin from '@/mixins/validateInventory'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'
import disableSubmit from '@/mixins/disable-submit-button'
import moment from 'moment'
import { getObjectValue, isEmpty, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DespatchDetailForm',

  mixins: [CheckInventoryMixin, getQuantityFromBarcode, disableSubmit],

  props: {
    status: {
      type: String,
      default: 'new'
    },
    documentEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      despatch: {
        despatchHeader: null,
        despatchDetail: null
      },

      selectOptions: [],
      selectMiniStoreList: [],
      stockItem: {},

      item: {
        stockItem: null,
        baseUnitName: null,
        taxId: '-1',
        quantity: null,
        unitId: '-1',
        unitPrice: 0,
        storeId: {
          id: '-1',
          name: ''
        },
        discount1: null,
        discount2: null,
        netAmount: 0
      },

      saveAndNew: true,
      discountControl: true,
      isSupplierGlobal: false,
      isPriceAvailable: false,
      currencySymbol: null,
      priceType: null,
      stockItemInfo: {}
    }
  },

  created () {
    this.setRoleArea()
  },

  async mounted () {
    this.pricePermission.find(i => {
      if (i.permission === 70) this.isPriceAvailable = true
    })
    if (this.isEdit) {
      this.priceType = this.$route.params.despatchDetail.priceType
      let despatchDetail
      // get detail
      let detailId = this.$route.params.detailId
      await this.get_DESPATCH_DETAIL(detailId).then(res => {
        if (res.status === 200) {
          despatchDetail = res.data.despatchDetail
          this.fetchStockItems(despatchDetail)
        }
      })

      this.despatch.despatchDetail = despatchDetail
      // get header
      await this.get_DESPATCH(despatchDetail.despatchHeaderId).then(res => {
        if (res.status === 200) {
          this.despatch.despatchHeader = res.data.despatchHeader
        }
      })
      this.item = despatchDetail
      if (this.Taxes.find(element => element.id === despatchDetail.taxId)) {
        this.item.taxId = despatchDetail.taxId
      }
      this.getMiniStoreList()
      this.get_PRICE_QUOTE({
        DespatchHeaderId: this.despatch.despatchHeader.id,
        StockItemId: this.item.stockItem.id,
        UnitId: this.item.unitId
      }).then(res => {
        this.isSupplierGlobal = res.data.priceQuote.isSupplierGlobal
      })
    } else {
      let despatchHeader = this.$route.params.despatchHeader
      if (!despatchHeader) {
        let despatchHeaderId = this.$route.params.id
        await this.get_DESPATCH(despatchHeaderId).then(res => {
          if (res) {
            despatchHeader = res.data.despatchHeader
            if (this.tenantMultiCurrencyIsActive || this.tenantMustHaveSingleStoreInSameDespatchActive) {
             this.item.storeId = {
                id: despatchHeader.parentStoreId,
                name: despatchHeader.parentStoreName
              }
            }
            else {
              this.fetchUser().then(res => {
                this.item.storeId = res.status === 200 ? { id: res.data.user.defaultStoreId, name: res.data.user.defaultStoreName }  : -1
              }) // set default store in selectbox
            }
          }
        })
      } else {
        if (this.tenantMultiCurrencyIsActive || this.tenantMustHaveSingleStoreInSameDespatchActive) this.item.storeId = { id:despatchHeader.parentStoreId, name: despatchHeader.parentStoreName }    
        else {
          this.fetchUser().then(res => {
            this.item.storeId = res.status === 200 ? { id: res.data.user.defaultStoreId, name: res.data.user.defaultStoreName } : -1
          }) // set default store in selectbox
        }
      }
      this.despatch.despatchHeader = despatchHeader
      this.getMiniStoreList()
      this.formFocus()
    }
    this.currencySymbol = this.despatch.despatchHeader.currencyDefinitionSymbol
  },

  watch: {
    async 'item.stockItem' (val) {
      if (this.isEdit || !val) return
      this.stockItem = val
      this.priceType = this.stockItem.priceType
      this.setUnit(val.unitId)
      // Calling watch unitId fonksiyon
    },
    async 'item.unitId' () {
      if (this.isEdit) return
      await this.getPriceQuote()
    },
    'isEmptyDiscount1' () {
      if (this.isEdit && this.isEmptyDiscount1) {
        this.item.discount2 = null
      }
      if ((this.isEmptyDiscount1 || Number(this.item.discount1) === 0) && !this.isEmptyDiscount2) {
        this.discountControl = false
      } else {
        this.discountControl = true
      }
    }
  },

  computed: {
    ...mapGetters('Tax', [
      'Taxes'
    ]),

    ...mapGetters('Settings', [
      'RoleArea', 'pricePermission', 'checkPermission', 'tenantMultiCurrencyIsActive','tenantMustHaveSingleStoreInSameDespatchActive'
    ]),

    ...mapGetters('Units', [
      'findUnit',
      'getRelatedUnitList'
    ]),

    ...mapGetters('Stores', [
      'miniStoreList'
    ]),

    isBarcodeSearch () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
    },

    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    directSelectOption () {
      return this.isBarcodeSearch
        ? this.selectOptions[0]
        : {}
    },

    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },

    validationErrorMessage () {
      return this.veeErrors.has('storeName')
        ? this.veeErrors.first('storeName')
        : this.veeErrors.has('stockItem')
          ? this.veeErrors.first('stockItem')
          : ''
    },

    checkInventoryParams () {
      /*
        *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
        */
       const storeId = this.item.storeId
      return {
        storeId: typeof storeId === 'object' ? storeId.id : storeId ,
        inventoryDate: this.despatch.despatchHeader ? this.despatch.despatchHeader.despatchDate : null,
        stockItemIdList: this.item.stockItem ? [this.item.stockItem.id] : null
      }
    },

    despatchDate () {
      return moment(this.despatch.despatchHeader.despatchDate).format('DD.MM.YYYY')
    },

    isEmptyDiscount1 () {
      return this.item.discount1 === null || !Number(this.item.discount1) || this.item.discount1 < 0
    },

    isEmptyDiscount2 () {
      return this.item.discount2 === null || !this.item.discount2 || this.item.discount2 < 0
    },

    filteredUnitList () {
      if (!this.showStockDetail) return []
      if (this.isFinalized && this.isEdit) {
        return [this.findUnit(this.item.unitId)]
      }
      const baseUnitId = this.isEdit
        ? this.findUnit(this.item.unitId).baseUnitId
        : this.findUnit(this.item.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    newItem () {
      return {
        despatchHeaderId: getObjectValue('despatchHeader.id', this.despatch),
        stockItemId: this.item.stockItem.id,
        discount1: this.item.discount1 || null,
        discount2: this.item.discount2 || null,
        quantity: this.item.quantity,
        storeId:  this.item.storeId.id,
        taxId: this.item.taxId,
        unitId: this.item.unitId,
        unitPrice: this.item.unitPrice
      }
    },

    newEdit () {
      return {
        id: getObjectValue('despatchDetail.id', this.despatch),
        discount1: this.item.discount1 || null,
        discount2: this.item.discount2 || null,
        isOrderCompleted: this.item.isOrderCompleted,
        quantity: this.item.quantity,
        storeId: this.item.storeId.id,
        taxId: this.item.taxId,
        unitId: this.item.unitId,
        unitPrice: this.item.unitPrice
      }
    },

    isLoading () {
      return this.isDisabledSubmit || this.$wait.is(['update_DESPATCH_DETAIL', 'create_DESPATCH_DETAIL', 'checkInventoryAll', 'onSubmitDespatchDetailFormSubmitLoading'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isDocumentEdit () {
      return this.documentEdit === true
    },

    isFinalized () {
      return !this.headerIsEmpty && this.despatch.despatchHeader.status === 2
    },

    headerIsEmpty () {
      return isEmpty(this.despatch.despatchHeader)
    },

    despatchHeaderTitle () {
      if (this.headerIsEmpty) return ''
      return this.despatch.despatchHeader.supplier.name
    },

    baseUnit () {
      return this.findUnit(this.item.unitId)
    },

    calculateBaseQuantity () {
      const valid = this.item.unitId !== '-1' &&
                      this.item.quantity &&
                      !this.veeErrors.has('unitId') &&
                      !this.veeErrors.has('quantity')
      if (!valid) return false

      const unit = this.baseUnit
      const value = this.item.quantity * unit.baseQuantity
      if (parseInt(value, 10) !== value) {
        return value.toString().slice(0, 5) // "integer" + "." + "precision:3" = 5 chars
      }
      return value
    },

    baseUnitPrice () {
      return this.item.unitPrice / this.baseUnit.baseQuantity
    },

    baseUnitPrice4Discount1 () {
      return this.baseUnitPrice * (1 - this.item.discount1 / 100)
    },

    baseUnitPrice4Discount2 () {
      return this.baseUnitPrice4Discount1 * (1 - this.item.discount2 / 100)
    },

    baseQuantityUnit () {
      const unit = this.findUnit(this.item.unitId)
      return unit.baseUnitName
    },

    calculateBasePrice () {
      const valid = this.item.unitId !== '-1' &&
                      this.item.unitPrice &&
                      !this.veeErrors.has('unitId')
      if (!valid) return false
      let value
      if (this.isEmptyDiscount1) {
        value = this.baseUnitPrice
      } else {
        if (this.isEmptyDiscount2) {
          value = this.baseUnitPrice4Discount1
        } else {
          value = this.baseUnitPrice4Discount2
        }
      }
      return value
    },

    unitPriceWithDiscount1 () {
      return this.item.unitPrice * (1 - this.item.discount1 / 100)
    },

    unitPriceWithDiscount2 () {
      return this.unitPriceWithDiscount1 * (1 - this.item.discount2 / 100)
    },

    discountedunitPrice () {
      let value
      if (this.isEmptyDiscount1) {
        value = this.item.unitPrice
      }
      if (this.isEmptyDiscount2) {
        value = this.unitPriceWithDiscount1
      } else {
        value = this.unitPriceWithDiscount2
      }
      return value
    },

    unitPriceLineTotal () {
      return this.discountedunitPrice * this.item.quantity
    },

    showStockDetail () {
      if (this.item.stockItem) return this.item.stockItem.hasOwnProperty('id')
    },

    isSupplierNotPermissionToGlobal () {
      return this.RoleArea === 'local' && this.isSupplierGlobal
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Settings', [
      'setRoleArea',
      'fetchUser'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH',
      'get_DESPATCH_DETAIL',
      'create_DESPATCH_DETAIL',
      'update_DESPATCH_DETAIL',
      'get_PRICE_QUOTE'
    ]),

    ...mapActions('Transfer', [
      'getStockInfo'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList',
      'fetchStoreByName'
    ]),

    ...mapMutations('Despatch', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'UPDATE_DESPATCH_ITEM'
    ]),

    async fetchStockItems (despatch) {
      const payload = {
        storeId: this.isEdit ? despatch.storeId : despatch.storeId.id,
        stockItemId: despatch.stockItem.id,
        unitId: despatch.stockItem.baseUnitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },

    reCalculate (areaName) {
      if ((areaName === 'quantity' || areaName === 'unitPrice')) this.item.netAmount = this.item.unitPrice * this.item.quantity
      if (areaName === 'netAmount') this.item.unitPrice = this.item.netAmount / this.item.quantity
    },

    formFocus () {
      this.$nextTick(() => this.$refs.firstField.$refs.search.focus())
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    resetForm (overrides = {}) {
      this.selectOptions = []
      this.stockItem = {}
      this.item = {
        stockItem: overrides.stockItem || null,
        baseUnitName: overrides.baseUnitName || null,
        taxId: overrides.taxId || '-1',
        quantity: overrides.quantity || null,
        unitId: overrides.unitId || '-1',
        unitPrice: overrides.unitPrice || 0,
        storeId: { id: overrides.storeId.id, name: overrides.storeId.name } || '-1',
        discount1: overrides.discount1 || null,
        discount2: overrides.discount2 || null,
        netAmount: overrides.discount2 || 0
      }
      this.formFocus()
    },

    setTaxId (id = this.stockItem.taxId) {
      this.item.taxId = id || -1
    },

    async getPriceQuote () {
      const payload = {
        DespatchHeaderId: this.despatch.despatchHeader.id,
        StockItemId: this.item.stockItem.id,
        UnitId: this.item.unitId
      }
      this.item.discount1 = null
      this.item.discount2 = null
      this.item.unitPrice = 0
      this.setTaxId()

      this.get_PRICE_QUOTE(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data.priceQuote !== null) {
              this.item.discount1 = res.data.priceQuote.discount1 || null
              this.item.discount2 = res.data.priceQuote.discount2 || null
              this.item.baseUnitPrice = res.data.priceQuote.baseUnitPrice || 0
              this.item.unitPrice = res.data.priceQuote.unitPrice || 0
              this.isSupplierGlobal = res.data.priceQuote.isSupplierGlobal
            } else {
              this.discountControl = true
              this.item.baseUnitPrice = 0
            }
          }
        })
        .then(() => {
          if (this.item.quantity) this.reCalculate('unitPrice')
        })
    },

    setUnit (unitId) {
      this.item.unitId = unitId
    },

    onSubmitDespatchDetailForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async valid => {
        if (!valid || !this.discountControl) return false
        else {
          if (this.isEdit) {
            await this.submitEdit()
          } else {
            await this.submitNew()
          }
        }
      })
    }, 'onSubmitDespatchDetailFormSubmitLoading'),

    async submitNew () {
      await this.create_DESPATCH_DETAIL(this.newItem).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: this.item.stockItem.name })
          this.notifyShow({ message })
          await this.get_DESPATCH(res.data.despatchDetail.despatchHeaderId).then(res => {
            if (res.status === 200) {
              this.UPDATE_DESPATCH_ITEM(res.data.despatchHeader)
              this.SHOW_DETAIL(res.data)
            }
          })
          if (this.saveAndNew) {
            this.resetForm({
              storeId: {
                id: this.item.storeId.id,
                name: this.item.storeId.name
              },
            })
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.close()
          }
        }
      })
    },

    async submitEdit () {
      await this.update_DESPATCH_DETAIL(this.newEdit)
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Updated', { name: this.item.stockItem.name })
            this.notifyShow({ message })
            this.get_DESPATCH(this.despatch.despatchHeader.id).then(res => {
              if (res.status === 200) {
                this.UPDATE_DESPATCH_ITEM(res.data.despatchHeader)
                if (!this.isDocumentEdit) this.SHOW_DETAIL(res.data)
              }
            })
            this.close()
            if (this.isDocumentEdit) this.$emit('getList', this.despatch.despatchHeader.id)
          }
          this.enableSubmitButton()
        })
    },

    getOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.searchStockItem({Text: decoratedValue, types: [1, 2, 4]})
        .then(res => {
          this.selectOptions = res.data.items
          if (this.isBarcodeSearch) {
            const baseUnitId = res.data.items[0].baseUnitId
            const parsedBarcode = this.getQuantityFromBarcode(search, baseUnitId)
            if (parsedBarcode.isWeighted) this.item.quantity = parsedBarcode.quantity
          }
        })
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },

    inputSelection (data) {
      if(data.length !== 0) {
        this.item.storeId.id = data.id
        this.item.storeId.name = data.name
      }
    },

    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },
  }
}
</script>

<style lang="scss">

  .Popup-despatch-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;

    div {
      margin-right: 30px;

      svg {
        color: $color-success;
        margin-right: 5px;
      }
    }
  }

  .Form-item-info {
    color: $color-text-light;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    font-size: $font-size-small;

    > div {
      display: flex;
      margin-right: 20px;
      align-items: center;

      select {
        margin-bottom: -2px;
        border: 0;
        background-color: $color-gray;
      }
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

  .Form-item-info-multiple {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    flex-wrap: wrap;
    margin-top: 17px;
    &-row {
      display: flex;
      justify-content: space-between;
      border: solid $color-gray 1px;
      width: 100%;
      padding: 17px 10px;
    }

    &-row:first-child {
       border-bottom: none;
     }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }

.row-field{
  margin-top: 17px;
  display: flex;
  .item {
    &:first-child {
      margin-right: 10px;
    }
    .txt{
      text-align: end;
    }
    .control .select {
      min-width: 184px;
      
    }
  }
}

.discount-field{
  margin-top: 17px;
  display: flex;
  .item {
    &:first-child {
      margin-right: 10px;
    }
  }
}

select.tax {
  max-width: 50px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _vm.isMultiple
        ? _c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.supplierName))])
        : _c("template", { slot: "title" }, [
            _vm._v(
              _vm._s(
                !_vm.despatch.despatchHeader.supplier
                  ? "-"
                  : _vm.despatch.despatchHeader.supplier.name
              )
            ),
          ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch-finalize" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchFinalizeForm.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isInvoice,
                    expression: "isInvoice",
                  },
                ],
                staticClass: "Form-item",
              },
              [
                _c("div", { staticClass: "Form-item-info-multiple" }, [
                  _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.Finalize_NetTotalAmount"))
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrency")(_vm.calculateNetTotalAmount)
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.Finalize_TaxTotalAmount"))
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrency")(_vm.calculateTaxTotalAmount)
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-item-info-multiple-row" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.Finalize_GrossTotalAmount"))
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.calculateGrossTotalAmount
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            !_vm.isMultiple
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "finalize-invoice-type" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "finalize-invoice-type-radio no-bottom-border",
                        },
                        [
                          _c("CustomRadio", {
                            staticClass: "finalize-invoice-type-radio-button",
                            attrs: {
                              id: `radio-despatch-invoice-type-invoice-1`,
                              label: _vm.$t(
                                "Despatches.Payment_isPassive_Text"
                              ),
                              checked: !_vm.isInvoice,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeInvoiceType(false)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "finalize-invoice-type-radio" },
                        [
                          _c("CustomRadio", {
                            staticClass: "finalize-invoice-type-radio-button",
                            attrs: {
                              id: `radio-despatch-invoice-type-invoice-2`,
                              label: _vm.$t("Despatches.Payment_isActive_Text"),
                              checked: _vm.isInvoice,
                              disabled: _vm.isEInvoicePayer,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeInvoiceType(true)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.finalize_Popup_FormField_invoiceNumber"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.invoiceNumber,
                            expression: "item.invoiceNumber",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:32|verify_invoiceNumber",
                            expression:
                              "'required|max:32|verify_invoiceNumber'",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("invoiceNumber"),
                        },
                        attrs: {
                          name: "invoiceNumber",
                          autocomplete: "off",
                          "data-vv-as": _vm.$t(
                            "Despatches.finalize_Popup_FormField_invoiceNumber"
                          ),
                          "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                          id: "input-finalize-despatch-invoice-number",
                        },
                        domProps: { value: _vm.item.invoiceNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.item,
                              "invoiceNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPendingInvoiceNumber,
                              expression: "isPendingInvoiceNumber",
                            },
                          ],
                          attrs: {
                            id: "btn-recipes-popup-loading-code",
                            type: "button",
                            variant: "icon loading",
                            tabindex: "-1",
                          },
                        },
                        [_c("Loading", { attrs: { theme: "disable" } })],
                        1
                      ),
                      _c("Button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isValidateInvoiceNumber,
                            expression: "isValidateInvoiceNumber",
                          },
                        ],
                        attrs: {
                          id: "btn-recipes-popup-ok-code",
                          type: "button",
                          variant: "icon check",
                          tabindex: "-1",
                          iconName: "icon-check",
                          iconClass: "icon-check",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("invoiceNumber"),
                          expression: "veeErrors.has('invoiceNumber')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("invoiceNumber")))]
                  ),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("div", { staticClass: "invoice-type" }, [
                    _c(
                      "div",
                      { staticClass: "invoice-type-radio" },
                      [
                        _c("CustomRadio", {
                          staticClass: "popup-item-check",
                          attrs: {
                            id: "radio-finalize-e-invoice-type",
                            label: _vm.$t("Despatches.eInvoice"),
                            checked: _vm.isElectronicInvoice,
                          },
                          on: { change: _vm.changeToElectronicInvoice },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Despatches.Invoice_Date"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("CustomDatepicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        class: {
                          "is-danger": _vm.veeErrors.has(`invoiceDate`),
                        },
                        attrs: {
                          name: "invoiceDate",
                          "data-vv-as": _vm.$t("Despatches.Invoice_Date"),
                          pickerType: "manuel",
                          inputIconRight: "icon-datepicker-big",
                          inputIcon: "",
                          disabledStartDate: _vm.invoiceDateDisabled,
                        },
                        model: {
                          value: _vm.item.invoiceDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "invoiceDate", $$v)
                          },
                          expression: "item.invoiceDate",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.finalize_Popup_FormField_invoiceGrossTotal"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("currency-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value:
                              _vm.item.invoiceGrossTotal ===
                              _vm.calculateGrossTotalAmount
                                ? "required|verify_gross"
                                : "required|verify_rounding_tolerance",
                            expression:
                              "item.invoiceGrossTotal === calculateGrossTotalAmount ? 'required|verify_gross' : 'required|verify_rounding_tolerance'",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("invoiceGrossTotal"),
                        },
                        attrs: {
                          name: "invoiceGrossTotal",
                          "data-vv-as": _vm.$t(
                            "Despatches.finalize_Popup_FormField_invoiceGrossTotal"
                          ),
                          id: "input-currency-finalize-despatch-invoice-gross-total",
                        },
                        model: {
                          value: _vm.item.invoiceGrossTotal,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "invoiceGrossTotal", $$v)
                          },
                          expression: "item.invoiceGrossTotal",
                        },
                      }),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("invoiceGrossTotal"),
                              expression: "veeErrors.has('invoiceGrossTotal')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.veeErrors.first("invoiceGrossTotal"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item-info" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.finalize_Popup_FormField_roundingTolerance"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.roundingTolerance)),
                  ]),
                ])
              : _vm._e(),
            _vm.isInvoice
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Despatches.finalize_Popup_FormField_invoiceType"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "payment-type" }, [
                    _c(
                      "div",
                      { staticClass: "payment-type-radio" },
                      [
                        _c("CustomRadio", {
                          staticClass: "popup-item-check",
                          attrs: {
                            id: "radio-finalize-payment-type-cash",
                            label: _vm.$t("Despatches.PaymentTypeCash"),
                            checked: _vm.isCash,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changePaymentType(true)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "payment-type-radio" },
                      [
                        _c("CustomRadio", {
                          staticClass: "popup-item-check",
                          attrs: {
                            id: "radio-finalize-payment-type-future",
                            label: _vm.$t("Despatches.PaymentTypeFuture"),
                            checked: !_vm.isCash,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changePaymentType(false)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.showPaymentTypeError
                    ? _c("p", { staticClass: "Form-item-help is-danger" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Despatches.paymentTypeErrorMessage"))
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.isInvoice && _vm.isCash
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Despatches.paymentTypeList"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.paymentOptionId,
                              expression: "item.paymentOptionId",
                            },
                          ],
                          staticClass: "select",
                          attrs: {
                            id: "select-despatch-invoice-popup-payment-list",
                            name: "paymentList",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.item,
                                "paymentOptionId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                id: "option-despatches-invoice-popup-paymentlist-unselected",
                              },
                              domProps: { value: null },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Supplier.detail_Popup_FormField_SupplierGroups_Unselected"
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._l(_vm.paymentList, function (group) {
                            return _c(
                              "option",
                              {
                                key: group.id,
                                attrs: {
                                  id: `option-suppliers-popup-group-name-${group.name}`,
                                },
                                domProps: { value: group.id },
                              },
                              [_vm._v(_vm._s(group.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isInvoice,
                    expression: "isInvoice",
                  },
                ],
                staticClass: "Form-item required",
              },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Despatches.Payment_Date"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _vm.isCash || _vm.isCash === null
                      ? _c(
                          "div",
                          { staticClass: "fake-datepicker" },
                          [
                            _c("Icon", {
                              attrs: { name: "icon-datepicker-big" },
                            }),
                            _c("input", {
                              staticClass: "fake-payment-date txt",
                              attrs: { disabled: "" },
                              domProps: { value: _vm.fakePaymentDate },
                            }),
                          ],
                          1
                        )
                      : _c("CustomDatepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          class: {
                            "is-danger": _vm.veeErrors.has(`invoiceDate`),
                          },
                          attrs: {
                            name: "paymentDate",
                            "data-vv-as": _vm.$t("Despatches.paymentDate"),
                            pickerType: "manuel",
                            inputIconRight: "icon-datepicker-big",
                            inputIcon: "",
                            disabledStartDate: _vm.PaymentDateDisabled,
                          },
                          model: {
                            value: _vm.item.paymentDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "paymentDate", $$v)
                            },
                            expression: "item.paymentDate",
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
            _vm.isInvoice
              ? _c(
                  "div",
                  { staticClass: "Form-item" },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Global.description"))),
                    ]),
                    _c("customTextareaInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:128",
                          expression: "'max:128'",
                        },
                      ],
                      ref: "descriptionInput",
                      attrs: {
                        name: "description",
                        "data-vv-as": _vm.$t("Global.description"),
                        error: _vm.veeErrors.has("description"),
                        rows: "3",
                      },
                      model: {
                        value: _vm.item.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "description", $$v)
                        },
                        expression: "item.description",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("description"),
                          expression: "veeErrors.has('description')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("description") },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-detail-popup-submit",
                type: "submit",
                primary: "",
                variant: "full",
                size: "medium",
                form: "form-despatch-finalize",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  !_vm.isInvoice
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FinishFinalizeCreateButtonType1"
                            )
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Despatches.detail_Popup_FinishFinalizeCreateButtonType2"
                            )
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template lang="pug">
Popup
    template(slot="title") {{ $route.params.despatchHeader.supplier.name }}

    template(slot="content")
      form.Form(
      id="form-despatch-detail"
      @submit.prevent="onSubmitDespatchDetailForm")
        .Form-item
          label.Form-item-label(:id="`despatches-detail-popup-form-label-store-name`")
            | {{ $t('Despatches.detail_Popup_FormField_StoreName') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="despatch-detail-multiple-store-select"
              name="storeName",
              v-model="form.storeId",
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')",
              v-validate="'required'",
              :inputClass="{ 'is-danger': veeErrors.has('storeSelect') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              label="name",
              :disabled="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive"
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.popup_FormField_StoreName_Placeholder')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeSelect')"
              :errorName="veeErrors.first('storeSelect')"
            )

        .Form-item
          label.Form-item-label(:id="`despatches-detail-popup-form-label-taxes`")
            | {{ $t('Despatches.detail_Popup_FormField_Tax') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            customSelectInput(
              name="tax"
              :optionData="Taxes"
              v-model="form.taxId"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Tax')"
              id="option-despatches-popup-tax"
              optionIdName="option-despatches-popup-store-name"
            )

            showValidateError(
              v-show="veeErrors.has('tax')"
              :errorName="veeErrors.first('tax')"
            )

        .Form-item.discount-field
          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-discount-1`") {{ $t('Despatches.detail_Popup_FormField_Discount1') }}
            .control.tooltip-relative
              customNumberInput(
                id="despatches-detail-discount1"
                name="discount1"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount1')"
                :placeholder="$t('Despatches.popupForm_Field_Discount1_Placeholder')"
                :error="veeErrors.has('discount1')"
                v-validate="'greater_than:-1|max_value:100'"
                :disabled="priceTypeControl"
                v-model="form.discount1"
              )

              showValidateError(
                v-show="veeErrors.has('discount1')"
                :errorName="veeErrors.first('discount1')"
              )

              tooltip.small(
                v-if="priceTypeControl"
                white,
                bottom,
                :text="$t('Despatches.priceTypeWarningMultiple')")

          .item
            label.Form-item-label(:id="`despatches-detail-popup-form-label-discount-2`") {{ $t('Despatches.detail_Popup_FormField_Discount2') }}
            .control.tooltip-relative
              customNumberInput(
                name="discount2"
                id="despatches-detail-discount2"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount2')"
                :placeholder="$t('Despatches.popupForm_Field_Discount2_Placeholder')"
                :error="veeErrors.has('discount2')"
                v-validate="'greater_than:-1|max_value:100'"
                v-model="form.discount2"
                :disabled="!form.discount1 || + (form.discount1) === 0 || priceTypeControl"
              )

              showValidateError(
                v-show="veeErrors.has('discount2')"
                :errorName="veeErrors.first('discount2')"
              )

              tooltip.small(
                v-if="priceTypeControl"
                white,
                bottom,
                :text="$t('Despatches.priceTypeWarningMultiple')")

    template(slot="footer")
      Button(
        id="btn-despatch-detail-popup-submit"
        type="submit"
        primary
        variant="full"
        size="medium"
        form="form-despatch-detail"
        :disabled="isLoading")
        span(v-show="!isLoading")
          template {{ $t('Despatches.detail_Popup_FinishEditButton') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'form-detail-multiple-despatch',

  data () {
    return {
      form: {
        despatchDetailIds: [],
        storeId: null,
        taxId: null,
        discount1: null,
        discount2: null
      },
      selectMiniStoreList: [],
    }
  },

  mounted () {
    if (this.$route.params.despatchDetails) {
      this.$route.params.despatchDetails.map(item => {
        this.form.despatchDetailIds.push(item.id)
      })
      if (this.tenantMultiCurrencyIsActive || this.tenantMustHaveSingleStoreInSameDespatchActive) this.form.storeId = { id: this.$route.params.despatchHeader.parentStoreId, name: this.$route.params.despatchHeader.parentStoreName }
      this.getMiniStoreList()
    } else {
      this.close()
    }
  },

  computed: {
    ...mapGetters('Tax', [
      'Taxes'
    ]),
    ...mapGetters('Settings', ['tenantMultiCurrencyIsActive','tenantMustHaveSingleStoreInSameDespatchActive']),

    isLoading () {
      return this.$wait.is(['getMiniStoreList', 'submit'])
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
    priceTypeControl () {
      return !!this.$route.params.despatchDetails.find(i => i.priceType === 2)
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList',
      'fetchStoreByName'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH',
      'update_BULK_DESPATCH_DETAIL'
    ]),

    ...mapMutations('Despatch', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'UPDATE_DESPATCH_ITEM'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    onSubmitDespatchDetailForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        const messageName = this.$route.params.despatchDetails.map(item => {
          return item.stockItem.name
        })
        const payload = {
          storeId: this.tenantMustHaveSingleStoreInSameDespatchActive ? null : this.form.storeId.id,
          despatchDetailIds: this.form.despatchDetailIds,
          taxId: this.form.taxId,
          discount1: this.form.discount1,
          discount2: this.form.discount2
        }
        await this.update_BULK_DESPATCH_DETAIL(payload)
          .then(res => {
            if (res) {
              const message = this.$t('Global.notification_Updated', { name: messageName.join(', ') })
              this.notifyShow({ message })
              this.get_DESPATCH(this.$route.params.despatchHeader.id).then(res => {
                if (res.status === 200) {
                  this.UPDATE_DESPATCH_ITEM(res.data.despatchHeader)
                  this.SHOW_DETAIL(res.data)
                }
              })
              this.close()
            }
          })
      })
    }, 'submitForm')
  }
}
</script>

<style scoped lang="scss">

  .discount-field {
    margin-top: 17px;
    display: flex;
    .item {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
</style>

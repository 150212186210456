export function getDespatchHeaderFields($t) {
  return [
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.companyName')
      },
      get title() {
        return $t('EInvoice.companyName')
      }
    },
    {
      name: 'despatchDate', 
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_created_on_date')
      },
      get title() {
        return $t('Orders.order_created_on_date')
      }
    },
    {
      name: 'despatchNumber',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_despatchNumber')
      },
      get title() {
        return $t('Orders.order_header_despatchNumber')
      }
    },
    {
      name: 'netTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_totalAmount')
      },
      get title() {
        return $t('Orders.order_header_totalAmount')
      }
    },
    {
      name: 'taxTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_header_taxAmount')
      },
      get title() {
        return $t('Orders.order_header_taxAmount')
      }
    },
    {
      name: 'grossTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('EInvoice.grossTotalAmount')
      },
      get title() {
        return $t('EInvoice.grossTotalAmount')
      }
    }
  ]
}
<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('Despatches.popup_EditTitle') }}
      template(v-else) {{ $t('Despatches.popup_CreateTitle') }}

    template(slot="content")
      form.Form(
      id="form-despatch"
      @submit.prevent="onSubmitDespatchForm")

        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popup_FormField_SupplierName') }}
          .control
            custom-search(
            ref="firstInput"
            component-name="despatch-supplier"
            name="supplier",
            v-model="item.supplier",
            :data-vv-as="$t('Despatches.popup_FormField_SupplierName')",
            v-validate="'required'",
            :inputClass="{ 'is-danger': veeErrors.has('supplier') }",
            :disabled="withDetailSave || despatch.status === 1"
            :on-search="getOptions",
            :options="selectOptions",
            label="name",
            :placeholder="$t('Despatches.popup_FormField_SupplierName_Placeholder')"
            :loading="isLoadingSearchSupplier"
          )

          showValidateError(
            v-show="veeErrors.has('supplier')"
            :errorName="veeErrors.first('supplier')"
          )

        div.Form-item.required(v-if="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive")
          label.Form-item-label {{ $t('Despatches.popup_FormField_StoreName') }}
          .control
            custom-search(
            ref="secondaryInput"
            component-name="despatch-store-select"
            name="storeSelect",
            v-model="item.parentStore",
            :data-vv-as="$t('Despatches.popup_FormField_StoreName')",
            v-validate="'required'",
            :inputClass="{ 'is-danger': veeErrors.has('storeSelect') }",
            :disabled="withDetailSave || despatch.status === 1 || !item.supplier"
            :on-search="getOptionsStore",
            :options="selectMiniStoreList",
            label="name",
            :searchAll="true"
            :withDebounce="false"
            :placeholder="$t('Despatches.popup_FormField_StoreName_Placeholder')"
            :loading="isSearchStoreLists"
            @input="inputSelection"
            @resetOptions="resetOptions"
          )

          showValidateError(
            v-show="veeErrors.has('storeSelect')"
            :errorName="veeErrors.first('storeSelect')"
          )

        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popup_FormField_DespatchNumber') }}
          .control
            customTextInput(
              name="despatchNumber"
              id="input-despatch-popup-number"
              :data-vv-as="$t('Despatches.popup_FormField_DespatchNumber')"
              :error="veeErrors.has('despatchNumber')"
              :class="{ 'disabled': !item.supplier }"
              :disabled="!item.supplier"
              v-validate="'required|max:32|verify_number'"
              v-model="item.despatchNumber"
              :isDelayInput="true"
            )
            //
            Button(
            id="btn-despatch-popup-loading-number"
            type="button",
            variant="icon loading"
            tabindex="-1",
            v-show="isPendingNumber")
              Loading(theme="disable")
            Button(
            id="btn-despatch-popup-ok-number"
            type="button",
            variant="icon check"
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateNumber")


            showValidateError(
              v-show="veeErrors.has('despatchNumber')"
              :errorName="veeErrors.first('despatchNumber')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Despatches.popup_DespatchDate') }}
          .control.form-item-select
            CustomDatepicker(
              name="despatchDate",
              :data-vv-as="$t('Despatches.popup_DespatchDate') ",
              v-model="item.despatchDate"
              :class="{ 'is-danger': veeErrors.has(`despatchDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="disableDate")

            showValidateError(
              v-show="veeErrors.has('despatchDate')"
              :errorName="veeErrors.first('despatchDate')"
            )
        
        .Form-item.required(v-if="isStoreDifferentCurrency && !isCurrencySame")
          label.Form-item-label {{ $t('Despatches.popup_Rate') }}
          .control.form-item-select
            currency-input.txt.currency(
              id="exchange-rate-despatch"
              name="exchangeRate"
              v-model="item.exchangeRate"
              :data-vv-as="$t('Despatches.popup_FormField_Rate')"
              :placeholder="$t('Despatches.popup_FormField_Rate')"
              :error="veeErrors.has('exchangeRate')"
              v-validate="'greater_than:0|required'"
            )

            .currency-symbol {{ rateIsoCode }}

            showValidateError(
              v-show="veeErrors.has('exchangeRate')"
              :errorName="veeErrors.first('exchangeRate')"
            )

        div.Form-item(v-if="!isEdit" :class="isOnlyListUsageClass")
          label.Form-item-label {{ $t('Despatches.popup_FormField_despatchListName') }}
          .control
            .despatch-list
              custom-search.form-item-select(
                component-name="despatch-list"
                name="despatchList",
                v-model="item.despatchList",
                :disabled="despatchListDisabled"
                :on-search="getDespatchListOptions",
                :options="selectOptionsDespatchList",
                label="name",
                :data-vv-as="$t('Despatches.popup_FormField_despatchListName') ",
                :error="veeErrors.has('despatchList')"
                :searchAll="true"
                v-validate="{required: isOnlyListUsageControl}"
                :placeholder="$t('Despatches.popup_FormField_despatchListName_Placeholder')"
              )
          showValidateError(
            v-show="veeErrors.has('despatchList')"
            :errorName="veeErrors.first('despatchList')"
          )

        .selected-items(v-if="!isEdit")
          Button(
            v-for="(item,index) in selectedDespatchLists",
            :key="index",
            size="small"
            seashell
            :id="`selected-despatch-list-item-${index}`"
            @click="removeDespatchListFromList(item)"
          )
            span {{ item.name }}
            Icon.m-left-5(
              name="icon-popup-close"
              :id="`selected-despatch-list-item-delete-${index}`"
            )

        .Form-item
          DualRadioButton(
            :disabled="isEdit"
            :isActive="item.unitPriceType === 2 ? true : false",
            @delegateIsActiveValue="changeUnitPriceType",
            :isActiveLabel="$t('Despatches.popup_FormField_DespatchTypeNET')",
            :isPassiveLabel="$t('Despatches.popup_FormField_DespatchTypeBRUT')"
            :headerLabel="$t('Despatches.popup_FormField_DespatchType')")

        .Form-item
          label.Form-item-label {{ $t('Global.description') }}
          customTextareaInput(
            ref="descriptionInput"
            v-model="item.description"
            v-validate="'max:128'"
            name="description"
            :data-vv-as="$t('Global.description')"
            :error="veeErrors.has('description')"
            rows="3")
          showValidateError(
            v-show="veeErrors.has('description')"
            :errorName="veeErrors.first('description')")

    template(slot="footer")
      Button.item(
      id="btn-despatch-popup-submit"
      type="submit",
      size="medium"
      variant="full"
      primary
      form="form-despatch",
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Despatches.popup_FinishEditButton') }}
          template(v-else) {{ $t('Despatches.popup_FinishCreateButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DualRadioButton from '@/view/global/dual-custom-radio'
import moment from 'moment'
import disableSubmit from '@/mixins/disable-submit-button'
import { omit, getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DespatchForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    DualRadioButton
  },
  mixins: [disableSubmit],

  data () {
    return {
      disableDate: {
        from: new Date()
      },

      selectOptions: [],
      selectOptionsDespatchList: [],
      selectMiniStoreList: [],
      selectedDespatchLists: [],
      rateIsoCode: 0,
      item: {
        supplier: null,
        parentStore: null,
        despatchList: null,
        despatchDate: new Date(),
        despatchNumber: null,
        unitPriceType: 1,
        exchangeRate: null,
        description: ''
      },
      despatch: {},
      isOnlyListUsage: false,
      isStoreDifferentCurrency: false
    }
  },

  watch: {
    'item.supplier' () {
      this.item.despatchList = null
      this.selectedDespatchLists = []
      this.selectOptionsDespatchList = []
      if (!this.isEdit) {
        if (this.item.supplier.currencyDefinitionId !== this.activeCurrency.id) this.rateIsoCode = this.activeCurrency.isoCode + '/' + (this.selectedHeaderList && this.selectedHeaderList.length ? this.item.supplier.currencyIsoCode : this.item.supplier.currencyDefinitionIsoCode)
        else this.rateIsoCode = null 
        this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
          if (res) this.item.exchangeRate = res.data.rate
        })
      }
    },
    'item.despatchList' () {
      if (!this.item.despatchList) return
      this.addDespatchList()
    },
    'item.despatchDate' () {
      if (!this.isEdit) {
        this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
          if (res) this.item.exchangeRate = res.data.rate
        })  
      }
    },
    'item.parentStore' () {
      if (!this.isEdit) {
        if(this.item.parentStore.currencyDefinitionId !== this.item.supplier.currencyDefinitionId) {
          this.rateIsoCode = this.item.supplier.currencyDefinitionIsoCode + '/' + this.item.parentStore.currencyDefinitionIsoCode
          this.isStoreDifferentCurrency = true
          this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId,toCurrencyDefinitionId: this.item.parentStore.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
            if (res) this.item.exchangeRate = res.data.rate
          })  
        }
      }
    }
  },

  created () {
    const self = this
    const verifyNumber = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            DespatchNumber: value,
            SupplierId: self.item.supplier.id
          }
          if (self.isEdit) {
            params.Id = self.despatch.id
          }
          self.checkDespatchNumber(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }
    const verifyNumberMessages = {
      tr: {
        messages: {
          verify_number: field => 'Girmeye çalıştığınız alım sistemde mevcut, lütfen kontrol ediniz!'
        }
      }
    }
    Validator.extend('verify_number', verifyNumber)
    Validator.localize(verifyNumberMessages)
  },

  async mounted () {
    await this.getSuppliers({pageNumber: 1, pageSize: 999999})
    if (this.isEdit) this.init()
    else if (this.withDetailSave) {
      let dateList = []
      if (this.selectedHeaderList && this.selectedHeaderList.length) {
        this.selectedHeaderList.forEach(item => {
          dateList.push(new Date(item.orderDate))
        })    
      }
      const despatchDate = new Date(Math.max.apply(new Date(), dateList))
      this.item.despatchDate = despatchDate.getTime() <= new Date().getTime() ? despatchDate : new Date()
      if (this.selectedHeaderList.length !== 0) this.addSupplierName(this.selectedHeaderList)
      else this.addSupplierName(this.selectedDetailList)
    } else {
      this.focusFirstInput()
    }
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    if (this.$route.query.ordered) {
      // Buradaki kod parçacığı BE tarafından ve Ordered ve Approved durumlarından dönen verilerde StoreId ve Name bulunuyor ancak currencydefination bilgileri bulunmadığından ötürü
      // Burada tekrar bu bilgileri çekebilmek için istek atılmıştır hem tedarikçi hemde ambar bilgileri için. BE'nin bu eksik bilgileri tam dönmesi durumunda bu kodu revize ediniz.
      const supplierData = await this.searchSupplier(this.$route.query.selectedHeaders[0].supplierName).then(res => {return res.data.items[0]})
      this.item.supplier = supplierData
      const parentStoreData = await this.fetchStoreByName({name: this.$route.query.selectedHeaders[0].storeName}).then(res => { return res.data.items[0] })
      this.item.parentStore = parentStoreData
      if (this.item.supplier.currencyDefinitionId !== this.activeCurrency.id) this.rateIsoCode = this.activeCurrency.isoCode + '/' + (this.selectedHeaderList && this.selectedHeaderList.length ? this.item.supplier.currencyIsoCode : this.item.supplier.currencyDefinitionIsoCode)
      else this.rateIsoCode = null 
      await this.getExchangeRate({currencyDefinitionId: this.item.supplier.currencyDefinitionId, conversionDate: moment(this.item.despatchDate).format('YYYY-MM-DD')}).then(res => {
        if (res) this.item.exchangeRate = res.data.rate
      })
    }
  },

  computed: {
    ...mapGetters('Despatch', [
      'despatch_PageNumber'
    ]),

    ...mapGetters('Settings', [
      'permissions', 'checkPermission','tenantMultiCurrencyIsActive','tenantMustHaveSingleStoreInSameDespatchActive'
    ]),

    ...mapGetters('Global', ['activeCurrency', 'CurrencyList']),

    ...mapGetters('Supplier', ['Suppliers']),

    ...mapFields({
      flagsDespatchNumber: 'despatchNumber'
    }),

    despatchListDisabled () {
      return this.$route.query.ordered === true ? true : !this.item.supplier
    },

    noListUsageRequiration () {
      return this.checkPermission('SupplierUsageWithListIsNotRequiredEdit') && this.item.supplier && this.item.supplier.usageWithListIsNotRequired
    },

    withDetailSave () {
      return this.$route.query.withDetail || false
    },
    selectedOrderList () {
      return this.withDetailSave ? this.$route.query.selectedItems : false
    },
    selectedHeaderList () {
      return this.withDetailSave ? this.$route.query.selectedHeaders : false
    },
    selectedDetailList () {
      return this.withDetailSave ? this.$route.query.selectedDetails : false
    },
    newItem () {
      return {
        id: this.despatch.id,
        despatchDate: moment(this.item.despatchDate).format('YYYY-MM-DD'),
        despatchNumber: this.item.despatchNumber,
        supplierId: this.item.supplier.id,
        parentStoreId: this.tenantMultiCurrencyIsActive || this.tenantMustHaveSingleStoreInSameDespatchActive ? this.item.parentStore.id : null,
        unitPriceType: this.item.unitPriceType,
        exchangeRate: this.tenantMultiCurrencyIsActive && !this.isCurrencySame ? this.item.exchangeRate : null,
        despatchListIds: this.selectedDespatchLists.map(item => item.id),
        description: this.item.description
      }
    },

    isOnlyListUsageClass () {
      return this.$route.query.ordered === true ? '' : this.isOnlyListUsage && !this.noListUsageRequiration ? 'required' : ''
    },

    isOnlyListUsageControl() {
      return this.$route.query.ordered === true || this.noListUsageRequiration ? false : this.isOnlyListUsage && this.selectedDespatchLists.length === 0
    },

    isLoading () {
      return this.isDisabledSubmit || this.$wait.is(['update_DESPATCH_DETAIL', 'create_DESPATCH_HEADER'])
    },

    isLoadingSearchSupplier () {
      return this.$wait.is(['searchSupplier'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingNumber () {
      return this.flagsDespatchNumber.pending
    },

    isValidateNumber () {
      return this.flagsDespatchNumber.valid && this.item.despatchNumber !== null && this.item.despatchNumber !== '' && !this.isPendingNumber
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
    isCurrencySame () {
      return this.item.supplier.currencyDefinitionId === this.item.parentStore.currencyDefinitionId
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Supplier', [
      'searchSupplier',
      'getSuppliers'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList',
      'fetchStoreByName'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH_LIST',
      'get_DESPATCH',
      'create_DESPATCH_HEADER',
      'update_DESPATCH_HEADER',
      'checkDespatchNumber',
      'getExchangeRate'
    ]),
    ...mapActions('ListsDespatch', [
      'searchDespatchList'
    ]),
    ...mapMutations('Despatch', [
      'SHOW_DETAIL'
    ]),

    addSupplierName (selectedList) {
      let supplier = this.Suppliers.find(i => i.id === selectedList[0].supplierId)
      this.item.supplier = {
        id: supplier.id,
        name: supplier.name,
        currencyDefinitionId: supplier.currencyId,
        currencyIsoCode: supplier.currencyIsoCode
      }
      this.selectOptions.push({
        id: selectedList[0].supplierId,
        name: selectedList[0].supplierName
      })
    },

    removeDespatchListFromList (item) {
      this.selectedDespatchLists.splice(this.selectedDespatchLists.indexOf(item), 1)
      this.changeUnitPriceTypeWhenChangedDespatchList()
    },

    unSelectDespatchList () {
      this.item.despatchList = null
    },

    changeUnitPriceTypeWhenChangedDespatchList () {
      if (this.selectedDespatchLists.length === 0) {
        this.item.unitPriceType = 1
      } else if (this.selectedDespatchLists.length === 1) {
        this.item.unitPriceType = this.selectedDespatchLists[0].unitPriceType
      } else {
        let gross = 0
        let net = 0
        this.selectedDespatchLists.forEach(dl => dl.unitPriceType === 1 ? net++ : gross++)
        this.item.unitPriceType = net > 0 ? 1 : 2
      }
    },

    addDespatchList () {
      const isSelectedBefore = !!this.selectedDespatchLists.some(dl => dl.id === this.item.despatchList.id)

      if (!isSelectedBefore) {
        this.selectedDespatchLists.push(this.item.despatchList)
        this.changeUnitPriceTypeWhenChangedDespatchList()
      }
      this.$nextTick(() => this.unSelectDespatchList())
    },

    async init () {
      let despatchHeader = this.$route.params.despatchHeader
      if (!despatchHeader) {
        let despatchHeaderId = this.$route.params.id
        await this.get_DESPATCH(despatchHeaderId).then(res => {
          if (res.status === 200) {
            despatchHeader = res.data.despatchHeader
          }
        })
      }
      this.item.supplier = this.Suppliers.find(i => i.id === despatchHeader.supplier.id)
      this.despatch = despatchHeader
      //
      this.item.parentStore = {
        id: despatchHeader.parentStoreId,
        name: despatchHeader.parentStoreName,
      }
      this.item.despatchNumber = despatchHeader.despatchNumber
      this.item.despatchDate = despatchHeader.despatchDate
      this.item.unitPriceType = despatchHeader.unitPriceType
      this.item.exchangeRate = despatchHeader.exchangeRate
      this.item.description = despatchHeader.description
      if (this.activeCurrency.id !== this.item.supplier.currencyId) {
        this.rateIsoCode = this.activeCurrency.isoCode + '/' + this.item.supplier.currencyIsoCode 
      } 
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name

      })
    },

    focusFirstInput () {
      this.$nextTick(() => this.$refs.firstInput.$refs.search.focus())
    },

    onSubmitDespatchForm: vueWaitLoader(async function () {
      await this.$validator.validateAll()
        .then(async result => {
          if (!result) {
            this.enableSubmitButton()
          } else {
            if (this.isEdit) await this.submitEdit()
            else await this.submitNew()
          }
        })
    }, 'submitForm'),

    resetForm () {
      this.selectOptions = []
      this.item = {
        supplier: null,
        parentStore: null,
        despatchDate: new Date(),
        despatchNumber: null,
        exchangeRate: null,
        description: ''
      }
    },

    async submitNew () {
      // add item
      let newDespatch = omit(this.newItem, ['id'])
      if (this.withDetailSave) {
        if (this.selectedHeaderList) newDespatch.orderHeaderIds = [...this.selectedHeaderList.map(_ => _.id)]
        if (this.selectedDetailList) newDespatch.orderDetailIds = [...this.selectedDetailList.map(_ => _.id)]
      }
      await this.create_DESPATCH_HEADER(newDespatch)
        .then(async res => {
          if (res) {
            this.$emit('showDrafts')
            const message = this.$t('Despatches.popup_Notificiation_Created', {
              number: res.data.despatchHeader.despatchNumber
            })
            this.notifyShow({ message })
            const resHeader = await this.get_DESPATCH_LIST({})
            if ((this.withDetailSave && resHeader) || (resHeader && this.selectedDespatchLists.length > 0)) {
              await this.get_DESPATCH(res.data.despatchHeader.id).then(resDetail => {
                if (resDetail) {
                  this.close()
                  this.SHOW_DETAIL(resDetail.data)
                }
              })
            } else {
              this.$router.push({
                name: 'DespatchDetailNew',
                params: {
                  id: res.data.despatchHeader.id
                }
              })
            }
          }
          this.enableSubmitButton()
        })
    },

    async submitEdit () {
      // edit item
      const payload = getObjectValuesByKey(this.newItem, ['id', 'despatchDate', 'despatchNumber', 'exchangeRate', 'description'])
      await this.update_DESPATCH_HEADER(payload).then(res => {
        if (res.status === 200) {
          const message = this.$t('Despatches.popup_Notificiation_Updated', {
            number: this.newItem.despatchNumber
          })
          this.notifyShow({ message })
          this.close()
          this.get_DESPATCH_LIST({})
        }
        this.enableSubmitButton()
      })
    },

    getOptions (search, loading) {
      this.searchSupplier(search)
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },

    getDespatchListOptions (search, loading) {
      const params = {despatchListName: search, supplierIds: [this.item.supplier.id]}
      this.searchDespatchList(params)
        .then(res => {
          if (res.status === 200) {
            this.selectOptionsDespatchList = res.data.despatchListHeaderList
          }
        })
    },

    inputSelection (data) {
      if(data.length !== 0) {
        this.item.parentStore.id = data.id
        this.item.parentStore.name = data.name
        this.item.parentStore.currencyDefinitionId = data.currencyDefinitionId
        this.item.parentStore.currencyDefinitionIsoCode = data.currencyDefinitionIsoCode
        this.item.parentStore.currencyDefinitionSymbol = data.currencyDefinitionSymbol
      }
    },

    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    changeUnitPriceType (val) {
      if (val) {
        this.item.unitPriceType = 2
      } else {
        this.item.unitPriceType = 1
      }
    }

  }
}
</script>

<style lang="scss" scoped>

@import '~@/view/pages/Lists/styles/form-modal.scss';

.despatch-list {
  display: flex;
  align-items: center;

  .form-item-select {
    width: 100%;
  }

  .btn-add-items {
    width: auto;
    height: 100%;
    padding: 12px;

    &:disabled {
      background: transparent !important;
      box-shadow: none !important;
    }
  }
}
.currency {
  text-align: right;
}
</style>

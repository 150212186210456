export function getRefundErrorHeaderFields($t) {
  return [
    {
      name: 'stockItem',
      is_visible: true,
      get short_title() {
        return $t.t('Despatches.refund_finalize_stock_errors')
      },
      get title() {
        return $t.t('Despatches.refund_finalize_stock_errors')
      }
    }
  ]
}

<template lang="pug">
  Popup

    template(slot="title")
        template {{ $t('Despatches.convertToTransfer') }}

    template(slot="content")
      form.Form(
      id="form-transfer"
      @submit.prevent="onSubmitForm")
        .Form-item.required
          label.Form-item-label
            | {{ $t('Transfer.buyerStore') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
              name="buyerStoreName"
              :data-vv-as="$t('Transfer.buyerStore')"
              v-model="form.toStoreId"
              :error="veeErrors.has('buyerStoreName')"
              v-validate="'required|is_not:-1'"
              id="select-transfer-transfer-buyerStore"
            )
              option(
                value="-1"
                :disabled="true"
                id="option-convert-transfer-popup-store-name-unselected"
              ) {{ $t('Despatches.detail_Popup_FormField_StoreName_Unselected') }}

              option(
                v-for="store in sortedStoreList"
                :key="store.id"
                :value="store.id"
                :id="`option-convert-transfer-popup-store-name-${store.name}`"
              ) {{ store.name }}
              
        .Form-item.required
          label.Form-item-label {{ $t('Transfer.Transfer_Date') }}
          .control.form-item-select
            CustomDatepicker(
              id="convert-transfer-header-datepicker"
              name="transferDate",
              v-model="form.transferDate",
              :class="{ 'is-danger': veeErrors.has('transferDate') }"
              :data-vv-as="$t('Transfer.Transfer_Date')",
              v-validate="'required'",
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon=""
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
            v-show="inventoryValidation"
          ) {{ $t('Inventory.closedInventoryError') }}


    template(slot="footer")

      Button.item(
      id="btn-convert-transfer-popup-submit"
      type="submit"
      variant="full"
      size="medium"
      primary
      form="form-transfer"
      )
        span {{ $t('Despatches.popupForm_Button_ConvertToTransfer') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import CheckInventoryMixin from '@/mixins/validateInventory'
import customSelect from '@/view/global/custom-select'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ConvertToTransferForm',
  mixins: [CheckInventoryMixin],
  props: {
    status: {
      type: String,
      default: 'new'
    },
    transferId: {
      type: String,
      default: null
    }
  },

  components: {
    customSelect
  },

  data () {
    return {
      despatchDate: null,
      form: {
        toStoreId: '-1',
        transferDate: moment(new Date()).format('YYYY-MM-DD')
      }
    }
  },
  computed: {
    ...mapGetters('Stores', [
      'miniStoreList',
      'sortedStoreList'
    ]),

    ...mapGetters('Settings', [
      'permissions'
    ]),

    disableDate () {
      return {to: moment(this.$route.params.date)._d, from: new Date()}
    },
  },

  async mounted () {
    this.despatchDate = this.$route.params.date
    await this.getStoreList({ignorePermission: true, pageNumber: 1, pageSize: 9999})
    await this.fetchUser().then(res => {
      this.form.toStoreId = res.status === 200 ? res.data.user.defaultStoreId : -1
    })
  },

  methods: {
    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('Transfer', [
      'createTransferFromDespatch'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stores', ['getStoreList']),

    formReset () {
      this.form.toStoreId = '-1'
      this.form.transferDate = moment(new Date()).format('YYYY-MM-DD')
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    onSubmitForm: vueWaitLoader(async function () {
      const validated = this.$validator.validateAll()
      if (validated) {
        const payload = {
          despatchHeaderId: this.$route.params.id,
          transferDate: moment(this.form.transferDate).format('YYYY-MM-DD'),
          toStoreId: this.form.toStoreId
        }
        const message = this.$t('Despatches.transferCreatedSuccessfully')

        await this.createTransferFromDespatch(payload).then(res => {
          if (res) {
            this.notifyShow({ message })
            this.close()
          }
        })
      }
    }, 'onSubmitFormConvertTransfer'),
  }
}
</script>

<style lang="scss" scoped>

  .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>

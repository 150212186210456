var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { staticClass: "transition", attrs: { name: "zoom" } },
    [
      _c("div", { staticClass: "Alert" }, [
        _c(
          "div",
          { staticClass: "Alert-body" },
          [
            _c("div", { staticClass: "Alert-body-title" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("EInvoice.uploading_EArchive_Document"))),
              ]),
            ]),
            _c("div", { staticClass: "Alert-body-subtitle m-top-15" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.docInfos")))]),
            ]),
            _c("div", { staticClass: "Alert-body-header m-top-10" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("EInvoice.totalCount", { length: _vm.data.length })
                  )
                ),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.data.filter((i) => i.code && i.code.status === 200)
                      .length
                  ) +
                    " / " +
                    _vm._s(_vm.data.length)
                ),
              ]),
            ]),
            _vm._l(_vm.data, function (item) {
              return _c("div", { staticClass: "Alert-item" }, [
                _c(
                  "div",
                  { staticClass: "Alert-item-name" },
                  [
                    _c("Icon", {
                      staticClass: "m-right-10",
                      attrs: { name: "icon-file" },
                    }),
                    _c(
                      "span",
                      { staticClass: "tooltip-relative" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getFileName(item.name))),
                        ]),
                        _c("Tooltip", {
                          staticClass: "tooltip",
                          attrs: { text: item.name, white: "", bottom: "" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                item.code && item.code.status === 200 && !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "Alert-item-code" },
                      [
                        _c("span", { staticClass: "message-success" }, [
                          _vm._v(_vm._s(_vm.$t("EInvoice.successful"))),
                        ]),
                        _c("Icon", {
                          staticClass: "m-left-10",
                          attrs: { name: "icon-success" },
                        }),
                      ],
                      1
                    )
                  : item.code && !item.code.status !== 200 && !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "Alert-item-code" },
                      [
                        _c("span", { staticClass: "message-error" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("EInvoice.ubl_errorCode_" + item.code)
                            )
                          ),
                        ]),
                        _c("Icon", {
                          staticClass: "m-left-10",
                          attrs: { name: "icon-failed" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "Alert-item-code" },
                      [
                        _c("span", { staticClass: "message-pending" }, [
                          _vm._v(_vm._s(_vm.$t("EInvoice.loading"))),
                        ]),
                        _c("Icon", {
                          staticClass: "m-left-10",
                          attrs: { name: "icon-pending" },
                        }),
                      ],
                      1
                    ),
              ])
            }),
            _c(
              "div",
              { staticClass: "Alert-footer" },
              [
                _c(
                  "Button",
                  {
                    staticClass: "m-top-15",
                    attrs: {
                      primary: "",
                      type: "button",
                      size: "small",
                      disabled: _vm.isLoading,
                    },
                    on: { click: _vm.closeAlert },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("Global.alert_OkButton"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }